import {
    Button,
    Icon,
    InputCpfCnpj,
    InputPassword,
    Link,
    Loading,
    Notification,
    Text,
} from '@terrainvest/react-components';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { fetchClientAgreementByTypeInit } from '../../Redux/Agreements';
import { resetAuthData } from '../../Redux/Auth';
import { fetchAuthInit } from '../../Redux/Auth/index.actions';
import { fetchSuitabilityProfileInit } from '../../Redux/Suitability/index.actions';
import Template from '../../Template';
import { StyledColNoPadd, StyledRowNoPadd } from '../Common/Grid';
import Logo from '../Common/Logo';
import Disclaimer from '../Disclaimer';
import {
    StyledCTAText,
    StyledLogin,
    StyledNotificationButton,
    StyledRegisterLink,
    StyledRegisterText,
    StyledSignup,
    StyledSignupContent,
} from './index.styles';
import LoggedUserTriage from './LoggedUserTriage';

export const Login = () => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const history = useHistory();

    const {
        auth,
        mappedError,
        error: authError,
        offerManagerError,
        loading: authStateLoading,
    } = useSelector(state => state.authState);

    const { client, error: clientError, loading: clientStateLoading } = useSelector(
        state => state.clientState,
    );

    const { profile: suitabilityProfile, loading: suitabilityStateLoading } = useSelector(
        state => state.suitabilityProfileState,
    );

    const { agreementByType: agreement, loading: agreementStateLoading } = useSelector(
        state => state.agreementsState,
    );

    const { partner } = useSelector(state => state.partnerState);

    const dispatch = useDispatch();

    const dataIsLoading =
        authStateLoading ||
        !(!clientStateLoading && !suitabilityStateLoading && !agreementStateLoading);

    const showLoginForm = !auth || !client;

    const onSubmitLogin = event => {
        event.preventDefault();

        dispatch(
            fetchAuthInit({
                username,
                password,
                partner_id: partner.id,
            }),
        );
    };

    React.useEffect(() => {
        if (!auth || !client || !client.Accounts || !client.Accounts.length) {
            return;
        }

        if (!suitabilityProfile) {
            dispatch(fetchSuitabilityProfileInit(client.ClientId));
        }

        if (!agreement) {
            dispatch(fetchClientAgreementByTypeInit(client));
        }
    }, [auth, client, suitabilityProfile, agreement]);

    React.useEffect(() => {
        if (offerManagerError) {
            history.push('/restricao-coordenacao-oferta');
            dispatch(resetAuthData());
        }
    }, [offerManagerError]);

    return (
        <Template>
            <Notification
                className="login-notification"
                fixed
                icon
                type={mappedError && mappedError.error !== 'user_blocked' ? 'warn' : 'error'}
                visible={!!mappedError}
            >
                {mappedError ? (
                    <>
                        <Icon icon="exclamation-circle" />
                        &nbsp;&nbsp;
                        {mappedError.error_description}
                        {mappedError.error === 'user_blocked' ? (
                            <>
                                <StyledNotificationButton
                                    tertiary
                                    onClick={() => history.push(`/recuperar-senha`)}
                                >
                                    DESBLOQUEAR CONTA
                                    <Icon icon="chevron-right" size="sm" padLeft />
                                </StyledNotificationButton>
                            </>
                        ) : null}
                    </>
                ) : null}
            </Notification>
            <Notification
                className="login-notification"
                fixed
                icon
                type="error"
                visible={!!(!mappedError && (authError || clientError))}
            >
                <Icon icon="exclamation-circle" />
                &nbsp;&nbsp;Serviço indisponível. Por favor, tente novamente mais tarde.
            </Notification>
            <StyledRowNoPadd>
                {showLoginForm ? (
                    <StyledColNoPadd lg={5} sm={12}>
                        <Loading visible={dataIsLoading} />
                        <StyledLogin onSubmit={onSubmitLogin}>
                            <Logo type="verticalBlack" src={partner.logos.login} />

                            <Text large weight="medium">
                                Já é cliente? Acesse sua conta.
                            </Text>

                            <InputCpfCnpj
                                id="username"
                                name="username"
                                autcomplete="on"
                                fullWidth
                                label="CPF ou CNPJ"
                                defaultValue=""
                                onChange={(evt, formattedValue) =>
                                    setUsername(formattedValue.parsedValue)
                                }
                            />

                            <InputPassword
                                id="password"
                                name="password"
                                autcomplete="on"
                                fullWidth
                                label="Senha"
                                onChange={evt => setPassword(evt.target.value)}
                            />

                            <Button
                                primary
                                className="login-button"
                                disabled={!username || !password}
                                fullWidth
                                type="submit"
                            >
                                ENTRAR <Icon icon="chevron-right" size="xs" padLeft />
                            </Button>
                            <Link
                                data-cy="passwordRecovery"
                                to="/recuperar-senha"
                                wrapper={RouterLink}
                            >
                                Esqueceu sua senha?
                            </Link>
                        </StyledLogin>
                        <StyledRegisterLink>
                            Ainda não é cliente?
                            <Link data-cy="registerLink" href={partner.urlCadastro}>
                                Abrir conta grátis
                            </Link>
                        </StyledRegisterLink>
                    </StyledColNoPadd>
                ) : (
                    <LoggedUserTriage isLoading={dataIsLoading} />
                )}

                {showLoginForm ? (
                    <StyledColNoPadd col={7}>
                        <StyledSignup onSubmit={event => event.preventDefault()}>
                            <StyledSignupContent display={partner.newAccountButton}>
                                <StyledCTAText small>Ainda não é cliente?</StyledCTAText>
                                <StyledRegisterText>
                                    Você não precisa ser um investidor para investir.
                                </StyledRegisterText>
                                <Button
                                    outline
                                    primary
                                    fullWidth
                                    className="register-button"
                                    onClick={() => window.open(partner.urlCadastro, '_self')}
                                >
                                    ABRIR CONTA GRÁTIS
                                    <Icon icon="chevron-right" size="xs" padLeft />
                                </Button>
                            </StyledSignupContent>
                            <Disclaimer textColor="white" />
                        </StyledSignup>
                    </StyledColNoPadd>
                ) : null}
            </StyledRowNoPadd>
        </Template>
    );
};

export default Login;
