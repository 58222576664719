import { formatCurrency } from '@terrainvest/react-components';
import { parseDateForDisplay, parseFinancialValue } from '../../Utils/Parsers';
import { WithdrawValue } from './index.types';

export const normalizeHistory = history =>
    history.map(entry => ({
        ...entry,
        DateNormalized: parseDateForDisplay(entry.Date),
        AmountNormalized: parseFinancialValue(entry.Amount),
    }));

export const normalizeWithdrawValue = (withdrawValueRaw): Record<string, WithdrawValue> => {
    const withdrawValue: WithdrawValue = {
        availableBalance: withdrawValueRaw.AvailableBalance,
        availableBalanceFormatted: formatCurrency(withdrawValueRaw.AvailableBalance),
        futureBalance: withdrawValueRaw.FutureBalance,
        futureBalanceFormatted: formatCurrency(withdrawValueRaw.FutureBalance),
        futureDebits: withdrawValueRaw.FutureDebits,
        futureDebitsFormatted: formatCurrency(withdrawValueRaw.FutureDebits),
        futureTransactions: withdrawValueRaw.FutureTransactions,
        futureTransactionsFormatted: formatCurrency(withdrawValueRaw.FutureTransactions),
    };

    return {
        [withdrawValueRaw.AccountNumber.toString()]: withdrawValue,
    };
};
