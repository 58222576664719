import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Button } from '@terrainvest/react-components';

export const StyledButtons = styled.div`
    display: flex;
    justify-content: flex-end;

    ${media.max.phone`
        flex-flow: column;
        display: flex;
        flex-direction: column-reverse;
    `}

    ${Button.Styles.StyledButton} {
        margin-left: 20px;
        height: 48px;
        width: 180px;

        ${media.max.phone`
            margin-left: 0;
            margin-bottom: 16px;
            width: 100%;
        `}
    }
`;
