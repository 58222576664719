import * as React from 'react';
import { H2, Text, Icon, InputText, InputCpf } from '@terrainvest/react-components';

import {
    StyledCol,
    StyledRow,
    StyledHeaderAddedItemWrapper,
    StyledRemoveItem,
} from '../../Common/GridStyles';

import { validateCpfValue } from '../../index.validators';

import Separator from '../../../../Utils/General/index.separator';
import { Field } from '../../Form/Field';
import { fieldLengthLimits } from '../../index.settings';

export const AdministratoInput = ({ index, preffix: id, onRemove }) => {
    const preffix = id ? `${id}.` : '';
    const handleRemove = () => {
        onRemove(index);
    };

    return (
        <>
            <StyledHeaderAddedItemWrapper>
                <H2>{`Administrador ${index + 1}`}</H2>
                <StyledRemoveItem onClick={handleRemove}>
                    <Text weight="medium">
                        Remover administrador
                        <Icon icon="times" size="sm" padLeft />
                    </Text>
                </StyledRemoveItem>
            </StyledHeaderAddedItemWrapper>
            <Separator />
            <StyledRow>
                <StyledCol md={4} sm={12}>
                    <Field name={`${preffix}Name`}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Nome"
                                fullWidth
                                maxLength={fieldLengthLimits.name}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={4} sm={12}>
                    <Field name={`${preffix}CpfCnpj`} validate={validateCpfValue}>
                        {({ inputProps }) => (
                            <>
                                <InputCpf
                                    {...inputProps}
                                    label="CPF"
                                    fullWidth
                                    disabled={inputProps.disabled}
                                />
                            </>
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={4} sm={12}>
                    <Field name={`${preffix}RG`}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="RG"
                                fullWidth
                                maxLength={fieldLengthLimits.name}
                            />
                        )}
                    </Field>
                </StyledCol>
            </StyledRow>
        </>
    );
};

export default AdministratoInput;
