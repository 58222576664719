import styled from 'styled-components';
import { Icon } from '@terrainvest/react-components';

export const StyledHeaderQualifiedInvestorItem = styled.div`
    color: ${props => (props.professional ? '#12479D' : '#4285F4')};
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
`;

export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    font-weight: 500;
    margin-right: auto;
`;

export const StyledIcon = styled(Icon)`
    font-size: 12px;
`;

export const StyledSpan = styled.span`
    margin-left: 5px;
    font-size: 14px;
`;
