import * as React from 'react';
import { Popover } from '@terrainvest/react-components';
import { StyledHeaderMenuContainer } from './index.styles';

interface HeaderMenuContainerProps {
    open?: boolean;
    setClose?;
    children: React.ReactNode;
    id: string;
}

export const HeaderMenuContainer: React.FC<HeaderMenuContainerProps> = ({
    open,
    setClose,
    children,
    id = '',
}: HeaderMenuContainerProps) => {
    const handleClose = event => {
        const { id: evtId } = event.target.id ? event.target : event.target.parentNode;
        if (open && evtId !== id) {
            setClose();
        }
    };

    return (
        <StyledHeaderMenuContainer>
            <Popover onClose={handleClose} bindTo={id} open={open}>
                {children}
            </Popover>
        </StyledHeaderMenuContainer>
    );
};

HeaderMenuContainer.defaultProps = {
    open: false,
    setClose: () => {},
};

export default HeaderMenuContainer;
