const valueOrZero = value => value || 0;

const NORMALIZE_NULL_FIELDS = [
    {
        label: 'NetWorth',
        normalizer: valueOrZero,
    },
    {
        label: 'MonthlyAverageBilling',
        normalizer: valueOrZero,
    },
];

const normalizeNullFinancialFields = client => {
    const denormalizedObj = { ...client };

    NORMALIZE_NULL_FIELDS.forEach(field => {
        const rawField = denormalizedObj.Patrimony[field.label];
        denormalizedObj.Patrimony[field.label] = field.normalizer(rawField);
    });

    return denormalizedObj;
};

export const normalizePatrimony = client => {
    const normalizedClient = { ...client };

    return normalizeNullFinancialFields(normalizedClient);
};
