import styled from 'styled-components';

export const StyledSwitch = styled.div`
    & svg {
        color: ${props => props.theme.global.colors.doveGray};
        ${props =>
            !props.visible
                ? `
                    position: relative;
                    left: -1px !important;
                `
                : ``}
    }
`;

export const StyledWrapper = styled.div`
    cursor: pointer;
`;
