import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import Core from './Core';
import store from './store';

const isDevelopment = ['production', 'staging'].indexOf(process.env.NODE_ENV) === -1;

if (!isDevelopment) {
    if (process.env.SENTRY_SECRET && process.env.SENTRY_PROJECT) {
        Sentry.init({
            dsn: `https://${process.env.SENTRY_SECRET}@sentry.io/${process.env.SENTRY_PROJECT}`,
            ignoreErrors: ['ResizeObserver'],
        });
    }
}

ReactDOM.render(
    <Provider store={store}>
        <Core />
    </Provider>,
    document.getElementById('root'),
);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept();
}
