import styled from 'styled-components';
import { HighlightedText, Text } from '@terrainvest/react-components';
import { HiddenBalanceSwitch } from '../Common/HiddenBalance';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 14px;
`;

export const StyledIconWrapper = styled.div`
    cursor: pointer;
    width: 40px;
    margin-right: 8px;
`;

export const StyledInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > div:nth-child(even) {
        margin: 0 10px;
    }
`;

export const StyledHiddenBalanceSwitch = styled(HiddenBalanceSwitch)`
    margin-right: 8px;
`;

export const StyledHighlightedBalanceText = styled(HighlightedText)`
    & > h2 {
        ${props =>
            props.isNegative ? `color: ${props.theme.global.colorAliases.error} !important;` : ''}
    }
`;

export const StyledNoDataFeedback = styled(Text)`
    color: ${props => props.theme.global.colors.error};
`;
