import * as React from 'react';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import { Loading } from '@terrainvest/react-components';

import { StyledContainerNoPadd, StyledRowNoPadd } from '../../View/Common/Grid';
import { GlobalNotification } from '../../View/Common/GlobalNotification';
import { DueDateNotification } from '../../View/Common/DueDateNotification';
import { UserDataExpirationNotification } from '../../View/UserDataExpiration';

import Header from '../../View/Header';
import Sidebar, { HOME_ITEM_ID } from '../../View/Sidebar';
import FinancialOverviewMobile from '../../View/Home/FinancialOverviewMobile';

import { useNotifyAppLoad } from '../../Utils/Hooks';

import { MENU_ID_PREFIX } from '../../View/Sidebar/Sections/index.constants';

import { fetchPartnerFunctionalityInit } from '../../Redux/PartnerFunctionality';

import { StyledSideBarAndHeader, StyledCol } from './index.styles';

export interface StandardTemplateProps {
    children: React.ReactNode;
    defaultSelected?: string;
    id?: string;
}

const RECOMMENDED_PORTFOLIO_URL = '/app/carteiras-recomendadas';
const PORTFOLIO_KEY = 'carteira';

const StandardTemplate = ({ id = '', defaultSelected = '', children }: StandardTemplateProps) => {
    const [collapsed, setCollapsed] = React.useState(false);
    const [selected, setSelected] = React.useState(defaultSelected);

    const { sections, loading } = useSelector(state => state.partnerFunctionalityState);
    const { partner } = useSelector(state => state.partnerState);
    const { isTerraApp } = useSelector(state => state.mobileState);

    const location = useLocation();
    const theme = useTheme();
    const dispatch = useDispatch();
    useNotifyAppLoad();

    const getMenuKeyByPath = pathname => {
        if (!sections) {
            return '';
        }
        let key = '';
        for (let i = 0; i < sections.length; i += 1) {
            const section = sections[i];
            if (section.functionalities) {
                const chosenFunctionality = section.functionalities.find(functionality =>
                    pathname.includes(`/app/${functionality.url}`),
                );
                if (chosenFunctionality) {
                    const mistakenPortfolioWithRecommendedPortfolio =
                        chosenFunctionality.url === PORTFOLIO_KEY &&
                        pathname.includes(RECOMMENDED_PORTFOLIO_URL);
                    if (!mistakenPortfolioWithRecommendedPortfolio) {
                        key = `${MENU_ID_PREFIX}${chosenFunctionality.url}`;
                        break;
                    }
                }
            }
        }
        return key;
    };

    React.useEffect(() => {
        if (partner && partner.id) {
            dispatch(fetchPartnerFunctionalityInit(partner.id));
        }
    }, [partner]);

    React.useEffect(() => {
        defineSelectedMenuItem();
    }, [location.pathname]);

    React.useEffect(() => {
        if (!loading) {
            defineSelectedMenuItem();
        }
    }, [loading]);

    const defineSelectedMenuItem = () => {
        const selectedKey = getMenuKeyByPath(location.pathname);
        if (selectedKey) {
            setSelected(selectedKey);
        } else if (location.pathname === '/app') {
            setSelected(HOME_ITEM_ID);
        } else {
            setSelected('');
        }
    };

    const collapseCallback = newValue => setCollapsed(newValue);

    return (
        <StyledContainerNoPadd id={id} fluid>
            <StyledRowNoPadd>
                {!isTerraApp && (
                    <StyledSideBarAndHeader>
                        <Header
                            width={
                                collapsed
                                    ? `calc(100% - ${theme.components.sideNav.collapsedWidth})`
                                    : `calc(100% - ${theme.components.sideNav.defaultWidth})`
                            }
                        />
                        <Sidebar collapseCallback={collapseCallback} defaultSelected={selected} />
                    </StyledSideBarAndHeader>
                )}
                <FinancialOverviewMobile />
                <StyledCol>
                    <UserDataExpirationNotification />
                    <DueDateNotification />
                    {loading ? <Loading visible={loading} /> : children}
                </StyledCol>
            </StyledRowNoPadd>
            <GlobalNotification />
        </StyledContainerNoPadd>
    );
};
export default StandardTemplate;
