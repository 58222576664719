import * as React from 'react';
import { StyledHeaderProfileNameItem } from './index.styles';

interface HeaderProfileNameItemProps {
    profile?: string;
    profileId?: number;
}

export const HeaderProfileNameItem: React.FC<HeaderProfileNameItemProps> = ({
    profile,
    profileId,
}: HeaderProfileNameItemProps) => {
    return (
        <StyledHeaderProfileNameItem profileId={profileId}>
            <span>{profileId > 0 && profileId < 4 ? `Perfil ${profile}` : 'Sem Perfil'}</span>
        </StyledHeaderProfileNameItem>
    );
};

HeaderProfileNameItem.defaultProps = {
    profile: '',
    profileId: 0,
};

export default HeaderProfileNameItem;
