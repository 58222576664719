import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchCustodyDates,
    fetchCustodyDatesSuccess,
    fetchCustodyDatesError,
} from './index.actions';

export const initialState = {
    dates: null,
    loading: false,
    error: null,
};

export const custodyDatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_CUSTODY_DATES_INIT:
            return state.loading === true
                ? state
                : loop(
                      { ...state, loading: true, error: false },
                      Cmd.run(fetchCustodyDates, {
                          successActionCreator: fetchCustodyDatesSuccess,
                          failActionCreator: fetchCustodyDatesError,
                          args: [action.account],
                      }),
                  );

        case Actions.FETCH_CUSTODY_DATES_SUCCESS:
            return { ...state, dates: [...action.dates], loading: false, error: false };

        case Actions.FETCH_CUSTODY_DATES_ERROR:
            return { ...state, error: null, loading: false };

        case Actions.FETCH_CUSTODY_DATES_RESET:
            return { ...state, dates: null, loading: false, error: false };

        default:
            return state;
    }
};
