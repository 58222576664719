import * as React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton, formatCurrency } from '@terrainvest/react-components';

import {
    StyledInfo,
    StyledWrapper,
    StyledIconWrapper,
    StyledHiddenBalanceSwitch,
    StyledHighlightedBalanceText,
    StyledNoDataFeedback,
} from './index.styles';
import SimpleVR from '../../Utils/General/index.vr';
import { feedbackNoFinancialData } from '../Common/TextualInfo';

export const FinancialOverview = () => {
    const { balance, loading, error, visible } = useSelector(state => state.balanceState);
    const [d1Balance, setD1BalanceState] = React.useState(0);
    const [d2Balance, setD2BalanceState] = React.useState(0);

    const replaceString = '--';

    const isAssetsBalanceNegative = visible && balance && balance.Total < 0;
    const isBalanceAvailableNegative = visible && balance && balance.Available < 0;
    const isProjectedBalanceNegative = visible && balance && balance.ProjectedBalance < 0;
    const isD1BalanceNegative = visible && balance && d1Balance && d1Balance < 0;
    const isD2BalanceNegative = visible && balance && d2Balance && d2Balance < 0;

    React.useEffect(() => {
        if (!balance) {
            return;
        }

        const d1Cred = balance.Credits.filter(e => e.Day === 1).pop();
        const d1Debit = balance.Debits.filter(e => e.Day === 1).pop();

        const d2Cred = balance.Credits.filter(e => e.Day === 2).pop();
        const d2Debit = balance.Debits.filter(e => e.Day === 2).pop();

        const d1BalanceTotal = d1Cred.Value - d1Debit.Value;
        const d2BalanceTotal = d2Cred.Value - d2Debit.Value;

        setD1BalanceState(d1BalanceTotal);
        setD2BalanceState(d2BalanceTotal);
    }, [balance]);

    const renderTitle = rawValue => {
        const loadingFeedback = (!rawValue && rawValue !== 0) || loading;

        const renderLoadingFeedback = { title: <Skeleton height={16} width={112} /> };
        const renderDataFeedback = {
            title:
                visible && (rawValue || rawValue === 0) ? formatCurrency(rawValue) : replaceString,
        };

        return loadingFeedback ? renderLoadingFeedback : renderDataFeedback;
    };

    const renderContent = () => {
        const hasSomeData = !loading && !error && balance;
        const isLoadingOrHasData = loading || (!loading && !error && hasSomeData);
        const hasError = !loading && error;

        if (isLoadingOrHasData) {
            return displayDynamicContent();
        }

        if (hasError) {
            return <StyledNoDataFeedback>{feedbackNoFinancialData}</StyledNoDataFeedback>;
        }

        return null;
    };

    const displayDynamicContent = () => {
        return (
            <StyledInfo>
                <StyledHighlightedBalanceText
                    small
                    subTitle="Patrimônio"
                    isNegative={isAssetsBalanceNegative}
                    {...renderTitle(balance ? balance.Total : undefined)}
                />
                <SimpleVR />
                <StyledHighlightedBalanceText
                    small
                    subTitle="Saldo Disp."
                    isNegative={isBalanceAvailableNegative}
                    {...renderTitle(balance ? balance.Available : undefined)}
                />
                <SimpleVR />
                <StyledHighlightedBalanceText
                    small
                    subTitle="D+1"
                    isNegative={isD1BalanceNegative}
                    {...renderTitle(balance ? d1Balance : undefined)}
                />
                <SimpleVR />
                <StyledHighlightedBalanceText
                    small
                    subTitle="D+2"
                    isNegative={isD2BalanceNegative}
                    {...renderTitle(balance ? d2Balance : undefined)}
                />
                <SimpleVR />
                <StyledHighlightedBalanceText
                    small
                    subTitle="Saldo Projetado"
                    isNegative={isProjectedBalanceNegative}
                    {...renderTitle(balance ? balance.ProjectedBalance : undefined)}
                />
            </StyledInfo>
        );
    };

    return (
        <StyledWrapper>
            <StyledIconWrapper>
                <StyledHiddenBalanceSwitch padRight />
            </StyledIconWrapper>
            {renderContent()}
        </StyledWrapper>
    );
};

export default FinancialOverview;
