import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { Loading } from '@terrainvest/react-components';

import MobileVisualizeDocPrompt from './MobileVisualizeDocPrompt';

export interface GetSrcInstructions {
    fetchSrcData: Function;
    config?: { method: string };
    props?: Array<any>;
}

export const IframeShell = ({
    title,
    srcOverride = null,
    mobileSrcInstructions = null,
    desktopSrcInstructions = null,
    isBlob = false,
    ...otherProps
}) => {
    const { isTerraApp } = useSelector(state => state.mobileState);

    const [mobileSrc, setMobileSrc] = React.useState<string>();
    const [desktopSrc, setDesktopSrc] = React.useState<string>();
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        if (
            isTerraApp &&
            mobileSrcInstructions &&
            mobileSrcInstructions.fetchSrcData &&
            !mobileSrc
        ) {
            initInstructions(mobileSrcInstructions, setMobileSrc);
        }

        if (
            !isTerraApp &&
            desktopSrcInstructions &&
            desktopSrcInstructions.fetchSrcData &&
            !desktopSrc
        ) {
            initInstructions(desktopSrcInstructions, setDesktopSrc);
        }
    }, []);

    const initInstructions = async (instructions: GetSrcInstructions, setState) => {
        const src =
            instructions && instructions.props
                ? await instructions.fetchSrcData(...instructions.props)
                : await instructions.fetchSrcData();

        setState(src);
    };

    React.useEffect(() => {
        if (srcOverride || mobileSrc || desktopSrc) {
            setShow(true);
        }
    }, [mobileSrc, desktopSrc]);

    return (
        <>
            {show ? (
                <>
                    {isTerraApp ? (
                        <MobileVisualizeDocPrompt
                            url={srcOverride || mobileSrc}
                            config={get(mobileSrcInstructions, ['config'])}
                            isBlob={isBlob}
                            {...otherProps}
                        />
                    ) : (
                        <iframe title={title} src={srcOverride || desktopSrc} {...otherProps} />
                    )}
                </>
            ) : (
                <Loading visible />
            )}
        </>
    );
};

IframeShell.propTypes = {
    title: PropTypes.string.isRequired,
    srcOverride: PropTypes.string,
    mobileSrcInstructions: PropTypes.shape({
        fetchSrcData: PropTypes.func,
        props: PropTypes.arrayOf(PropTypes.any),
        config: PropTypes.shape({ method: PropTypes.string }),
    }),
    desktopSrcInstructions: PropTypes.shape({
        fetchSrcData: PropTypes.func,
        props: PropTypes.arrayOf(PropTypes.any),
        config: PropTypes.shape({ method: PropTypes.string }),
    }),
    isBlob: PropTypes.bool,
};

IframeShell.defaultProps = {
    srcOverride: undefined,
    desktopSrcInstructions: null,
    mobileSrcInstructions: null,
    isBlob: false,
};

export default IframeShell;
