import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from '@terrainvest/react-components';

import { selectAccountNumber } from '../../../Redux/Client';
import { RootState } from '../../../store';
import { logout } from '../../../Redux/Auth';

import HeaderProfileMenuButton from '../assets/HeaderProfileMenuButton';
import HeaderMenuContainer from '../assets/HeaderMenuContainer';
import HeaderProfileMenuItem from '../assets/HeaderProfileMenuItem';
import HeaderMenuSeparator from '../assets/HeaderMenuSeparator';
import HeaderLinkItem from '../assets/HeaderLinkItem';
import HeaderProfileNameItem from '../assets/HeaderProfileNameItem';
import HeaderLogOutItem from '../assets/HeaderLogOutItem';
import HeaderQualifiedInvestorItem from '../assets/HeaderQualifiedInvestorItem';

import Advisors from '../../Advisors';

export const ProfileMenu = ({ open, setOpen, history }) => {
    const { client, selectedAccount } = useSelector((state: RootState) => state.clientState);
    const { advisor } = useSelector((state: RootState) => state.advisorsState);
    const { minifiedProfile } = useSelector((state: RootState) => state.suitabilityProfileState);
    const accounts = client && client.Accounts ? client.Accounts : [];
    const { ProfessionalInvestor, QualifiedInvestor } = client;
    const dispatch = useDispatch();
    const id = 'header-menu-button';

    const handleCloseHeaderMenu = () => {
        setOpen(false);
    };

    const handleSelectedAccountNumber = account => {
        dispatch(selectAccountNumber(account));
        handleCloseHeaderMenu();
    };

    const hasAccountsAndMinifiedProfile = accounts.length && minifiedProfile !== undefined;

    return (
        <>
            <HeaderProfileMenuButton id={id} open={open} setOpen={setOpen} />
            <HeaderMenuContainer id={id} open={open} setClose={handleCloseHeaderMenu}>
                {hasAccountsAndMinifiedProfile ? (
                    <>
                        {accounts.map(account => (
                            <HeaderProfileMenuItem
                                key={account}
                                number={account}
                                name=""
                                selected={account === selectedAccount}
                                onClick={() => handleSelectedAccountNumber(account)}
                            />
                        ))}

                        <HeaderMenuSeparator />

                        {advisor && (
                            <>
                                <Advisors />
                                <HeaderMenuSeparator />
                            </>
                        )}

                        {QualifiedInvestor && (
                            <HeaderQualifiedInvestorItem professional={ProfessionalInvestor} />
                        )}

                        <HeaderLinkItem
                            name="Suitability"
                            dispatch={() => history.push('/app/perfil-de-investidor')}
                            clickedCallback={handleCloseHeaderMenu}
                        />

                        <HeaderProfileNameItem
                            profile={(minifiedProfile || { UserProfileName: null }).UserProfileName}
                            profileId={(minifiedProfile || { UserProfile: null }).UserProfile}
                        />

                        <HeaderLinkItem
                            dispatch={() => history.push('/app/dados-de-acesso')}
                            clickedCallback={handleCloseHeaderMenu}
                        />

                        <HeaderLinkItem
                            name="Dados pessoais"
                            dispatch={() => history.push('/app/dados-cadastrais')}
                            clickedCallback={handleCloseHeaderMenu}
                        />

                        <HeaderLinkItem
                            name="Contas e Subcontas"
                            dispatch={() => history.push('/app/contas-e-sub-contas')}
                            clickedCallback={handleCloseHeaderMenu}
                        />

                        <HeaderLinkItem
                            name="Termos e Contratos"
                            dispatch={() => history.push('/app/contratos')}
                            clickedCallback={handleCloseHeaderMenu}
                        />

                        <HeaderLogOutItem logout={() => dispatch(logout())} />
                    </>
                ) : (
                    <Skeleton height={300} width={230} />
                )}
            </HeaderMenuContainer>
        </>
    );
};

export default ProfileMenu;
