import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, Link, Loading } from '@terrainvest/react-components';
import Details from './Details';
import Addresses from './Addresses';
import { StyledAccountData, StyledHR, StyledH1 } from './index.styles';
import { RootState } from '../../store';
import { Commons } from '../../Redux/Common';
import Documents from './Documents';
import FinancialData from './FinancialData';
import { FormWithFormik, FormContext } from './Form';
import Suitability from './Suitability';
import { fetchSuitabilityProfileInit } from '../../Redux/Suitability';
import Attachments from './Attachments';
import Representatives from './Representatives';
import OrderIssuers from './OrderIssuers';
import { fetchClientAgreementsInit } from '../../Redux/Agreements';
import ProcuratorsInfo from './ProcuratorsInfo';
import ControllersInfo from './ControllersInfo';
import AdministratorsInfo from './AdministratorsInfo';
import AssetManagers from './AssetManagers';

const UserProfileForm = ({
    userHasExpiredData = false,
    suitabilityExpiredData = false,
    focusedAnchor,
    onClose = null,
    onSubmit = null,
    redirectOnSuccess = true,
}) => {
    const dispatch = useDispatch();
    const { client } = useSelector(state => state.clientState);
    const { profile } = useSelector(state => state.suitabilityProfileState);
    const CommonState = useSelector(state => state.commonState);
    const { isRlpActive } = useSelector((state: RootState) => state.rlpState);

    const isCompany = client && client.isCompany;

    const [formLoading, setFormLoading] = React.useState(true);
    const [initLoadingData, setInitLoadingData] = React.useState(false);

    React.useEffect(() => {
        dispatch(Commons.States.actions.fetchInit('BRA'));
        dispatch(Commons.Nationalities.actions.fetchInit());
        dispatch(Commons.Occupations.actions.fetchInit());
        dispatch(Commons.MaritalStatus.actions.fetchInit());
        dispatch(Commons.DocumentTypes.actions.fetchInit());
        dispatch(Commons.DocumentIssuerAgencies.actions.fetchInit());
        dispatch(Commons.Genders.actions.fetchInit());
        dispatch(Commons.Receipt.actions.fetchInit('F'));
        dispatch(Commons.Banks.actions.fetchInit());
        dispatch(Commons.Advisors.actions.fetchInit());
        dispatch(Commons.RepresentativeCategory.actions.fetchInit());
        dispatch(Commons.BusinessActivities.actions.fetchInit());
        setInitLoadingData(true);
    }, []);

    React.useEffect(() => {
        if ((client || {}).ClientId) {
            dispatch(fetchSuitabilityProfileInit(client.ClientId));
            dispatch(fetchClientAgreementsInit(client.ClientId));
        }
    }, [client]);

    React.useEffect(() => {
        if (
            initLoadingData &&
            !CommonState.States.loading &&
            !CommonState.Nationalities.loading &&
            !CommonState.Occupations.loading &&
            !CommonState.MaritalStatus.loading &&
            !CommonState.DocumentTypes.loading &&
            !CommonState.DocumentIssuerAgencies.loading &&
            !CommonState.Genders.loading &&
            !CommonState.Receipt.loading &&
            !CommonState.Banks.loading &&
            !CommonState.Advisors.loading &&
            !CommonState.RepresentativeCategory.loading &&
            !CommonState.BusinessActivities.loading
        ) {
            setFormLoading(false);
        }
    }, [CommonState, initLoadingData]);

    const getSuitabilityForm = () => (
        <>
            <Suitability />
            <StyledHR />
        </>
    );

    const getPersonForm = () => (
        <FormWithFormik
            initialValues={
                client ? { ...client, SuitabilityProfile: profile, RLPOffers: isRlpActive } : null
            }
            userHasExpiredData={userHasExpiredData}
            suitabilityExpiredData={suitabilityExpiredData}
            focusedAnchor={focusedAnchor ? focusedAnchor.ID : ''}
            onClose={onClose}
            redirectOnSuccess={redirectOnSuccess}
            onSubmit={onSubmit}
        >
            <Details isCompany={isCompany} />
            <StyledHR />
            <ProcuratorsInfo isCompany={false} />
            <StyledHR />
            <AssetManagers isCompany={false} />
            <StyledHR />
            <Addresses isCompany={isCompany} />
            <StyledHR />
            <Documents />
            <StyledHR />
            <FinancialData isCompany={isCompany} />
            <StyledHR />
            {getSuitabilityForm()}
            <FormContext.Consumer>
                {({ isUploadVisible }) => isUploadVisible && <Attachments isCompany={isCompany} />}
            </FormContext.Consumer>
        </FormWithFormik>
    );

    const getCompanyForm = () => (
        <FormWithFormik
            initialValues={client ? { ...client, SuitabilityProfile: profile } : null}
            userHasExpiredData={userHasExpiredData}
            suitabilityExpiredData={suitabilityExpiredData}
            focusedAnchor={focusedAnchor ? focusedAnchor.ID : ''}
            onClose={onClose}
            redirectOnSuccess={redirectOnSuccess}
            onSubmit={onSubmit}
        >
            <Details isCompany={isCompany} />
            <StyledHR />
            <ProcuratorsInfo isCompany />
            <StyledHR />
            <AssetManagers isCompany />
            <StyledHR />
            <Addresses isCompany={isCompany} />
            <StyledHR />
            <Representatives />
            <StyledHR />
            <ControllersInfo />
            <StyledHR />
            <AdministratorsInfo />
            <StyledHR />
            <OrderIssuers />
            <StyledHR />
            <FinancialData isCompany={isCompany} />
            <StyledHR />
            {getSuitabilityForm()}
            <FormContext.Consumer>
                {({ isUploadVisible }) => isUploadVisible && <Attachments isCompany={isCompany} />}
            </FormContext.Consumer>
        </FormWithFormik>
    );

    return (
        <StyledAccountData>
            {!userHasExpiredData && !suitabilityExpiredData && (
                <>
                    <Breadcrumb>
                        <Link to="/app" border={false} wrapper={RouterLink}>
                            Home
                        </Link>
                        <Link to="/app/dados-cadastrais" border={false} wrapper={RouterLink}>
                            Dados Pessoais
                        </Link>
                    </Breadcrumb>
                    <StyledH1>Meus Dados</StyledH1>
                </>
            )}
            {formLoading ? (
                <Loading visible />
            ) : (
                <>{isCompany ? getCompanyForm() : getPersonForm()}</>
            )}
        </StyledAccountData>
    );
};

UserProfileForm.defaultProps = {
    isCompany: false,
    focusedAnchor: null,
};

export default UserProfileForm;
