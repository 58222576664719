import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Button, Icon } from '@terrainvest/react-components';

import FormCard from '../Common/FormCard';
import { FORM_SECTIONS } from '../Form';

import getGUID from '../../../Utils/General/GUID';
import Separator from '../../../Utils/General/index.separator';
import FeedbackNote from '../../Common/FeedbackNote';
import ControllerInput from './ControllerInput';

export const ControllersInfo = () => {
    const { values, setFieldValue } = useFormikContext<any>();
    const { ClientId } = useSelector(state => state.clientState.client);

    const handleRemoveProcurator = index => {
        if (index >= -1) {
            const newControllers = [...values.Controllers];
            newControllers.splice(index, 1);

            setFieldValue('Controllers' as never, newControllers);
        }
    };

    const handleAdd = () => {
        const controllers = [
            ...(values.Controllers || []),
            {
                key: getGUID(),
                ClientId,
                Name: '',
                CnpjId: '',
                Category: '',
            },
        ];

        setFieldValue('Controllers' as never, controllers);
    };

    return (
        <FormCard
            title={FORM_SECTIONS.CONTROLLERS.NAME}
            subTitle={FORM_SECTIONS.CONTROLLERS.DETAILS}
        >
            {values &&
                values.Controllers &&
                values.Controllers.map((controller, index) => (
                    <ControllerInput
                        key={controller.CnpjId || controller.key}
                        index={index}
                        preffix={`Controllers[${index}]`}
                        onRemove={handleRemoveProcurator}
                    />
                ))}

            {values && (!values.Controllers || !values.Controllers.length) && (
                <>
                    <Separator />
                    <FeedbackNote color="warn" message={FORM_SECTIONS.CONTROLLERS.EMPTY_FEEDBACK} />
                    <Separator />
                </>
            )}

            <Separator />
            <Button type="button" fullWidth secondary onClick={handleAdd}>
                ADICIONAR {FORM_SECTIONS.CONTROLLERS.NAME}
                <Icon icon={['fas', 'plus']} padLeft size="xs" />
            </Button>
        </FormCard>
    );
};

export default ControllersInfo;
