import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { OpenFlatCard, Skeleton, Table, Text } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    width: 100vw;
`;

export const StyledCard = styled(OpenFlatCard)`
    width: 100%;
    padding: 12px 16px;

    ${media.min.md`
        display: none;
    `}
`;

export const StyledHeader = styled.div`
    padding: 0;
    margin: 0;

    ${props => `
        display: flex;
        align-items: center;

        & > i:first-of-type {
            margin-left: auto;
            color: ${props.theme.global.colors.doveGrayLight};
        }

    `};
`;

export const StyledTitle = styled(Text)`
    ${props => `
        font-family: ${props.theme.components.typography.headingsFontFamily};
        line-height: ${props.theme.components.typography.largeLineHeight};
        font-weight: ${props.theme.components.typography.config.fontWeight.semibold};
    `};
`;

export const StyledSkeleton = styled(Skeleton)`
    @media (max-width: 574px) {
        width: 120px;
    }

    @media (min-width: 575px) and (max-width: 777px) {
        width: 160px;
    }

    @media (min-width: 778px) and (max-width: 991px) {
        width: 160px;
    }

    ${media.xs`
        margin-left: 16px;
    `}
`;

export const StyledSpecialSkeleton = styled(Skeleton)`
    @media (max-width: 574px) {
        width: 160px;
    }

    @media (min-width: 575px) and (max-width: 777px) {
        width: 280px;
    }

    @media (min-width: 778px) and (max-width: 991px) {
        width: 280px;
    }
`;

export const StyledTableWrapper = styled.div`
    margin-top: 1rem;
    margin-bottom: 0.2rem;

    & > ${Table.Styles.StyledTable} {
        width: 100%;

        ${Table.Column.Styles.StyledRow} {
        }

        ${Table.Column.Styles.StyledColumn} {
        }
    }
`;

export const StyledNoDataFeedback = styled(Text)`
    color: ${props => props.theme.global.colors.error};
`;
