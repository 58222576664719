import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';

export const fetchBanksInit = clientId => ({
    type: Actions.BANKS_FETCH_INIT,
    clientId,
});

export const fetchBanks = clientId => {
    return fetch(ENDPOINTS.GET_BANKS.replace(':clientId', clientId), {
        method: 'GET',
    }).then(response => response.json());
};

export const fetchBanksSuccess = banks => ({
    type: Actions.BANKS_FETCH_SUCCESS,
    banks,
});

export const fetchBanksError = error => ({
    type: Actions.BANKS_FETCH_ERROR,
    error: error.message,
});
