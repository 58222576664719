import * as React from 'react';
import { useSelector } from 'react-redux';

import { Notification } from '@terrainvest/react-components';
import { RootState } from '../../../../store';

import { StyledButton } from './index.styles';
import RLPOptin from '../RLPOptin';

import { fetchHomebrokerToken } from '../../../../Redux/Homebroker/index.actions';

import { ENDPOINTS } from '../../../../Utils/Api';
import Authorizer from '../../../../Authorization/BaseAuthorizer';

export const Homebroker = () => {
    const { client, selectedAccount } = useSelector((state: RootState) => state.clientState);
    const { isRlpActive } = useSelector((state: RootState) => state.rlpState);

    const [errorFeedback, setErrorFeedback] = React.useState({ visible: false, message: '' });
    const [buttonVisible, setButtonVisible] = React.useState(false);
    const [modalOptions, setModalOptions] = React.useState({
        visible: false,
        firstEntry: true,
    });

    const handleOpenHomebroker = form => {
        const windowRef = window.open('', '', 'Homebroker');
        form.submit();
        document.body.removeChild(form);
        windowRef.close();
    };

    const appendToForm = (form, ...elements) => {
        elements.forEach(e => form.appendChild(e));
    };

    const appendToDocument = element => {
        document.body.appendChild(element);
    };

    const appendElementsToParent = (parent, ...elements) => {
        appendToForm(parent, ...elements);
        appendToDocument(parent);
    };

    const makeTokenInput = token => {
        const tokenInput = document.createElement('input');
        tokenInput.setAttribute('type', 'hidden');
        tokenInput.setAttribute('name', 'token');
        tokenInput.setAttribute('value', token);

        return tokenInput;
    };

    const makeFormElement = () => {
        const form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', ENDPOINTS.POST_HOMEBROKER_REDIRECT);
        form.setAttribute('target', 'Homebroker');
        form.setAttribute('id', 'hbForm');
        return form;
    };

    const handleHomebrokerInit = token => {
        const form = makeFormElement();
        const tokenInput = makeTokenInput(token);

        appendElementsToParent(form, tokenInput);
        handleOpenHomebroker(form);
    };

    const handleGetTokenError = err => {
        setErrorFeedback({ visible: true, message: err.message });
    };

    const randomOpenRule = max => {
        const aux = new Uint32Array(1);
        crypto.getRandomValues(aux);
        return aux[0] % max === 0;
    };

    const shouldDisplayRlpOptin = () => {
        return (
            !isRlpActive && isRlpActive != null && (modalOptions.firstEntry || randomOpenRule(3))
        );
    };

    const getToken = async () => {
        return fetchHomebrokerToken(selectedAccount);
    };

    const handleHomebroker = async () => {
        setErrorFeedback({ visible: false, message: '' });

        try {
            const { Token } = await getToken();
            handleHomebrokerInit(Token);
        } catch (err) {
            handleGetTokenError(err);
        }
    };

    const handleClick = () => {
        const rlpOptinWillOpen = shouldDisplayRlpOptin();
        setModalOptions({ ...modalOptions, visible: rlpOptinWillOpen, firstEntry: false });
        if (!rlpOptinWillOpen) {
            handleHomebroker();
        }
    };

    const handleCloseModal = () => {
        setModalOptions({ ...modalOptions, visible: false });
        handleHomebroker();
    };

    React.useEffect(() => {
        let isMounted = true;
        Authorizer.get('homebroker')
            .run({ client })
            .then(value => isMounted && setButtonVisible(value));
        return () => {
            isMounted = false;
        };
    }, [client]);

    return (
        <>
            <Notification fixed icon type="error" visible={errorFeedback.visible} timeout={4000}>
                {errorFeedback.message}
            </Notification>
            {modalOptions.visible && (
                <RLPOptin onclose={handleCloseModal} visible={modalOptions.visible} />
            )}
            {buttonVisible ? (
                <StyledButton
                    auth="homebroker"
                    outline
                    onClick={handleClick}
                    id="home-broker-button"
                >
                    HOME BROKER
                </StyledButton>
            ) : null}
        </>
    );
};
