import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeAgreements, normalizeAgreement } from './index.normalizers';

export const fetchClientAgreementsInit = clientId => ({
    type: Actions.CLIENT_AGREEMENTS_FETCH_INIT,
    clientId,
});

export const fetchClientAgreements = clientId =>
    fetch(ENDPOINTS.GET_CLIENT_AGREEMENT.replace(':clientId', clientId), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeAgreements);

export const fetchClientAgreementsSuccess = agreements => ({
    type: Actions.CLIENT_AGREEMENTS_FETCH_SUCCESS,
    agreements,
});

export const fetchClientAgreementsError = () => ({
    type: Actions.CLIENT_AGREEMENTS_FETCH_ERROR,
});

export const fetchFileAsObjUrl = filePath =>
    fetch(filePath, { method: 'GET', useBaseUrl: false })
        .then(response => response.blob())
        .then(blob => ({ blob, url: URL.createObjectURL(blob) }));

export const fetchClientAgreementByTypeInit = client => ({
    type: Actions.CLIENT_AGREEMENT_BY_TYPE_FETCH_INIT,
    client,
});

export const fetchClientAgreementByType = client => {
    return fetch(
        ENDPOINTS.GET_CLIENT_AGREEMENT_BY_TYPE.replace(':clientId', client.ClientId).replace(
            ':type',
            '1',
        ),
        {
            method: 'GET',
        },
    )
        .then(response => response.json())
        .then(agreement => normalizeAgreement(agreement));
};

export const fetchClientAgreementByTypeSuccess = data => ({
    type: Actions.CLIENT_AGREEMENT_BY_TYPE_FETCH_SUCCESS,
    agreement: data,
});

export const fetchClientAgreementByTypeError = () => ({
    type: Actions.CLIENT_AGREEMENT_BY_TYPE_FETCH_ERROR,
});

export const postClientAgreement = (agreement, client, electronicCheck) => {
    return fetch(ENDPOINTS.POST_COMPLIANCE_CLIENT_AGREEMENT, {
        method: 'POST',
        body: JSON.stringify({
            EletronicCheck: electronicCheck,
            ClientId: client.ClientId,
            AgreementId: agreement.AgreementId,
        }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};
