import styled from 'styled-components';

export const StyledErrorFeedback = styled.div`
    ${props => `
        display: flex;
        align-items: center;

        & i {
            color: ${props.theme.components.typography.color};
            font-size: 22px;
            margin-right: 6px;
        }
    `}
`;
