import styled from 'styled-components';

export const StyledHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`;

export const StyledRemove = styled.div`
    cursor: pointer;
    margin-left: auto;
`;
