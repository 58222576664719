import styled, { css } from 'styled-components';

export const StyledHeaderMenuSeparator = styled.div`
    ${() =>
        css`
            border: 1px solid #cdd4d5;
            opacity: 0.2;
            margin-left: 32px;
            margin-right: 32px;
            margin-top: 16px;
            margin-bottom: 16px;
        `}
`;
