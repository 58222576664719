import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchLifeInsurance,
    fetchLifeInsuranceSuccess,
    fetchLifeInsuranceError,
    fetchLifeInsuranceRequests,
    fetchLifeInsuranceRequestsError,
    fetchLifeInsuranceRequestsSuccess,
} from './index.actions';

export const initialState = {
    pensions: [],
    lifeInsuranceRequests: [],
    loading: false,
    error: false,
};

export const lifeInsuranceReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.LIFE_INSURANCE_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchLifeInsurance, {
                    successActionCreator: fetchLifeInsuranceSuccess,
                    failActionCreator: fetchLifeInsuranceError,
                }),
            );
        case Actions.LIFE_INSURANCE_FETCH_SUCCESS:
            return {
                ...state,
                pensions: action.pensions,
                loading: false,
                error: false,
            };
        case Actions.LIFE_INSURANCE_FETCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        case Actions.FETCH_LIFE_INSURANCE_REQUESTS_INIT:
            return loop(
                { ...state, loading: true, error: false },
                Cmd.run(fetchLifeInsuranceRequests, {
                    successActionCreator: fetchLifeInsuranceRequestsSuccess,
                    failActionCreator: fetchLifeInsuranceRequestsError,
                    args: [action.account],
                }),
            );
        case Actions.FETCH_LIFE_INSURANCE_REQUESTS_SUCCESS:
            return {
                ...state,
                lifeInsuranceRequests: action.requests,
                loading: false,
                errorStatus: false,
            };
        case Actions.FETCH_LIFE_INSURANCE_REQUESTS_ERROR:
            return { ...state, errorStatus: action.error, loading: false };

        default:
            return state;
    }
};
