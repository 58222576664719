export const Actions = {
    PARTNER_FETCH_INIT: 'PARTNER_FETCH_INIT',
    PARTNER_FETCH_SUCCESS: 'PARTNER_FETCH_SUCCESS',
    PARTNER_FETCH_ERROR: 'PARTNER_FETCH_ERROR',
};

export const FileTypes = {
    Menu: 'Menu',
    Icone: 'Icone',
    Login: 'Login',
};
