import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchBanks, fetchBanksSuccess, fetchBanksError } from './index.actions';

export const initialState = {
    banks: null,
    loading: false,
    error: null,
};

export const banksReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.BANKS_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchBanks, {
                    successActionCreator: fetchBanksSuccess,
                    failActionCreator: fetchBanksError,
                    args: [action.clientId],
                }),
            );
        case Actions.BANKS_FETCH_SUCCESS:
            return { ...state, banks: action.banks, loading: false, error: false };
        case Actions.BANKS_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
};
