import styled from 'styled-components';
import { Text, FormGroup } from '@terrainvest/react-components';

export const StyledBankAccount = styled.div`
    position: relative;

    & > :first-child {
        margin-top: 0;
    }
`;

export const StyledDelete = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    ${Text.Styles.StyledText} {
        margin: 0;
        display: flex;
        align-items: center;

        & > :last-child {
            margin-left: 8px;
        }
    }
`;

export const StyledFormGroup = styled(FormGroup)`
    ${props => `
        ${props.disabled &&
            `
                box-shadow: 0 0 0 1px ${props.theme.global.colors.doveGrayLightDisabled};
                :hover {
                    box-shadow: 0 0 0 1px ${props.theme.global.colors.doveGrayLightDisabled};
                }
                > p {
                    color: ${props.theme.global.colors.doveGrayLightDisabled} !important;
                }
            `}
    `}
`;
