import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { InputPassword, Link, Loading, useId } from '@terrainvest/react-components';

import { fetchForgotElectronicCheck } from '../../../Redux/Auth';
import { postNotificationFromError, postNotification } from '../../../Redux/Notification';

import { StyledWrapper, StyledHiddenInput } from './index.styles';

export const ElectronicPasswordInput = ({
    onChange,
    setLoading,
    label = 'Assinatura eletrônica',
    ...props
}) => {
    const dispatch = useDispatch();
    const { client } = useSelector(state => state.clientState);
    const [id] = useId('electronic-check-');

    const [innerLoading, setInnerLoading] = React.useState(false);
    const showLoading = setLoading || setInnerLoading;

    const handleForgotElectronicCheck = async e => {
        e.preventDefault();
        e.stopPropagation();

        showLoading(true);

        try {
            await fetchForgotElectronicCheck(client.UserId);

            dispatch(
                postNotification(
                    'Você receberá uma nova assinatura eletrônica no seu email em breve',
                    { timeout: 0, type: 'warn' },
                ),
            );
        } catch (error) {
            dispatch(postNotificationFromError(error));
        } finally {
            showLoading(false);
        }
    };

    return (
        <>
            <Loading visible={innerLoading} />
            <StyledWrapper>
                <StyledHiddenInput type="text" tabIndex={-1} />
                <StyledHiddenInput type="password" tabIndex={-1} />
                <InputPassword
                    id={id}
                    name="electronic-check"
                    autoComplete="off"
                    label={label}
                    {...props}
                    onChange={onChange}
                />
                <Link href="." onClick={e => handleForgotElectronicCheck(e)}>
                    Esqueci minha assinatura eletrônica
                </Link>
            </StyledWrapper>
        </>
    );
};

export default ElectronicPasswordInput;
