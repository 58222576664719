import {
    FormGroup,
    H2,
    Icon,
    InputCpfCnpj,
    InputDate,
    InputTel,
    InputText,
    Radio,
    Text,
} from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import * as React from 'react';
import Separator from '../../../../Utils/General/index.separator';
import { disclaimerTexts, StyledDisclaimerText } from '../../../Common/TextualInfo';
import AddressInput from '../../AdressInput';
import {
    StyledCol,
    StyledHeaderAddedItemWrapper,
    StyledRemoveItem,
    StyledRow,
} from '../../Common/GridStyles';
import { Field } from '../../Form/Field';
import { fieldLengthLimits } from '../../index.settings';
import {
    isRequiredValidDate,
    isValidEmail,
    isValidPhone,
    validateCpfCnpjValue,
    validateLength,
    validateRequiredField,
} from '../../index.validators';

export const AssetManagerInput = ({ index, preffix: id, onRemove, isCompany }) => {
    const { setFieldValue } = useFormikContext();
    const [showValidityDate, setShowValidityDate] = React.useState<boolean>();

    const preffix = id ? `${id}.` : '';

    const handleRemove = () => {
        onRemove(index);
    };

    const handleValidityIndeterminateChangedEnabled = () => {
        setFieldValue(`${preffix}ValidityIndeterminate` as never, true);
        setShowValidityDate(false);
    };

    const handleValidityIndeterminateChangedDisabled = () => {
        setFieldValue(`${preffix}ValidityIndeterminate` as never, false);
        setShowValidityDate(true);
    };

    return (
        <>
            <StyledHeaderAddedItemWrapper>
                <H2>{`Administrador de Carteira ${index + 1}`}</H2>
                <StyledRemoveItem onClick={handleRemove}>
                    <Text weight="medium">
                        Remover administrador de carteira <Icon icon="times" size="sm" padLeft />
                    </Text>
                </StyledRemoveItem>
            </StyledHeaderAddedItemWrapper>
            <Separator />
            <StyledRow>
                <StyledCol md={6} sm={12}>
                    <Field name={`${preffix}AssetManagerName`} validate={validateRequiredField}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Nome"
                                fullWidth
                                maxLength={fieldLengthLimits.name}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name={`${preffix}AssetManagerCpfCnpj`} validate={validateCpfCnpjValue}>
                        {({ inputProps }) => (
                            <InputCpfCnpj {...inputProps} label="CPF/CNPJ" fullWidth />
                        )}
                    </Field>
                </StyledCol>

                <AddressInput preffix={id} />

                <StyledCol md={4} sm={12}>
                    <Field
                        name={`${preffix}Skype`}
                        validate={value => validateLength('skype', value)}
                    >
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Skype"
                                fullWidth
                                maxLength={fieldLengthLimits.skype}
                            />
                        )}
                    </Field>
                </StyledCol>

                <StyledCol md={4} sm={12}>
                    <Field name={`${preffix}MobilePhone`} validate={isValidPhone}>
                        {({ inputProps }) => <InputTel {...inputProps} label="Celular" fullWidth />}
                    </Field>
                </StyledCol>

                <StyledCol md={4} sm={12}>
                    <Field name={`${preffix}Email`} validate={isValidEmail}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="E-mail"
                                fullWidth
                                maxLength={fieldLengthLimits.email}
                            />
                        )}
                    </Field>
                </StyledCol>

                {!isCompany && (
                    <StyledCol col={12}>
                        <StyledDisclaimerText small>
                            {disclaimerTexts.procurator.authorization}
                        </StyledDisclaimerText>
                    </StyledCol>
                )}

                <StyledCol md={12} sm={12}>
                    <Field name={`${preffix}ValidityIndeterminate`}>
                        {({ field }) => (
                            <FormGroup
                                name={field.name}
                                border
                                vertical={false}
                                fullWidth
                                onChange={evt => {
                                    field.onChange(evt);
                                }}
                            >
                                <Text>Validade</Text>
                                <Radio
                                    label="Indeterminada"
                                    defaultChecked={field.value === true}
                                    // eslint-disable-next-line react/jsx-boolean-value
                                    defaultValue={true}
                                    onChange={handleValidityIndeterminateChangedEnabled}
                                />
                                <Radio
                                    label="Até"
                                    defaultChecked={field.value === false}
                                    defaultValue={false}
                                    onChange={handleValidityIndeterminateChangedDisabled}
                                />
                            </FormGroup>
                        )}
                    </Field>
                </StyledCol>

                {showValidityDate && (
                    <StyledCol md={6} sm={12}>
                        <Field
                            name={`${preffix}ValidityDate`}
                            validate={showValidityDate && isRequiredValidDate}
                        >
                            {({ inputProps }) => (
                                <InputDate {...inputProps} label="Validade" fullWidth />
                            )}
                        </Field>
                    </StyledCol>
                )}
            </StyledRow>
        </>
    );
};

export default AssetManagerInput;
