import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Header, HeaderGroup, HeaderItem } from '@terrainvest/react-components';

export const StyledHeaderGroup = styled(HeaderGroup)``;

export const StyledLogoHeaderItem = styled(HeaderItem)`
    ${media.min.md`
        display: none;
    `}
`;

export const StyledHeaderComponent = styled(Header)`
    ${media.max.md`
        background-color: ${props => props.theme.global.colors.capeCodDarker};
        height: ${props => props.theme.global.spaces.highFrequencyRadio};
    `}
`;

export const SubtitleWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
    margin-top: -12px;

    text-transform: initial;
`;
