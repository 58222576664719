import * as React from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Login from '../Login';
import AppLogin from '../AppLogin';
import { withAuth } from '../../Utils/HOCs';

import StandardTemplate from '../../Template/StandardTemplate';
import ErrorBoundary from '../../ErrorBoundary';
import SuspenseLoading from './SuspenseLoading';
import ExpiredUserProfile from '../UserDataExpiration/ExpiredUserProfile';
import BlockOfferManager from '../BlockOfferManager';
import ExpiredIntermediationContract from '../UserDataExpiration/ExpiredIntermediationContract';
import HomebrokerExpiredSession from '../HomebrokerExpiredSession';

const LoggedRouter = React.lazy(() => import('./LoggedRouter'));
const PasswordRecovery = React.lazy(() => import('../PasswordRecovery'));
const CustomerCommunication = React.lazy(() => import('../CustomerCommunication'));

const PrivateRoutes = () => {
    const AuthenticatedRoute = withAuth(Route);
    return (
        <Switch>
            <AuthenticatedRoute exact path="/cadastro-expirado">
                <ExpiredUserProfile />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/termo-expirado">
                <ExpiredIntermediationContract />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/">
                <Redirect to="/app" />
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/app">
                <StandardTemplate>
                    <ErrorBoundary>
                        <React.Suspense fallback={<SuspenseLoading />}>
                            <LoggedRouter />
                        </React.Suspense>
                    </ErrorBoundary>
                </StandardTemplate>
            </AuthenticatedRoute>
            <Route>
                <Redirect to="/app" />
            </Route>
        </Switch>
    );
};

const PublicRoutes = () => {
    return (
        <React.Suspense fallback={<SuspenseLoading />}>
            <Switch>
                <Route path="/fale-conosco">
                    <CustomerCommunication />
                </Route>
                <Route path="/sessao-expirada-homebroker">
                    <HomebrokerExpiredSession />
                </Route>
                <Route path="/restricao-coordenacao-oferta">
                    <BlockOfferManager />
                </Route>
                <Route path="/recuperar-senha">
                    <PasswordRecovery />
                </Route>
                <Route path="/app-login/:accessToken/:account">
                    <AppLogin />
                </Route>
                <Route>
                    <Login />
                </Route>
            </Switch>
        </React.Suspense>
    );
};

const ViewRouter = () => {
    const { auth } = useSelector(state => state.authState);
    const { selectedAccount } = useSelector(state => state.clientState);

    const showPrivateRoutes = () => auth && auth.access_token && selectedAccount;

    const getRoutesContent = () => (showPrivateRoutes() ? <PrivateRoutes /> : <PublicRoutes />);

    return (
        <Router>
            <ErrorBoundary>{getRoutesContent()}</ErrorBoundary>
        </Router>
    );
};

export default ViewRouter;
