import styled from 'styled-components';
import { Text } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    padding-left: 32px;
    padding-right: 32px;
    overflow: hidden;
`;

export const StyledAvatarWrapper = styled.div`
    align-self: center;
    justify-self: center;
    text-align: center;

    margin-right: 32px;
    min-height: 32px;
    min-width: 32px;
`;

export const StyledInfoWrapper = styled.div``;

export const StyledHeader = styled(Text)`
    font-family: ${props => props.theme.components.typography.headingsFontFamily};
    font-size: 12px;
    font-weight: ${props => props.theme.components.typography.config.fontWeight.semibold};
`;

export const StyledInfoText = styled(Text)`
    font-family: ${props => props.theme.components.typography.headingsFontFamily};
    font-size: 12px;
    font-weight: ${props => props.theme.components.typography.config.fontWeight.regular};
`;
