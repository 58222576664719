import { normalizeString } from '../../Utils/Parsers';
import { FileTypes } from './index.constants';

const normalizeLogosData = partner => {
    if (!partner || !partner.files || !partner.files.length) {
        return undefined;
    }

    const logoMenu = partner.files.find(file => String(file.locationDescription) === FileTypes.Menu)
        .fileUrl;

    const logoIcon = partner.files.find(
        file => String(file.locationDescription) === FileTypes.Icone,
    ).fileUrl;

    const logoLogin = partner.files.find(
        file => String(file.locationDescription) === FileTypes.Login,
    ).fileUrl;

    return { logoLogin, logoMenu, logoIcon };
};

export const normalizePartner = partner => {
    const styleContent = JSON.parse(partner.style.css);
    const logosData = normalizeLogosData(partner);

    return {
        id: partner.id,
        advisorId: partner.advisorId,
        officeId: partner.officeId,
        name: partner.name,
        cnpj: normalizeString(partner.cnpj),
        logos: {
            login: logosData.logoLogin,
            menu: logosData.logoMenu,
            icon: logosData.logoIcon,
        },
        style: {
            id: partner.style.id,
            name: partner.style.name,
            ...styleContent,
        },
        urlCadastro: partner.urlCadastro,
        newAccountButton: partner.newAccountButton,
    };
};
