import * as React from 'react';
import {
    InputCurrency,
    InputDate,
    parseCurrency,
    Notification,
} from '@terrainvest/react-components';
import { useFormikContext, useField } from 'formik';
import { StyledFinalData, Wrapper } from './index.styles';
import { StyledRow, StyledCol } from '../Details/index.styles';
import BankAccount from './BankAccount';
import { StyledContainerNoPadd } from '../../Common/Grid';
import { validateCurrency, isValidDate } from '../index.validators';
import { Field } from '../Form/Field';
import { FORM_SECTIONS } from '../Form';

import FormCard from '../Common/FormCard';

export const StyledNotification = (props: any) => (
    <Wrapper>
        <Notification {...props} />
    </Wrapper>
);

const FinancialData = ({ isCompany }) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();

    let totalPatrimony = 0;
    const patrimonyMessage = 'O somatório do patrimônio deve ser maior que zero.';

    if (values && values.Patrimony) {
        totalPatrimony =
            values.Patrimony.FinancialInvestments +
            values.Patrimony.RealState +
            values.Patrimony.OtherAssets +
            values.Patrimony.MonthlyIncome +
            values.Patrimony.NetWorth +
            values.Patrimony.WorkingCapital +
            values.Patrimony.CapitalStock;
    }

    useField({
        name: 'PatrimonyValidation',
        value: totalPatrimony,
        validate: () => (totalPatrimony <= 0 ? patrimonyMessage : null),
    });

    const onRemoveBankAccount = index => {
        if (index >= 0) {
            const newAccounts = [...values.BankAccounts];
            newAccounts.splice(index, 1);

            setFieldValue('BankAccounts', newAccounts);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onAddBankAccount = () => {
        const bankAccount = values.BankAccounts[0];
        setFieldValue('BankAccounts', [
            ...values.BankAccounts,
            {
                ClientId: bankAccount.ClientId,
                AccountId: bankAccount.AccountId,
                BankNumber: '',
                AgencyNumber: '',
                AccountNumber: '',
                AccountDigit: '',
                AccountType: 0,
                CpfCoOwner: '',
                NameCoOwner: null,
                AgencyDigit: 0,
            },
        ]);
    };

    const handleCurrencyFieldChanged = (field, event) => {
        const changedValue = event.target.value ? parseCurrency(event.target.value) : null;
        setFieldValue(field.name, changedValue);
        setFieldTouched(field.name, !!changedValue, true);
    };

    const getPersonForm = () => (
        <>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.FinancialInvestments" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Aplicações Financeiras"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.MonthlyIncome" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Renda Mensal"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
        </>
    );

    const getCompanyForm = () => (
        <>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.NetWorth" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Patrimônio Líquido"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.WorkingCapital" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Capital de Giro"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.CapitalStock" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Capital Social"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.NetWorthDate" validate={isValidDate}>
                    {({ inputProps }) => (
                        <InputDate
                            {...inputProps}
                            id="patrimony-validate-net-worth-date"
                            label="Data Último Balanço"
                            fullWidth
                            icon={false}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.MonthlyAverageBilling" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Faturamento médio mensal (últimos 12 meses)"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.MonthlyAverageBillingDate" validate={isValidDate}>
                    {({ inputProps }) => (
                        <InputDate
                            {...inputProps}
                            id="patrimony-validate-monthly-billing-date"
                            label="Data Faturamento Médio Mensal"
                            fullWidth
                            icon={false}
                        />
                    )}
                </Field>
            </StyledCol>
        </>
    );

    return (
        <StyledFinalData id={FORM_SECTIONS.FINANCIAL_DATA.ID}>
            <FormCard title={FORM_SECTIONS.FINANCIAL_DATA.NAME}>
                <StyledContainerNoPadd fluid>
                    <StyledRow>
                        <StyledCol md={6} sm={12}>
                            <Field name="Patrimony.RealState" validate={validateCurrency}>
                                {({ field, inputProps }) => (
                                    <InputCurrency
                                        {...inputProps}
                                        label="Bens Imóveis"
                                        fullWidth
                                        onChange={evt => handleCurrencyFieldChanged(field, evt)}
                                    />
                                )}
                            </Field>
                        </StyledCol>
                        <StyledCol md={6} sm={12}>
                            <Field name="Patrimony.OtherAssets" validate={validateCurrency}>
                                {({ field, inputProps }) => (
                                    <InputCurrency
                                        {...inputProps}
                                        label="Outros Bens"
                                        fullWidth
                                        onChange={evt => handleCurrencyFieldChanged(field, evt)}
                                    />
                                )}
                            </Field>
                        </StyledCol>
                        {isCompany ? getCompanyForm() : getPersonForm()}
                    </StyledRow>
                    <StyledNotification type="error" visible={totalPatrimony <= 0} icon={false}>
                        {patrimonyMessage}
                    </StyledNotification>
                </StyledContainerNoPadd>
                {values &&
                    values.BankAccounts &&
                    values.BankAccounts.map((bankAccount, index) => (
                        <BankAccount
                            key={index.toString()}
                            bankAccount={bankAccount}
                            index={index}
                            onClose={
                                values.BankAccounts.length > 1
                                    ? () => onRemoveBankAccount(index)
                                    : null
                            }
                        />
                    ))}
                {/* <Button type="button" fullWidth secondary onClick={onAddBankAccount}>
                    <Icon icon={['fas', 'plus']} style={{ marginRight: '8px' }} size="xs" />
                    ADICIONAR CONTA
                </Button> */}
            </FormCard>
        </StyledFinalData>
    );
};

export default FinancialData;
