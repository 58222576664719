import { Button, Loading, Text } from '@terrainvest/react-components';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { markBannerAsUnread } from '../../../../Redux/Banners';
import { selectAccountNumber } from '../../../../Redux/Client';
import {
    isCadastralUpdate,
    needsAgreementUpdate,
    needsSuitabilityUpdate,
} from '../../../../Utils/General';
import { isExpiredDate } from '../../../../Utils/Parsers';
import Logo from '../../../Common/Logo';
import { StyledSelectAccount, StyledCol } from './index.styles';

export const AccountSelection = ({ isLoading, clientData }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { profile: suitabilityProfile } = useSelector(state => state.suitabilityProfileState);
    const { agreementByType: agreement } = useSelector(state => state.agreementsState);
    const { partner } = useSelector(state => state.partnerState);

    const onChooseAccount = account => {
        dispatch(selectAccountNumber(account));
        dispatch(markBannerAsUnread());

        if (
            isCadastralUpdate(clientData) ||
            isExpiredDate(clientData.DueDate) ||
            needsSuitabilityUpdate(suitabilityProfile)
        ) {
            history.push('/cadastro-expirado');
        } else if (needsAgreementUpdate(agreement)) {
            history.push('/termo-expirado');
        } else if (history && history.location && history.location.pathname) {
            history.push(history.location.pathname);
        } else {
            history.push('/');
        }
    };

    const handleAutoChooseWhenOneAccount = () => {
        if (clientData.Accounts && clientData.Accounts.length === 1) {
            onChooseAccount(clientData.Accounts[0]);
        }
    };

    React.useEffect(() => {
        if (!isLoading && suitabilityProfile && suitabilityProfile.Validity && agreement) {
            handleAutoChooseWhenOneAccount();
        }
    }, [isLoading, suitabilityProfile, agreement]);

    return (
        <StyledCol col={12}>
            <Loading visible={isLoading || !(suitabilityProfile && suitabilityProfile.Validity)} />
            {!isLoading &&
                suitabilityProfile &&
                suitabilityProfile.Validity &&
                clientData.Accounts.length > 1 && (
                    <StyledSelectAccount onSubmit={event => event.preventDefault()}>
                        <Logo type="verticalBlack" src={partner.logos.login} />
                        <Text large weight="medium">
                            Contas
                        </Text>
                        <Text>Selecione a conta que você quer acessar:</Text>
                        {clientData.Accounts.map(account => (
                            <Button
                                className="account-button"
                                key={account}
                                fullWidth
                                secondary
                                onClick={() => onChooseAccount(account)}
                            >
                                {account}
                            </Button>
                        ))}
                    </StyledSelectAccount>
                )}
        </StyledCol>
    );
};

export default AccountSelection;
