import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { History } from 'history';
import { withRouter } from 'react-router-dom';

import { HeaderGroup, HeaderItem } from '@terrainvest/react-components';

import { fetchClientInit } from '../../Redux/Client';

import { Homebroker } from './assets/Homebroker';

import SimpleVR from '../../Utils/General/index.vr';
import { StyledHeaderComponent, StyledLogoHeaderItem } from './index.styles';

import { FinancialOverview } from '../FinancialOverview';
import { ProfileMenu } from './ProfileMenu';
import { fetchAdvisorInit } from '../../Redux/Advisors';
import Logo from '../Common/Logo';

interface HeaderProps {
    history: History;
    width: string;
}

export const Header: React.FC<HeaderProps> = ({ history, width }: HeaderProps) => {
    const [hide, setHide] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const { client } = useSelector(state => state.clientState);
    const { partner } = useSelector(state => state.partnerState);

    const dispatch = useDispatch();

    const handleHideHeader: () => void = () => setHide(window.innerWidth < 768);

    React.useEffect(() => {
        if (!client) {
            dispatch(fetchClientInit());
        }

        handleHideHeader();
        window.addEventListener('resize', handleHideHeader);
        return () => {
            window.removeEventListener('resize', handleHideHeader);
        };
    }, []);

    React.useEffect(() => {
        if (client) {
            dispatch(fetchAdvisorInit(client.AdvisorId));
        }
    }, [client]);

    const handleSetOpen = () => {
        return setOpen(prev => !prev);
    };

    return (
        <StyledHeaderComponent shadow mobileBreakpoint={768} hide={hide} width={width}>
            <HeaderGroup left>
                <StyledLogoHeaderItem mobile>
                    <Logo
                        type="verticalWhite"
                        onClick={() => history.push('/app')}
                        src={partner.logos.menu}
                    />
                </StyledLogoHeaderItem>
                <HeaderItem>
                    <FinancialOverview />
                </HeaderItem>
            </HeaderGroup>
            <HeaderGroup right>
                <HeaderItem>
                    <Homebroker />
                </HeaderItem>
                <HeaderItem>
                    <SimpleVR />
                </HeaderItem>
                <HeaderItem mobile>
                    <ProfileMenu open={open} setOpen={handleSetOpen} history={history} />
                </HeaderItem>
            </HeaderGroup>
        </StyledHeaderComponent>
    );
};

export default withRouter(Header);
