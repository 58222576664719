import * as React from 'react';
import { Button } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import { StyledRepresentatives, StyledNotification } from './index.styles';
import Representative from './Representative';
import { FORM_SECTIONS } from '../Form';

import FormCard from '../Common/FormCard';

const Representatives = () => {
    const { values, setFieldValue } = useFormikContext<any>();

    const percentSum =
        values && values.Representatives
            ? values.Representatives.reduce(
                  (sum, representative) => (representative ? representative.Percentage : 0) + sum,
                  0,
              )
            : 0;

    const onRemoveRepresentative = representative => {
        const index = values.Representatives.indexOf(representative);
        if (index >= 0) {
            values.Representatives.splice(index, 1);
            setFieldValue('Representatives', [...values.Representatives]);
        }
    };

    const onAddRepresentative = () => {
        setFieldValue('Representatives', [
            ...values.Representatives,
            {
                ClientId: values.ClientId,
                Cpf: '',
                Name: '',
                Percentage: 0,
                Category: null,
            },
        ]);
    };

    return (
        <StyledRepresentatives id={FORM_SECTIONS.REPRESENTATIVES.ID}>
            <FormCard title={FORM_SECTIONS.REPRESENTATIVES.NAME}>
                {values &&
                    values.Representatives &&
                    values.Representatives.map((representative, index) => (
                        <Representative
                            key={index.toString()}
                            index={index}
                            hasError={percentSum !== 100}
                            onClose={
                                values.Representatives.length > 1
                                    ? () => onRemoveRepresentative(representative)
                                    : null
                            }
                        />
                    ))}
                {percentSum !== 100 && (
                    <StyledNotification type="error" visible={percentSum !== 100} icon={false}>
                        O somatório do percentual dos representantes deve ser exatemente 100.
                    </StyledNotification>
                )}
                <Button type="button" secondary fullWidth onClick={onAddRepresentative}>
                    ADICIONAR REPRESENTANTES +
                </Button>
            </FormCard>
        </StyledRepresentatives>
    );
};

export default Representatives;
