import * as React from 'react';
import { StyledHeaderLogOutItem } from './index.styles';

interface HeaderLogOutItemProps {
    logout?: () => void;
}

export const HeaderLogOutItem: React.FC<HeaderLogOutItemProps> = ({
    logout,
}: HeaderLogOutItemProps) => {
    return (
        <StyledHeaderLogOutItem onClick={logout}>
            <span>Sair</span>
        </StyledHeaderLogOutItem>
    );
};

HeaderLogOutItem.defaultProps = {
    logout: () => undefined,
};

export default HeaderLogOutItem;
