import styled from 'styled-components';
import { Text } from '@terrainvest/react-components';

export const StyledBalanceText = styled(Text)`
    color: ${props =>
        props.isNegative
            ? props.theme.global.colorAliases.error
            : props.theme.global.colors.doveGrayDark} !important;

    & > i {
        vertical-align: middle;
    }
`;

export default StyledBalanceText;
