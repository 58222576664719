import * as React from 'react';
import { Radio } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import { StyledDocuments, StyledFormGroup } from './index.styles';
import { StyledRow, StyledCol } from '../Details/index.styles';
import useWindowSize from '../../../Utils/Hooks/useWindowSize';
import { Field } from '../Form/Field';
import { StyledContainerNoPadd } from '../../Common/Grid';

import { FORM_SECTIONS } from '../Form';
import RGForm from './RGForm';
import CNHForm from './CNHForm';
import RNEForm from './RNEForm';

import FormCard from '../Common/FormCard';

enum DocumentIssuerNames {
    CNH = 'CH',
    RNE = 'RN',
}

const Documents = () => {
    const formik = useFormikContext<any>();
    const windowSize = useWindowSize();

    const cutString = (str, cuttedStr) => (windowSize.width < 768 ? cuttedStr : str);
    const clearFields = () => {
        formik.setFieldValue('DocumentNumber', '');
        formik.setFieldValue('DocumentIssuerDate', '');
        formik.setFieldValue('DocumentIssuerState ', '');
        formik.setFieldValue('DocumentSecondaryNumber ', '');
    };

    React.useEffect(() => {
        setTimeout(() => formik.validateForm(), 300);
    }, [formik.values]);

    return (
        <StyledDocuments id={FORM_SECTIONS.DOCUMENTS.ID}>
            <StyledContainerNoPadd fluid>
                <FormCard title={FORM_SECTIONS.DOCUMENTS.NAME}>
                    <StyledRow>
                        <StyledCol col={12}>
                            <Field name="DocumentTypeId">
                                {({ field }) => (
                                    <StyledFormGroup
                                        name={field.name}
                                        vertical={false}
                                        fullWidth
                                        onChange={evt => {
                                            clearFields();
                                            field.onChange(evt);
                                        }}
                                    >
                                        <Radio
                                            label={cutString('RG - Registro Geral', 'RG')}
                                            defaultChecked={field.value === 'RG'}
                                            defaultValue="RG"
                                            onChange={() => {
                                                formik.setFieldValue('DocumentIssuerName', '');
                                            }}
                                        />
                                        <Radio
                                            label={cutString(
                                                'CNH -  Carteira Nacional de Habilitação',
                                                'CNH',
                                            )}
                                            defaultChecked={field.value === 'CH'}
                                            defaultValue="CH"
                                            onChange={() => {
                                                formik.setFieldValue(
                                                    'DocumentIssuerName',
                                                    DocumentIssuerNames.CNH,
                                                );
                                            }}
                                        />
                                        <Radio
                                            label={cutString(
                                                'RNE - Registro Nacional de Estrangeiro',
                                                'RNE',
                                            )}
                                            defaultChecked={field.value === 'RN'}
                                            defaultValue="RN"
                                            onChange={() => {
                                                formik.setFieldValue(
                                                    'DocumentIssuerName',
                                                    DocumentIssuerNames.RNE,
                                                );
                                            }}
                                        />
                                    </StyledFormGroup>
                                )}
                            </Field>
                        </StyledCol>
                    </StyledRow>
                    {(!formik.values || formik.values.DocumentTypeId === 'RG') && <RGForm />}
                    {(!formik.values || formik.values.DocumentTypeId === 'CH') && <CNHForm />}
                    {(!formik.values || formik.values.DocumentTypeId === 'RN') && <RNEForm />}
                </FormCard>
            </StyledContainerNoPadd>
        </StyledDocuments>
    );
};

export default Documents;
