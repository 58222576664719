import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { SideNav, HeaderItem, Popover, Logo } from '@terrainvest/react-components';
import { StyledColNoPadd } from '../../View/Common/Grid';

export const StyledCol = styled(StyledColNoPadd)`
    padding: 20px 30px;
    box-sizing: border-box;

    ${media.max.xs`
        padding: 20px 10px;
    `}
`;

export const StyledSideBarAndHeader = styled.div`
    ${media.min.tablet`
        ${SideNav.Styles.StyledSideNav} {
            top: 0;
        }
    `}

    ${HeaderItem.Styles.StyledHeaderItem} {
        ${Popover.Styles.StyledPopover} {
            &:before {
                content: '';
                width: 0px;
                height: 0px;
                position: absolute;
                border-left: 8px solid white;
                border-right: 8px solid white;
                border-bottom: 13px solid #fff;
                right: 12px;
                border-color: transparent transparent #fff;
                top: -13px;
            }
        }

        ${Logo.Styles.StyledLogo} {
            cursor: pointer;
        }
    }
`;
