import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SideNav, SideNavItem } from '@terrainvest/react-components';
import { History } from 'history';
import { RouteComponentProps } from 'react-router';

import Sections from './Sections';
import MobileSidebar from './MobileSidebar';

interface SidebarProps {
    history: History;
    defaultSelected: string;
    collapseCallback: (value: boolean) => void;
}

export const HOME_ITEM_ID = 'side-nav-item-home';

export const Sidebar: React.FC<RouteComponentProps & SidebarProps> = ({
    history,
    defaultSelected = HOME_ITEM_ID,
    collapseCallback,
}) => {
    const { partner } = useSelector(state => state.partnerState);

    return (
        <SideNav
            shadow
            defaultSelected={defaultSelected}
            mobileBreakpoint={768}
            isCollapsable
            logoOpen={partner.logos.menu}
            logoClosed={partner.logos.icon}
            logoCallback={() => history.push('/app')}
            collapseCallback={collapseCallback}
        >
            <MobileSidebar history={history} />

            <SideNavItem
                title="Visão Geral"
                icon={['far', 'money-check']}
                mobileNavItem
                id={HOME_ITEM_ID}
                onClick={() => history.push('/app')}
            />

            <Sections />
        </SideNav>
    );
};

export default withRouter(Sidebar);
