import styled from 'styled-components';
import { Text } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    width: 56px;
    height: 56px;
    line-height: 56px;
    border-radius: 50%;
    font-size: 30px;
    text-align: center;

    background-color: ${props =>
        props.bgColor ? props.bgColor : props.theme.global.colors.capeCode};
`;

export const StyledInnerAvatarText = styled(Text)`
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 56px;
    color: ${props => (props.color ? props.color : props.theme.global.colors.white)};
`;
