import * as React from 'react';
import { Button } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import { StyledOrderIssuers } from './index.styles';
import OrderIssuer from './OrderIssuer';
import { FORM_SECTIONS } from '../Form';

import FormCard from '../Common/FormCard';

const OrderIssuers = () => {
    const { values, setFieldValue } = useFormikContext<any>();

    const onRemoveOrderIssuer = orderIssuer => {
        const index = values.OrderIssuers.indexOf(orderIssuer);
        if (index >= 0) {
            values.OrderIssuers.splice(index, 1);
            setFieldValue('OrderIssuers', [...values.OrderIssuers]);
        }
    };

    const onAddOrderIssuer = () => {
        setFieldValue('OrderIssuers', [
            ...values.OrderIssuers,
            {
                ClientId: values.ClientId,
                Cpf: '',
                Name: '',
                Rg: '',
            },
        ]);
    };

    return (
        <StyledOrderIssuers id={FORM_SECTIONS.ORDER_ISSUERS.ID}>
            <FormCard title={FORM_SECTIONS.ORDER_ISSUERS.NAME}>
                {values &&
                    values.OrderIssuers &&
                    values.OrderIssuers.map((orderIssuer, index) => (
                        <OrderIssuer
                            key={index.toString()}
                            index={index}
                            onClose={() => onRemoveOrderIssuer(orderIssuer)}
                        />
                    ))}
                <Button type="button" secondary fullWidth onClick={onAddOrderIssuer}>
                    ADICIONAR EMISSORES +
                </Button>
            </FormCard>
        </StyledOrderIssuers>
    );
};

export default OrderIssuers;
