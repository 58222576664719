export const Actions = {
    WITHDRAW_FETCH_INIT: 'WITHDRAW_FETCH_INIT',
    WITHDRAW_FETCH_SUCCESS: 'WITHDRAW_FETCH_SUCCESS',
    WITHDRAW_FETCH_ERROR: 'WITHDRAW_FETCH_ERROR',
    WITHDRAW_VALUES_FETCH_INIT: 'WITHDRAW_VALUES_FETCH_INIT',
    WITHDRAW_VALUES_FETCH_SUCCESS: 'WITHDRAW_VALUES_FETCH_SUCCESS',
    WITHDRAW_VALUES_FETCH_ERROR: 'WITHDRAW_VALUES_FETCH_ERROR',
    WITHDRAW_HISTORY_FETCH_INIT: 'WITHDRAW_HISTORY_FETCH_INIT',
    WITHDRAW_HISTORY_FETCH_SUCCESS: 'WITHDRAW_HISTORY_FETCH_SUCCESS',
    WITHDRAW_HISTORY_FETCH_ERROR: 'WITHDRAW_HISTORY_FETCH_ERROR',
};
