import * as React from 'react';
import * as PropTypes from 'prop-types';

import { StyledWrapper, StyledInnerAvatarText } from './index.styles';
import { normalizeSpecialCharacters } from '../../../Utils/Parsers';

export const AvatarFromText = ({ text, color, bgColor }) => {
    const parseText = incomingText => {
        const parsedText = normalizeSpecialCharacters(incomingText);
        const textAsArray = parsedText.split(' ');
        const innerText =
            textAsArray.length && textAsArray.length > 1
                ? `${textAsArray[0][0]}${textAsArray[textAsArray.length - 1][0]}`
                : `${textAsArray[0][0]}`;

        return innerText.toUpperCase();
    };

    const innerText = text ? parseText(text) : '';

    return (
        <StyledWrapper color={color} bgColor={bgColor}>
            <StyledInnerAvatarText>{innerText}</StyledInnerAvatarText>
        </StyledWrapper>
    );
};

AvatarFromText.defaultProps = {
    text: '',
    color: '',
    bgColor: '',
};

AvatarFromText.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string,
};

AvatarFromText.propTypes = {};

export default AvatarFromText;
