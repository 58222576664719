import { Button, Checkbox, Icon } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { FormContext } from '..';
import Separator from '../../../../Utils/General/index.separator';
import ElectronicPasswordInput from '../../../Common/ElectronicPasswordInput';
import { StyledButtons } from './index.styles';

export const Footer = ({
    userHasExpiredData,
    setLoading,
    checkedNoDataChanged,
    setCheckedNoDataChanged,
}) => {
    const { values, touched, dirty } = useFormikContext<any>();
    const {
        onSubmit,
        onClose,
        setIsUploadVisible,
        isUploadVisible,
        onlySuitability,
        electronickCheck,
        setElectronicCheck,
    } = React.useContext(FormContext);

    const isCompany = values && values.isCompany;
    const isAttachmentsRequired = isCompany
        ? touched &&
          (touched.Address ||
              touched.Name ||
              touched.Representatives ||
              touched.Activity ||
              touched.Patrimony ||
              touched.BankAccounts ||
              touched.OrderIssuers ||
              (touched.Procurators && values && values.AuthorizesOrdersByProcurator) ||
              (touched.AssetManagers && values && values.AuthorizesOrdersByAdministrator))
        : touched &&
          (touched.Documents ||
              touched.Address ||
              (touched.BankAccounts &&
                  values.BankAccounts &&
                  values.BankAccounts.some(account => account && account.CpfCoOwner)) ||
              (touched.Procurators && values && values.AuthorizesOrdersByProcurator) ||
              (touched.AssetManagers && values && values.AuthorizesOrdersByAdministrator));

    const isInvalidProcurators = () =>
        values.AuthorizesOrdersByProcurator && (!values.Procurators || !values.Procurators.length);

    const disableNextButton = !values || isInvalidProcurators();

    const showBtnCancel = !userHasExpiredData;
    const showBtnFinalize = (!isAttachmentsRequired && !isUploadVisible) || onlySuitability;
    const showBtnNext = isAttachmentsRequired && !isUploadVisible && !onlySuitability;
    const showBtnSave = isUploadVisible;

    React.useEffect(() => {
        setCheckedNoDataChanged(!dirty && !onlySuitability);
    }, [dirty, onlySuitability]);

    const areAnswersFilled = questions => {
        if (!questions.length) {
            return false;
        }
        for (let i = 0; i < questions.length; i += 1) {
            const question = questions[i];
            if (question.Questions) {
                if (!areAnswersFilled(question.Questions)) {
                    return false;
                }
            } else if (!question.Answers || !question.Answers.length) {
                return false;
            }
        }
        return true;
    };

    const getSuitabilityDisabled = () => {
        const answersFilled =
            values &&
            values.SuitabilityProfile &&
            values.SuitabilityProfile.Questions &&
            areAnswersFilled(values.SuitabilityProfile.Questions);
        return !answersFilled && !electronickCheck;
    };

    const handleSubmit = () => {
        onSubmit();
    };

    const handleNoDataChange = () => {
        setCheckedNoDataChanged(!checkedNoDataChanged);
    };

    const handleSetEletronicCheck = value => {
        setElectronicCheck(value);
    };

    return (
        <>
            {!dirty && !onlySuitability && (
                <>
                    <Separator />
                    <Checkbox
                        onChange={handleNoDataChange}
                        checked={checkedNoDataChanged}
                        label="Declaro que não houve alteração nos meus dados cadastrais."
                        fullWidth
                        defaultChecked={checkedNoDataChanged}
                    />
                </>
            )}

            <Separator />

            <ElectronicPasswordInput
                name="electronicCheck"
                id="electronicCheck"
                autocomplete="electronic-check"
                label="Assinatura eletrônica"
                fullWidth
                icon
                setLoading={setLoading}
                onChange={evt => handleSetEletronicCheck(evt.target.value)}
            />

            <Separator />
            <StyledButtons>
                {showBtnCancel && (
                    <Button onClick={onClose} type="button" tertiary>
                        Cancelar
                    </Button>
                )}

                {showBtnFinalize && !onlySuitability && (
                    <Button
                        type="submit"
                        disabled={
                            !values ||
                            !electronickCheck ||
                            (!isCompany && !dirty && !checkedNoDataChanged)
                        }
                        onClick={handleSubmit}
                    >
                        FINALIZAR
                    </Button>
                )}

                {showBtnFinalize && onlySuitability && (
                    <Button
                        type="submit"
                        disabled={getSuitabilityDisabled()}
                        onClick={handleSubmit}
                    >
                        FINALIZAR
                    </Button>
                )}

                {showBtnNext && (
                    <Button
                        type="submit"
                        disabled={disableNextButton}
                        onClick={() => setIsUploadVisible(true)}
                    >
                        PRÓXIMO <Icon icon="chevron-right" size="xs" padLeft />
                    </Button>
                )}

                {showBtnSave && (
                    <Button
                        type="submit"
                        disabled={!values || !electronickCheck}
                        onClick={handleSubmit}
                    >
                        SALVAR ALTERAÇÕES
                    </Button>
                )}
            </StyledButtons>
        </>
    );
};
