/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Checkbox, Link, Notification } from '@terrainvest/react-components';
import HybridLink from '../../../Common/HybridLink';
import HelpButton from '../../../Common/HelpButton';

import { RootState } from '../../../../store';

import { Field } from '../../Form/Field';
import { StyledSkeleton } from '../../Form/index.styles';
import Separator from '../../../../Utils/General/index.separator';

import {
    pepDescription,
    rlpDescription,
    rlpUnmarkWhileActive,
    usPersonDescription,
    PeopleLinkedtoTerraInvestimentos,
    PoliticallyExposedPerson,
    USPerson,
} from './index.constants';

import { StyledFlagsFormGroup, StyledDescription, StyledCheckboxWithInfo } from './index.styles';
import { StyledCol } from '../index.styles';

import { addNewEmptyProcurator, clearProcurators } from '../../ProcuratorsInfo/index.model';
import { addNewEmptyAssetManager, clearAssetManagers } from '../../AssetManagers';
import { ENDPOINTS } from '../../../../Utils/Api';

const COMPLIANCE_AGREEMENT_FILE_ID = '33';
const AGREEMENT_ID = 34;

const Flags = () => {
    const formikContext = useFormikContext<any>();
    const { values, setFieldValue } = formikContext;

    const { client } = useSelector((state: RootState) => state.clientState);
    const agreementsState = useSelector((state: RootState) => state.agreementsState);
    const { isRlpActive } = useSelector((state: RootState) => state.rlpState);

    const [rlpNotificationVisible, setRlpNotificationVisible] = React.useState(false);

    const fileUrl = `${process.env.API_URL}${ENDPOINTS.GET_AGREEMENT_FILE.replace(
        ':agreementId',
        COMPLIANCE_AGREEMENT_FILE_ID,
    )}`;
    const agreementFileUrl = `${process.env.API_URL}${ENDPOINTS.GET_AGREEMENT_FILE.replace(
        ':agreementId',
        AGREEMENT_ID.toString(),
    )}`;
    const hasNoProcurator = !values || !values.Procurators || !values.Procurators.length;
    const hasProcurator = values && values.Procurators && values.Procurators.length > 0;
    const hasNoAssetManager = !values || !values.AssetManagers || !values.AssetManagers.length;
    const hasAssetManager = values && values.AssetManagers && values.AssetManagers.length > 0;
    const handleProcuratorAuthorizationChanged = (event: React.SyntheticEvent, fieldChanged) => {
        const { checked } = { ...event.target } || { checked: false };
        const applyAuthorization = checked && checked === true;
        fieldChanged(event);
        return applyAuthorization ? handleAuthorizeProcurator() : handleUnauthorizeProcurator();
    };
    const handleAuthorizeProcurator = () => {
        return hasNoProcurator && addNewEmptyProcurator(formikContext, client.ClientId);
    };
    const handleUnauthorizeProcurator = () => {
        return hasProcurator && clearProcurators(formikContext);
    };
    const handleAssetManagerAuthorizationChanged = (event: React.SyntheticEvent, fieldChanged) => {
        const { checked } = { ...event.target };
        const applyAuthorization = checked && checked === true;
        fieldChanged(event);
        return applyAuthorization
            ? handleAuthorizeAssetManagers()
            : handleUnauthorizeAssetManagers();
    };
    const handleAuthorizeAssetManagers = () => {
        return hasNoAssetManager && addNewEmptyAssetManager(formikContext, client.ClientId);
    };
    const handleUnauthorizeAssetManagers = () => {
        return hasAssetManager && clearAssetManagers(formikContext);
    };

    const handleInvestorAccept = (investor: string) => {
        switch (investor) {
            case 'QualifiedInvestor':
                setFieldValue('QualifiedInvestor', !values.QualifiedInvestor);
                setFieldValue('ProfessionalInvestor', false);
                break;
            case 'ProfessionalInvestor':
                setFieldValue('ProfessionalInvestor', !values.ProfessionalInvestor);
                setFieldValue('QualifiedInvestor', true);
                break;

            default:
                break;
        }
    };

    const handleRLPOffersChange = () => {
        if (isRlpActive) {
            setRlpNotificationVisible(true);
        }
        setFieldValue('RLPOffers', isRlpActive || !values.RLPOffers);
    };

    return (
        <>
            <StyledCol col={12}>
                <Notification
                    type="warn"
                    fixed
                    visible={rlpNotificationVisible}
                    timeout={5000}
                    onClose={() => setRlpNotificationVisible(false)}
                >
                    {rlpUnmarkWhileActive}
                </Notification>
                <StyledFlagsFormGroup vertical name="flags-group">
                    <StyledCheckboxWithInfo>
                        <Field name="LinkedPerson">
                            {({ field }) => (
                                <Checkbox
                                    name={field.name}
                                    onChange={field.onChange}
                                    label="Sou pessoa vinculada à Terra Investimentos"
                                    fullWidth
                                    defaultChecked={field.value}
                                />
                            )}
                        </Field>
                        <HelpButton
                            content={PeopleLinkedtoTerraInvestimentos}
                            messageWidth="350px"
                            icon={['fas', 'info-circle']}
                        />
                    </StyledCheckboxWithInfo>
                    <StyledDescription title={usPersonDescription}>
                        <StyledCheckboxWithInfo>
                            <Field name="UsPerson">
                                {({ field }) => (
                                    <Checkbox
                                        name={field.name}
                                        onChange={field.onChange}
                                        label="Sou US Person"
                                        fullWidth
                                        defaultChecked={field.value}
                                    />
                                )}
                            </Field>
                            <HelpButton
                                content={USPerson}
                                messageWidth="350px"
                                icon={['fas', 'info-circle']}
                            />
                        </StyledCheckboxWithInfo>
                    </StyledDescription>

                    <StyledDescription title={pepDescription}>
                        <StyledCheckboxWithInfo>
                            <Field name="PoliticalyExposedPerson">
                                {({ field }) => (
                                    <Checkbox
                                        name={field.name}
                                        onChange={field.onChange}
                                        label="Sou pessoa politicamente exposta"
                                        fullWidth
                                        defaultChecked={field.value}
                                    />
                                )}
                            </Field>
                            <HelpButton
                                content={PoliticallyExposedPerson}
                                messageWidth="350px"
                                icon={['fas', 'info-circle']}
                            />
                        </StyledCheckboxWithInfo>
                    </StyledDescription>
                    <Field name="AuthorizesOrdersByProcurator">
                        {({ field }) => (
                            <Checkbox
                                name={field.name}
                                onChange={evt =>
                                    handleProcuratorAuthorizationChanged(evt, field.onChange)
                                }
                                label="Autorizo transmissão de ordens via procurador"
                                fullWidth
                                defaultChecked={field.value}
                            />
                        )}
                    </Field>
                    <Field name="AuthorizesOrdersByAdministrator">
                        {({ field }) => (
                            <Checkbox
                                name={field.name}
                                onChange={evt =>
                                    handleAssetManagerAuthorizationChanged(evt, field.onChange)
                                }
                                label="Autorizo a transmissão de ordens por Administrador de Carteira"
                                fullWidth
                                defaultChecked={field.value}
                            />
                        )}
                    </Field>
                    {agreementsState.loading ? (
                        <>
                            <Separator base={8} />
                            <StyledSkeleton height={48} width={900} duration={2} />
                        </>
                    ) : (
                        <Field name="QualifiedInvestor">
                            {({ field }) => (
                                <Checkbox
                                    name={field.name}
                                    defaultChecked={field.value}
                                    checked={values.QualifiedInvestor}
                                    onClick={() => handleInvestorAccept('QualifiedInvestor')}
                                    label={
                                        <>
                                            Sou investidor qualificado, li e aceito a&nbsp;
                                            <HybridLink overrideGetUrl href={fileUrl}>
                                                <Link border>
                                                    Declaração de Investidor Qualificado.
                                                </Link>
                                            </HybridLink>
                                        </>
                                    }
                                />
                            )}
                        </Field>
                    )}
                    {agreementsState.loading ? (
                        <>
                            <Separator base={8} />
                            <StyledSkeleton height={48} width={900} duration={2} />
                        </>
                    ) : (
                        <Field name="ProfessionalInvestor">
                            {({ field }) => (
                                <Checkbox
                                    name={field.name}
                                    defaultChecked={field.value}
                                    checked={values.ProfessionalInvestor}
                                    onClick={() => handleInvestorAccept('ProfessionalInvestor')}
                                    label={
                                        <>
                                            Sou investidor profissional, li e aceito a&nbsp;
                                            <HybridLink overrideGetUrl href={agreementFileUrl}>
                                                <Link>Declaração de Investidor Profissional.</Link>
                                            </HybridLink>
                                        </>
                                    }
                                />
                            )}
                        </Field>
                    )}

                    <StyledCheckboxWithInfo>
                        <Field name="RLPOffers">
                            {({ field }) => (
                                <Checkbox
                                    name={field.name}
                                    checked={values.RLPOffers}
                                    onClick={handleRLPOffersChange}
                                    label="Autorizo operar ofertas RLP"
                                    fullWidth
                                />
                            )}
                        </Field>
                        <HelpButton
                            content={rlpDescription}
                            messageWidth="350px"
                            icon={['fas', 'info-circle']}
                        />
                    </StyledCheckboxWithInfo>
                </StyledFlagsFormGroup>
            </StyledCol>
        </>
    );
};

Flags.defaultProps = {
    isCompany: false,
};

export default Flags;
