import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Icon } from '@terrainvest/react-components';

import { toggleBalanceVisibility } from '../../../../Redux/Balance';

import { StyledSwitch, StyledWrapper } from './index.styles';

export const HiddenBalanceSwitch = ({ icon = null, size = 'lg', ...otherProps }) => {
    const { visible } = useSelector(state => state.balanceState);
    const dispatch = useDispatch();

    const toggleFinancialVisibility = () => {
        dispatch(toggleBalanceVisibility());
    };

    return (
        <StyledWrapper
            onClick={toggleFinancialVisibility}
            title="Alterar visibilidade dos dados financeiros"
        >
            <StyledSwitch enableView={visible}>
                <Icon
                    {...otherProps}
                    size={size}
                    id="hiddenBalanceSwitch"
                    icon={icon || (visible ? ['fas', 'eye'] : ['fas', 'eye-slash'])}
                    padLeft
                />
            </StyledSwitch>
        </StyledWrapper>
    );
};

export default HiddenBalanceSwitch;
