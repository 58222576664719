import React from 'react';
import PropTypes from 'prop-types';
import { StyledImg } from './index.styles';

const defaultValues = {
    verticalWhite: {
        width: '200px',
        height: '100px',
    },
    verticalBlack: {
        width: '200px',
        height: '100px',
    },
};

const Logo = ({ src, type, ...otherProps }) => (
    <StyledImg
        src={src}
        alt={`logo ${type}`}
        height={defaultValues[type].height}
        width={defaultValues[type].width}
        {...otherProps}
    />
);

Logo.propTypes = {
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default Logo;
