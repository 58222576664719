import { parseDateForDisplay } from '../../Utils/Parsers';
import { formatComplexValues } from '../../Utils/Parsers/numberParsers';

const normalizePercentage = value => (value ? String(value).replace('.', ',') : value);

const normalizeBasicPension = pension => ({
    id: pension.Id,
    strategy: pension.AssetType ? pension.AssetType.Description : '',
    name: pension.Name,
    isQualifiedInvestor: pension.QualifiedInvestor,
    admTax: `${normalizePercentage(pension.AdmTax)}%`,
    initialApplication: pension.UniqueValue,
    rentabilityValueMonth: normalizePercentage(pension.RentabilityValueMonth),
    rentabilityValue12Months: normalizePercentage(pension.RentabilityValue12Months),
    assetFiles: pension.AssetFiles,
    suitabilityProfileDescription: pension.SuitabilityProfileDescription,
    suitability: pension.SuitabilityProfileId,
    risk: {
        color: pension.Risk ? pension.Risk.Color : '',
        description: pension.Risk ? pension.Risk.Description : '',
    },
});

export const normalizePrivatePension = pensions => pensions && pensions.map(normalizeBasicPension);

export const normalizePensionDetails = pension => {
    const basic = normalizeBasicPension(pension);

    return {
        ...basic,
        maxAdmTax: `${pension.MaxAdmTax}%`,
        performanceTax:
            typeof pension.PerformanceTax === 'number'
                ? `${pension.PerformanceTax}%`
                : pension.PerformanceTax,
        monthlyValue: pension.MonthlyValue,
        term: '',
    };
};

export const normalizeRelatives = relatives =>
    relatives &&
    relatives.map(relative => ({
        id: relative.Id,
        value: relative.Description,
    }));

export const normalizeFinancialInstitutionForSelect = institutions =>
    institutions &&
    institutions.map(institution => ({
        id: institution.Id,
        value: institution.Name,
    }));

export const normalizePaymentMethods = paymentMethods =>
    paymentMethods &&
    paymentMethods.map(paymentMethod => ({
        id: paymentMethod.Id,
        value: paymentMethod.Description,
    }));

export const normalizePrivatePensionHistory = history =>
    history &&
    history.map(elem => ({
        name: elem.AssetName,
        status: elem.StatusDescription,
        type: elem.Type,
        value: formatComplexValues(elem.Value, {}),
        date: elem.Date,
        dateNormalized: parseDateForDisplay(elem.Date),
    }));
