import styled, { css } from 'styled-components';
import { Popover } from '@terrainvest/react-components';

export const StyledHeaderMenuContainer = styled.div`
    ${() =>
        css`
            ${Popover.Styles.StyledPopover} {
                margin-top: 20px;
                right: 11px;
                left: auto;
                border-radius: 4px;
                transition: all 600ms;

                min-width: 300px;
                max-width: 320px;
            }
        `}
`;
