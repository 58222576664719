import { createActions } from './index.actions';
import { createReducer } from './index.reducer';
import { CommonPrefixes } from './index.constants';
import { ENDPOINTS } from '../../Utils/Api';
import {
    normalizeBanks,
    normalizeAdvisors,
    normalizeOptions,
    normalizeRepresentativeCategories,
    normalizeFatca,
    normalizePJActivities,
} from './index.normalizers';

export const createRedux = ({
    prefix: commonPrefix,
    endpoint,
    normalize,
}: {
    prefix: CommonPrefixes;
    endpoint: string;
    normalize?: (data: []) => [];
}) => {
    const prefix = commonPrefix.toUpperCase();

    const actions = createActions({ prefix, endpoint, normalize });
    const reducer = createReducer({ prefix, actions });

    return {
        prefix,
        actions,
        reducer,
    };
};

export const Commons = {
    StatusRegistrations: createRedux({
        prefix: CommonPrefixes.StatusRegistrations,
        endpoint: `${ENDPOINTS.GET_COMMON}/status-registrations`,
        normalize: normalizeOptions,
    }),
    Cities: createRedux({
        prefix: CommonPrefixes.Cities,
        endpoint: `${ENDPOINTS.GET_COMMON}/cities/:param`,
        normalize: normalizeOptions,
    }),
    Countries: createRedux({
        prefix: CommonPrefixes.Countries,
        endpoint: `${ENDPOINTS.GET_COMMON}/countries`,
        normalize: normalizeOptions,
    }),
    States: createRedux({
        prefix: CommonPrefixes.States,
        endpoint: `${ENDPOINTS.GET_COMMON}/states/:param`,
        normalize: normalizeOptions,
    }),
    Nationalities: createRedux({
        prefix: CommonPrefixes.Nationalities,
        endpoint: `${ENDPOINTS.GET_COMMON}/nationalities`,
        normalize: normalizeOptions,
    }),
    Occupations: createRedux({
        prefix: CommonPrefixes.Occupations,
        endpoint: `${ENDPOINTS.GET_COMMON}/occupations`,
        normalize: normalizeOptions,
    }),
    MaritalStatus: createRedux({
        prefix: CommonPrefixes.MaritalStatus,
        endpoint: `${ENDPOINTS.GET_COMMON}/marital-status`,
        normalize: normalizeOptions,
    }),
    DocumentTypes: createRedux({
        prefix: CommonPrefixes.DocumentTypes,
        endpoint: `${ENDPOINTS.GET_COMMON}/document-types`,
        normalize: normalizeOptions,
    }),
    DocumentIssuerAgencies: createRedux({
        prefix: CommonPrefixes.DocumentIssuerAgencies,
        endpoint: `${ENDPOINTS.GET_COMMON}/document-issuer-agencies`,
        normalize: normalizeOptions,
    }),
    Genders: createRedux({
        prefix: CommonPrefixes.Genders,
        endpoint: `${ENDPOINTS.GET_COMMON}/genders`,
        normalize: normalizeOptions,
    }),
    Receipt: createRedux({
        prefix: CommonPrefixes.Receipt,
        endpoint: `${ENDPOINTS.GET_COMMON}/receipt/:param`,
        normalize: normalizeOptions,
    }),
    Banks: createRedux({
        prefix: CommonPrefixes.Banks,
        endpoint: `${ENDPOINTS.GET_COMMON}/../bank`,
        normalize: normalizeBanks,
    }),
    Advisors: createRedux({
        prefix: CommonPrefixes.Advisors,
        endpoint: `${ENDPOINTS.GET_COMMON}/../advisor`,
        normalize: normalizeAdvisors,
    }),
    RepresentativeCategory: createRedux({
        prefix: CommonPrefixes.RepresentativeCategory,
        endpoint: `${ENDPOINTS.GET_COMMON}/../representative-category`,
        normalize: normalizeRepresentativeCategories,
    }),
    BusinessActivities: createRedux({
        prefix: CommonPrefixes.BusinessActivities,
        endpoint: `${ENDPOINTS.GET_COMMON}/../common/activities/J`,
        normalize: normalizePJActivities,
    }),
    Fatca: createRedux({
        prefix: CommonPrefixes.Fatca,
        endpoint: `${ENDPOINTS.GET_COMMON}/fatca`,
        normalize: normalizeFatca,
    }),
    combineReducers: () => {
        return Object.entries(Commons).reduce(
            (previous, entry: any[]) => ({
                ...previous,
                [entry[0]]: entry[1].reducer,
            }),
            {},
        );
    },
};
