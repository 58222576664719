import * as React from 'react';
import { useFormikContext } from 'formik';
import {
    Text,
    InputText,
    InputCpf,
    InputDate,
    FormGroup,
    Radio,
} from '@terrainvest/react-components';

import { StyledCol, StyledRow } from '../../../Common/GridStyles';

import {
    validateCpfValue,
    validateLength,
    validateRequiredField,
    isValidDate,
} from '../../../index.validators';

import { Field } from '../../../Form/Field';
import { fieldLengthLimits } from '../../../index.settings';
import AddressInput from '../../../AdressInput';

export const ProcuratorInputForCompany = ({
    id,
    preffix,
    showValidityDate,
    validityEnabled,
    validityDisabled,
}) => {
    const { setFieldValue } = useFormikContext();

    return (
        <StyledRow>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}ProcuratorName`} validate={validateRequiredField}>
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="Nome"
                            fullWidth
                            maxLength={fieldLengthLimits.name}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}ProcuratorCpfCnpj`} validate={validateCpfValue}>
                    {({ inputProps }) => <InputCpf {...inputProps} label="CPF" fullWidth />}
                </Field>
            </StyledCol>

            <AddressInput preffix={id} />

            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}Skype`} validate={value => validateLength('skype', value)}>
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="Skype"
                            fullWidth
                            maxLength={fieldLengthLimits.skype}
                        />
                    )}
                </Field>
            </StyledCol>

            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}PoliticallyExposedPerson`}>
                    {({ field }) => (
                        <FormGroup
                            name={field.name}
                            border
                            vertical={false}
                            fullWidth
                            onChange={evt => {
                                field.onChange(evt);
                            }}
                        >
                            <Text>É pessoa políticamente exposta?</Text>
                            <Radio
                                label="Sim"
                                defaultChecked={field.value === true}
                                // eslint-disable-next-line react/jsx-boolean-value
                                defaultValue={true}
                                onChange={() => {
                                    setFieldValue(
                                        `${preffix}PoliticallyExposedPerson` as never,
                                        true,
                                    );
                                }}
                            />
                            <Radio
                                label="Não"
                                defaultChecked={field.value === false}
                                defaultValue={false}
                                onChange={() => {
                                    setFieldValue(
                                        `${preffix}PoliticallyExposedPerson` as never,
                                        false,
                                    );
                                }}
                            />
                        </FormGroup>
                    )}
                </Field>
            </StyledCol>

            <StyledCol md={12} sm={12}>
                <Field name={`${preffix}ValidityIndeterminate`}>
                    {({ field }) => (
                        <FormGroup
                            name={field.name}
                            border
                            vertical={false}
                            fullWidth
                            onChange={evt => {
                                field.onChange(evt);
                            }}
                        >
                            <Text>Validade</Text>
                            <Radio
                                label="Indeterminada"
                                defaultChecked={!field.value || field.value === true}
                                // eslint-disable-next-line react/jsx-boolean-value
                                defaultValue={true}
                                onChange={validityEnabled}
                            />
                            <Radio
                                label="Até"
                                defaultChecked={field.value === false}
                                defaultValue={false}
                                onChange={validityDisabled}
                            />
                        </FormGroup>
                    )}
                </Field>
            </StyledCol>

            {showValidityDate && (
                <StyledCol md={6} sm={12}>
                    <Field
                        name={`${preffix}ValidityDate`}
                        validate={showValidityDate && isValidDate}
                    >
                        {({ inputProps }) => (
                            <InputDate {...inputProps} label="Validade" fullWidth />
                        )}
                    </Field>
                </StyledCol>
            )}
        </StyledRow>
    );
};

export default ProcuratorInputForCompany;
