import * as React from 'react';

import { InputText, InputDate } from '@terrainvest/react-components';

import { Field, SelectField } from '../../Form/Field';

import { CommonPrefixes } from '../../../../Redux/Common/index.constants';

import { StyledRow, StyledCol, StyledSelect } from '../../Details/index.styles';
import { StyledHelperImage } from '../index.styles';

import { fieldLengthLimits } from '../../index.settings';

import { validateRequiredField, validateDate, validateFields } from '../../index.validators';

import RGNumber from '../assets/nRG.png';
import RGValidateDate from '../assets/dtExpRG.png';

const RGForm = () => (
    <StyledRow>
        <StyledCol md={6} sm={12}>
            <Field name="DocumentNumber" validate={value => validateFields('rgNumber', value)}>
                {({ inputProps }) => (
                    <InputText
                        {...inputProps}
                        id="rg-number"
                        label="Número do RG"
                        fullWidth
                        helperContent={<StyledHelperImage src={RGNumber} />}
                        maxLength={fieldLengthLimits.rgNumber}
                    />
                )}
            </Field>
        </StyledCol>
        <StyledCol md={6} sm={12}>
            <SelectField
                name="DocumentIssuerName"
                commonPrefix={CommonPrefixes.DocumentIssuerAgencies}
                validate={validateRequiredField}
            >
                {({ field, form: { errors }, onChange, options, defaultValue }) => (
                    <StyledSelect
                        name={field.name}
                        label="Emissor"
                        fullWidth
                        suggest
                        suggestLimit={8}
                        error={!!errors[field.name]}
                        message={errors[field.name] || ''}
                        defaultValue={defaultValue}
                        options={options}
                        onChange={onChange}
                    />
                )}
            </SelectField>
        </StyledCol>
        <StyledCol md={6} sm={12}>
            <Field name="DocumentIssuerDate" validate={validateDate}>
                {({ inputProps }) => (
                    <InputDate
                        {...inputProps}
                        id="rg-validate-date"
                        label="Data de expedição"
                        fullWidth
                        icon={false}
                        helperContent={<StyledHelperImage src={RGValidateDate} />}
                    />
                )}
            </Field>
        </StyledCol>
        <StyledCol md={6} sm={12}>
            <SelectField
                name="DocumentIssuerState"
                commonPrefix={CommonPrefixes.States}
                validate={validateRequiredField}
            >
                {({ field, form: { errors }, onChange, options, defaultValue }) => (
                    <StyledSelect
                        name={field.name}
                        label="UF"
                        fullWidth
                        suggest
                        suggestLimit={8}
                        error={!!errors[field.name]}
                        message={errors[field.name] || ''}
                        defaultValue={defaultValue}
                        options={options}
                        onChange={onChange}
                    />
                )}
            </SelectField>
        </StyledCol>
    </StyledRow>
);

export default RGForm;
