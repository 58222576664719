import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext, useField } from 'formik';
import { debounce } from 'lodash';
import { Commons } from '../../../../Redux/Common';
import { DefaultLoading } from '..';
import { normalizeString, normalizeUpperWithSpace } from '../../../../Utils/Parsers';

export const Field = ({
    name,
    validate = null,
    children,
    loading = false,
    error = false,
    isArray = false,
    disabled = false,
    ...otherProps
}) => {
    const formik = useFormikContext();

    if (!formik.values || loading) {
        return <DefaultLoading />;
    }

    const [field, meta] = useField({ name, validate, ...otherProps });
    const setFieldValue = (val: any) => {
        formik.setFieldValue(name as never, val);
        formik.setFieldTouched(name as never, true);
        if (/^Document/.test(name)) {
            formik.setFieldTouched('Documents' as never, true);
        }
    };

    const debouncedSetFieldvalue = debounce(val => setFieldValue(val), 400);

    let memoizedChildren;
    const memoizedProps: any = {
        inputProps: {
            name,
            defaultValue: field.value,
            error: !!error || !!meta.error,
            message: error || meta.error ? meta.error : '',
            onChange: evt => debouncedSetFieldvalue(evt.target ? evt.target.value : evt),
            disabled,
        },
        field,
        meta,
        form: formik,
    };

    if (isArray) {
        // Array field must change value immediately o reflect changes since the key props doesn't change
        // and it prevents a new render, showing the old value instead of the new one

        memoizedProps.inputProps.value = field.value;
        memoizedProps.inputProps.onChange = evt =>
            setFieldValue(evt.target ? evt.target.value : evt);

        memoizedChildren = children(memoizedProps);
    } else {
        memoizedChildren = React.useMemo(() => children(memoizedProps), [
            field.value,
            meta.error,
            error,
            disabled,
        ]);
    }

    return memoizedChildren;
};

export const SelectField = ({ name, commonPrefix, children, loading = false, ...otherProps }) => {
    const formik = useFormikContext();
    const { data } = useSelector(state => state.commonState[commonPrefix]);

    if (!data || !data.length) {
        return <DefaultLoading />;
    }

    const options = data;
    const defaultOption = options.find(
        option =>
            String(option.id || '').trim() ===
            String(formik.getFieldProps({ name }).value || '').trim(),
    );

    const defaultValue = defaultOption ? defaultOption.value : '';

    const onChange = value => {
        const option = options.find(opt => opt.value === value);
        formik.setFieldValue(name as never, option ? option.id : '');
        formik.setFieldTouched(name as never, true);
        if (/^Document/.test(name)) {
            formik.setFieldTouched('Documents' as never, true);
        }
    };

    return (
        <Field name={name} loading={loading} {...otherProps}>
            {formikProps => children({ ...formikProps, defaultValue, onChange, options })}
        </Field>
    );
};

export const SelectCityField = ({ name, bindTo, children, loading = false, ...otherProps }) => {
    const formik = useFormikContext();
    const [stateId, setStateId] = React.useState();
    const [cities, setCities] = React.useState();

    // Get city options based on state id
    React.useEffect(() => {
        const id = formik.getFieldProps({ name: bindTo }).value;

        if (id && stateId !== id) {
            setStateId(id);
            setCities(null);
            Commons.Cities.actions.fetch(id).then(data => setCities(data));
        } else {
            setStateId(id);
        }
    }, [formik.getFieldProps({ name: bindTo }).value]);

    // Triggered by direct changes in field, like CEP field does
    React.useEffect(() => {
        const { value } = formik.getFieldProps({ name });
        if (value && typeof value === 'string') {
            const valueNormalized = normalizeUpperWithSpace(value);
            Commons.Cities.actions
                .fetch(formik.getFieldProps({ name: bindTo }).value)
                .then(citiesData => {
                    const city = citiesData.find(opt => opt.value === valueNormalized);
                    formik.setFieldValue(name as never, city ? city.id : '');
                    formik.setFieldTouched(name as never, true);
                });
        }
    }, [formik.getFieldProps({ name }).value]);

    if (!stateId || !cities || !cities.length) {
        return <DefaultLoading />;
    }

    const options = cities.map(option => ({ id: option.Value, value: option.Description }));
    const defaultOption = options.find(option => {
        const fieldValue = normalizeString(String(formik.getFieldProps({ name }).value || ''));

        return (
            fieldValue === normalizeString(String(option.id || '')) ||
            fieldValue === normalizeString(String(option.value || ''))
        );
    });

    const defaultValue = defaultOption ? defaultOption.value : (options && options[0].value) || '';
    const onChange = value => {
        const option = options.find(opt => opt.value === value);
        formik.setFieldValue(name as never, option ? option.id : '');
        formik.setFieldTouched(name as never, true);
    };

    return (
        <Field name={name} loading={loading} {...otherProps}>
            {formikProps => children({ ...formikProps, defaultValue, onChange, options })}
        </Field>
    );
};
