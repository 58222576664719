import * as React from 'react';
import { Icon } from '@terrainvest/react-components';
import { StyledHeaderProfileMenuItem } from './index.styles';

interface HeaderProfileMenuItemProps {
    number?: string;
    name?: string;
    selected?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export const HeaderProfileMenuItem: React.FC<HeaderProfileMenuItemProps> = ({
    number,
    name,
    selected,
    ...otherProps
}: HeaderProfileMenuItemProps) => {
    return (
        <StyledHeaderProfileMenuItem selected={selected} {...otherProps}>
            <Icon icon={['fas', 'user-circle']} size="lg" />
            <span id="number">{number}</span>
            {name ? (
                <>
                    <span> - </span>
                    <span id="name">{name}</span>
                </>
            ) : null}
        </StyledHeaderProfileMenuItem>
    );
};

HeaderProfileMenuItem.defaultProps = {
    number: '',
    name: '',
    selected: false,
    onClick: () => {},
};

export default HeaderProfileMenuItem;
