import { Button, Icon, Notification } from '@terrainvest/react-components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { isCadastralUpdate } from '../../../Utils/General';
import * as Components from './index.styles';

export const DueDateNotification: React.FC<{}> = props => {
    const { client } = useSelector(state => state.clientState) || {};
    const history = useHistory();
    const url = useLocation();
    const condition = client && url.pathname.includes('/dados-cadastrais');
    const inExpiredDataURL = url.pathname === '/cadastro-expirado';

    /* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
    const message = `É necessário fazer sua Atualização Cadastral para validação das suas informações, evite que a sua conta seja bloqueada.`;

    return (
        <Components.StyledDueDateNotification {...props}>
            <Notification
                type="warn"
                visible={isCadastralUpdate(client) && !inExpiredDataURL}
                fixed
                icon={condition}
                timeout={condition ? 3000 : 0}
            >
                <Components.StyledWarnIcon>
                    <Icon icon="exclamation-circle" />
                </Components.StyledWarnIcon>
                <Components.StyledMessage>{message}</Components.StyledMessage>

                <Button
                    onClick={() => history.push('/app/dados-cadastrais')}
                    secondaryOverBackground
                    type="button"
                >
                    FAZER ATUALIZAÇÃO
                </Button>
            </Notification>
        </Components.StyledDueDateNotification>
    );
};
