import jwtDecode from 'jwt-decode';
import { Actions, OFFER_MANAGER_ERROR } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';

interface Credentials {
    username: string;
    password: string;
    partner_id: string;
}

export const fetchAuthInit = (
    credentials: Credentials,
    callback: (error?) => void = () => null,
) => ({
    type: Actions.AUTH_FETCH_INIT,
    credentials,
    callback,
});

export const fetchAuthSuccess = auth => ({
    type: Actions.AUTH_FETCH_SUCCESS,
    auth,
});

export const fetchAuthMappedError = mappedError => ({
    type: Actions.AUTH_MAPPED_ERROR,
    mappedError,
});

export const fetchAuthOfferManagerError = () => ({
    type: Actions.AUTH_OFFER_MANAGER_ERROR,
});

export const fetchAuthError = error => ({
    type: Actions.AUTH_FETCH_ERROR,
    error: error.message,
});

export const setAuth = auth => ({
    type: Actions.SET_AUTH,
    auth,
});

export const logout = () => ({
    type: Actions.AUTH_LOGOUT,
});

export const resetAuthData = () => ({
    type: Actions.AUTH_LOGOUT,
});

export const fetchAuth = async (
    credentials: Credentials,
    callback: (error?) => void = () => null,
    dispatch,
) => {
    const params = {
        grant_type: 'password',
        ...credentials,
    };

    try {
        const response = await fetch(ENDPOINTS.POST_AUTH, {
            method: 'POST',
            body: Object.keys(params)
                .map(key => {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
                })
                .join('&'),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        const data = await response.json();

        const jwtData = jwtDecode(data.access_token);

        callback();

        return {
            ...data,
            ...jwtData,
        };
    } catch (e) {
        if (e.responseJson) {
            if (e.responseJson.Message === OFFER_MANAGER_ERROR) {
                dispatch(fetchAuthOfferManagerError());
            }
            dispatch(fetchAuthMappedError(e.responseJson));
        }

        callback(e);
        throw e;
    }
};

export const fetchNewPassword = username =>
    fetch(ENDPOINTS.PUT_NEW_PASSWORD, {
        method: 'PUT',
        body: JSON.stringify({ username }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchValidateToken = (data: { username: string; token: string }) =>
    fetch(ENDPOINTS.PUT_VALIDATE_TOKEN, {
        method: 'POST',
        body: JSON.stringify({ ...data }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchResetPassword = (data: {
    username: string;
    token: string;
    newPassword: string;
}) =>
    fetch(ENDPOINTS.PUT_RESET_PASSWORD, {
        method: 'PUT',
        body: JSON.stringify({ ...data }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchChangePassword = (data: { currentPassword: string; newPassword: string }) =>
    fetch(ENDPOINTS.POST_CHANGE_PASSWORD, {
        method: 'PUT',
        body: JSON.stringify({ ...data }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchChangeElectronicCheck = (data: {
    oldElectronicCheck: string;
    newElectronicCheck: string;
}) =>
    fetch(ENDPOINTS.PUT_CHANGE_ELECTRONIC_CHECK, {
        method: 'PUT',
        body: JSON.stringify({ ...data }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchForgotElectronicCheck = userId =>
    fetch(ENDPOINTS.POST_FORGOT_ELECTRONIC_CHECK, {
        method: 'POST',
        body: JSON.stringify({
            UserId: userId,
        }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchUploadToken = userId => {
    const url = ENDPOINTS.GET_UPLOAD_TOKEN.replace(':userId', userId);
    return fetch(url, { method: 'GET' }).then(response =>
        response ? response.text() : Promise.reject(),
    );
};
