import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Text } from '@terrainvest/react-components';

import { appMsgTypes, postAppMsg } from '../../../../Utils/General';

import { StyledButton } from './index.styles';

const ENTER_KEY_CODE = 13;

export const MobileVisualizeDocPrompt = ({ url, config, isBlob }) => {
    React.useEffect(() => {
        handleClick();
    }, []);

    const handleClick = () => {
        return isBlob
            ? postAppMsg(appMsgTypes.FILE, { url, ...config })
            : postAppMsg(appMsgTypes.DIRECT_LINK, { url });
    };

    const handleKeyUp = evt => {
        if ((evt.charCode || evt.keyCode) === ENTER_KEY_CODE) {
            handleClick();
        }
    };

    return (
        <>
            <StyledButton primary outline fullWidth onClick={handleClick} onKeyUp={handleKeyUp}>
                VISUALIZAR TERMO DE ACORDO
            </StyledButton>
            <Text>Um arquivo PDF será baixado e exibido em seu dispositivo</Text>
        </>
    );
};

MobileVisualizeDocPrompt.propTypes = {
    url: PropTypes.string.isRequired,
    config: PropTypes.shape({
        method: PropTypes.string,
        payload: PropTypes.shape({}),
    }),
    isBlob: PropTypes.bool,
};

MobileVisualizeDocPrompt.defaultProps = {
    config: { method: 'GET', payload: null },
    isBlob: false,
};

export default MobileVisualizeDocPrompt;
