import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchPrivatePension,
    fetchPrivatePensionSuccess,
    fetchPrivatePensionError,
    fetchPrivatePensionHistory,
    fetchPrivatePensionHistorySuccess,
    fetchPrivatePensionHistoryError,
} from './index.actions';

export const initialState = {
    pensions: [],
    loading: false,
    error: false,
    history: [],
    loadingHistory: false,
    errorHistory: false,
};

export const privatePensionReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.PRIVATE_PENSION_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchPrivatePension, {
                    successActionCreator: fetchPrivatePensionSuccess,
                    failActionCreator: fetchPrivatePensionError,
                }),
            );
        case Actions.PRIVATE_PENSION_FETCH_SUCCESS:
            return {
                ...state,
                pensions: action.pensions,
                loading: false,
                error: false,
            };
        case Actions.PRIVATE_PENSION_FETCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case Actions.PRIVATE_PENSION_HISTORY_FETCH_INIT:
            return loop(
                { ...state, loadingHistory: true },
                Cmd.run(fetchPrivatePensionHistory, {
                    successActionCreator: fetchPrivatePensionHistorySuccess,
                    failActionCreator: fetchPrivatePensionHistoryError,
                    args: [action.account],
                }),
            );
        case Actions.PRIVATE_PENSION_HISTORY_FETCH_SUCCESS:
            return {
                ...state,
                history: action.history,
                loadingHistory: false,
                errorHistory: false,
            };
        case Actions.PRIVATE_PENSION_HISTORY_FETCH_ERROR:
            return {
                ...state,
                errorHistory: true,
                loadingHistory: false,
            };
        default:
            return state;
    }
};
