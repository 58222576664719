import * as React from 'react';
import { useFormikContext } from 'formik';
import { H2, Icon, Text } from '@terrainvest/react-components';

import { Separator } from '../../../../Utils/General/index.separator';
import { StyledHeaderWrapper, StyledRemove } from './index.styles';
import ProcuratorInputForCompany from './ProcuratorInputForCompany';
import ProcuratorInputForPerson from './ProcuratorInputForPerson';

export const ProcuratorInput = ({ index, preffix: id, onRemove, isCompany }) => {
    const { setFieldValue } = useFormikContext();
    const [showValidityDate, setShowValidityDate] = React.useState<boolean>();

    const preffix = id ? `${id}.` : '';

    const handleRemove = () => {
        onRemove(index);
    };

    const handleValidityIndeterminateChangedEnabled = () => {
        setFieldValue(`${preffix}ValidityIndeterminate` as never, true);
        setShowValidityDate(false);
    };

    const handleValidityIndeterminateChangedDisabled = () => {
        setFieldValue(`${preffix}ValidityIndeterminate` as never, false);
        setShowValidityDate(true);
    };

    return (
        <>
            <StyledHeaderWrapper>
                <H2>{`Procurador ${index + 1}`}</H2>
                <StyledRemove onClick={handleRemove}>
                    <Text weight="medium">
                        Remover procurador <Icon icon="times" size="sm" padLeft />
                    </Text>
                </StyledRemove>
            </StyledHeaderWrapper>
            <Separator />
            {isCompany ? (
                <ProcuratorInputForCompany
                    id={id}
                    preffix={preffix}
                    showValidityDate={showValidityDate}
                    validityEnabled={handleValidityIndeterminateChangedEnabled}
                    validityDisabled={handleValidityIndeterminateChangedDisabled}
                />
            ) : (
                <ProcuratorInputForPerson
                    id={id}
                    preffix={preffix}
                    showValidityDate={showValidityDate}
                    validityEnabled={handleValidityIndeterminateChangedEnabled}
                    validityDisabled={handleValidityIndeterminateChangedDisabled}
                />
            )}
        </>
    );
};

export default ProcuratorInput;
