import { Loading } from '@terrainvest/react-components';
import { FormikValues, withFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchClientInit, updateClient, updateClientNoDataChanged } from '../../../Redux/Client';
import { postFiles } from '../../../Redux/Common/index.actions';
import {
    notifyErrorMsg,
    notifySuccessMsg,
    postNotificationFromError,
} from '../../../Redux/Notification';
import { fetchRLPStatusInit, patchRLPStatusByProp } from '../../../Redux/RLP';
import { postClientAgreement } from '../../../Redux/Compliance';
import {
    fetchSuitabilityProfileByAccountNumberInit,
    fetchSuitabilityProfileInit,
    fillSuitabilityModal,
    setLoadingPostSuitability,
    updateSuitabilityProfile,
} from '../../../Redux/Suitability';
import RLPInfoModal from '../../RLPInfoModal';
import { Anchors } from '../Anchors';
import { deNormalize, deNormalizeNoDataChanged, normalize } from '../index.normalizers';
import { Footer } from './Footer';
import {
    StyledForm,
    StyledLink,
    StyledMsgContainer,
    StyledParagraph,
    StyledSkeleton,
} from './index.styles';

export const DefaultLoading = () => <StyledSkeleton height={48} width={900} duration={2} />;

export const FormContext = React.createContext(null);

const ENTER_KEY_CODE = 13;

export const Form = ({
    values,
    validateForm,
    getFieldMeta,
    onlySuitability = false,
    userHasExpiredData = false,
    suitabilityExpiredData = false,
    focusedAnchor = '',
    children = null,
    onClose = null,
    onSubmit = null,
    redirectOnSuccess = true,
}) => {
    let message = null;

    const formRef = React.useRef();
    const [loading, setLoading] = React.useState(false);
    const [showRLPInfoModal, setShowRLPInfoModal] = React.useState(false);
    const [error, setError] = React.useState('');
    const [isUploadVisible, setIsUploadVisible] = React.useState(false);
    const [electronickCheck, setElectronicCheck] = React.useState();
    const [checkedNoDataChanged, setCheckedNoDataChanged] = React.useState(true);

    const history = useHistory();
    const dispatch = useDispatch();
    const isCompany = values && values.isCompany;
    const isQualifiedInvestor = values && values.QualifiedInvestor === true;
    const isProfessionalInvestor = values && values.ProfessionalInvestor === true;

    const { isEmptyProfile } = useSelector((state: any) => state.suitabilityProfileState);
    const { isRlpActive, loading: loadingRLP, error: errorRLPState } = useSelector(
        (state: any) => state.rlpState,
    );

    const { loading: loadingClient, selectedAccount, client } = useSelector(
        (state: any) => state.clientState,
    );
    const [redirectOnFinishedLoading, setRedirectOnFinishedLoadingClient] = React.useState(false);

    React.useEffect(() => {
        if (client && !loadingRLP && isRlpActive === null) {
            dispatch(fetchRLPStatusInit(client.ClientId));
        }
    }, [client]);

    React.useEffect(() => {
        handleRedirectWhenFinishedLoadingNewClient();
    }, [loadingClient]);

    React.useEffect(() => {
        if (!loading && error) {
            dispatch(notifyErrorMsg(error));
        }
    }, [loading, error]);

    /**
     * Atrasa o redirecionamento do usuário para após o carregamento dos dados em ClientState.
     */
    const handleRedirectWhenFinishedLoadingNewClient = () => {
        if (!redirectOnFinishedLoading || loadingClient) {
            return;
        }

        history.push('/app');
    };

    const questionsParser = Questions =>
        Questions.reduce((accumulator, currentValue) => {
            if (currentValue.Type !== 2) {
                if (currentValue.Answers[0]) {
                    return accumulator.concat([
                        {
                            Id: currentValue.Id,
                            Answers:
                                currentValue.Type === 1
                                    ? [...new Set(currentValue.Answers.map(answer => answer.Id))]
                                    : [currentValue.Answers[0].Id],
                        },
                    ]);
                }

                return accumulator;
            }
            return accumulator.concat(questionsParser(currentValue.Questions));
        }, []);

    const sendOnlySuitability = (): Promise<any> => getSuitabilityPromise();

    const updateSuitability = (): Promise<any> => getSuitabilityPromise();

    const getSuitabilityPromise = (): Promise<any> => {
        const payload = {
            Id: values.SuitabilityProfile.Id,
            UserId: values.UserId,
            ClientId: values.SuitabilityProfile.ClientId,
            Questions: questionsParser(values.SuitabilityProfile.Questions),
            ElectronicCheck: electronickCheck,
        };

        return updateSuitabilityProfile(payload);
    };

    const uploadFiles = (): Promise<any> => {
        if (values.files) {
            return Promise.all(Object.values(values.files).map(file => postFiles(file)));
        }

        return Promise.resolve();
    };

    const QualifiedInvestor = () => {
        postClientAgreement({
            AgreementId: 33,
            EletronicCheck: electronickCheck,
            ClientId: client.ClientId,
            AccountNumber: client.Accounts[0],
            UserId: client.UserId,
        });
    };

    const ProfessionalInvestor = () => {
        postClientAgreement({
            AgreementId: 34,
            EletronicCheck: electronickCheck,
            ClientId: client.ClientId,
            AccountNumber: client.Accounts[0],
            UserId: client.UserId,
        }).then(res => {
            return res ? QualifiedInvestor() : Promise.reject();
        });
    };

    const handleSubmit = async () => {
        const errors = await validateForm();
        const hasErrors = Object.keys(errors).length;

        if (hasErrors) {
            handleSubmitErrors();
            return;
        }

        if (onlySuitability) {
            handleSubmitOnlySuitability();
            return;
        }

        if (isQualifiedInvestor && !isProfessionalInvestor) {
            QualifiedInvestor();
        } else if (isQualifiedInvestor && isProfessionalInvestor) {
            ProfessionalInvestor();
        }

        if (!loadingRLP && !isRlpActive && !errorRLPState && !values.StatusFatcaId) {
            if (values.RLPOffers) {
                handleSubmitMainPath({ rlp: true });
            } else {
                setShowRLPInfoModal(true);
            }
        } else {
            message =
                'Sucesso! As suas informações estão sendo analisadas e em breve o avisaremos.';
            handleSubmitMainPath();
        }
    };

    const handleSubmitErrors = () => {
        const elements = formRef.current ? (formRef.current as any).elements : [];
        const element = [...elements].find(el => el.name && getFieldMeta(el.name).error);
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top: Math.abs(
                    element.getBoundingClientRect().top -
                        (formRef.current as any).getBoundingClientRect().top,
                ),
            });
        }
    };

    const handleSubmitOnlySuitability = () => {
        setLoading(true);
        dispatch(setLoadingPostSuitability(true));
        sendOnlySuitability()
            .then(res => {
                if (res.ok) {
                    dispatch(notifySuccessMsg('Sucesso!'));
                    dispatch(fetchClientInit(false));
                    dispatch(fetchSuitabilityProfileByAccountNumberInit(selectedAccount));
                    dispatch(fetchSuitabilityProfileInit(client.ClientId));

                    if (onSubmit) {
                        onSubmit();
                    } else {
                        history.push('/app');
                    }
                } else {
                    throw new Error('Submit error');
                }
            })
            .catch(() => setError('Desculpe, não foi possível alterar seu perfil de investidor'))
            .finally(() => {
                setLoading(false);
                dispatch(setLoadingPostSuitability(false));
            });
    };

    const handleSubmitMainPath = (params = { rlp: false }) => {
        const { rlp } = params;
        setError('');
        setLoading(true);
        setRedirectOnFinishedLoadingClient(false);
        dispatch(setLoadingPostSuitability(true));

        const promises = [...getSubmitMainPathPromises()];
        if (isCompany && values.StatusFatcaId) {
            promises.push(getRLPUpdatePromise(true, false));
        } else {
            promises.push(getRLPUpdatePromise(rlp));
        }

        Promise.all(promises)
            .then(() => {
                dispatch(notifySuccessMsg(message));

                dispatch(fetchClientInit(true));
                dispatch(fetchSuitabilityProfileByAccountNumberInit(selectedAccount));

                setRedirectOnFinishedLoadingClient(true);

                if (isEmptyProfile) {
                    dispatch(fillSuitabilityModal());
                }

                if (redirectOnSuccess) {
                    history.push('/app');
                }
            })
            .finally(() => {
                setLoading(false);
                dispatch(setLoadingPostSuitability(false));
            });
    };

    const updateClientWithValidation = () => {
        return checkedNoDataChanged
            ? updateClientNoDataChanged(
                  deNormalizeNoDataChanged(values, checkedNoDataChanged, electronickCheck),
              ).catch(err => {
                  dispatch(
                      postNotificationFromError(
                          err,
                          {},
                          'Desculpe, não foi possível alterar os dados cadastrais.',
                      ),
                  );
                  return Promise.reject(err);
              })
            : updateClient(deNormalize(values, electronickCheck)).catch(err => {
                  dispatch(
                      postNotificationFromError(
                          err,
                          {},
                          'Desculpe, não foi possível alterar os dados cadastrais.',
                      ),
                  );
                  return Promise.reject(err);
              });
    };

    const getSubmitMainPathPromises = () => {
        return [
            updateClientWithValidation(),
            uploadFiles().catch(err => {
                dispatch(
                    postNotificationFromError(
                        err,
                        {},
                        'Desculpe, não foi possível fazer o upload dos arquivos.',
                    ),
                );
                return Promise.reject(err);
            }),
            updateSuitability().catch(err => {
                dispatch(
                    postNotificationFromError(
                        err,
                        {},
                        'Desculpe, não foi possível alterar o perfil de investidor.',
                    ),
                );
                return Promise.reject(err);
            }),
        ];
    };

    const getRLPUpdatePromise = (activateRlp, status = true) => {
        return activateRlp
            ? patchRLPStatusByProp(client.ClientId, status)
                  .then(res => {
                      if (res.ok) {
                          dispatch(fetchRLPStatusInit(client.ClientId));
                      }
                  })
                  .catch(err => {
                      setError('Desculpe, não foi possível ativar o RLP.');
                      return Promise.reject(err);
                  })
            : Promise.resolve();
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        } else {
            history.push('/app');
        }
    };

    const renderAnchors = () => {
        const showAnchors = !onlySuitability && !userHasExpiredData && !suitabilityExpiredData;

        return (
            <Anchors
                showAnchors={showAnchors}
                isCompany={isCompany}
                focusedAnchor={focusedAnchor}
            />
        );
    };

    return (
        <FormContext.Provider
            value={{
                isUploadVisible,
                onlySuitability,
                electronickCheck,
                userHasExpiredData,
                setElectronicCheck,
                setIsUploadVisible,
                onClose: handleClose,
                onSubmit: handleSubmit,
            }}
        >
            <Loading fixed visible={loading} />

            {renderAnchors()}

            <StyledForm
                fullWidth={userHasExpiredData || suitabilityExpiredData}
                ref={formRef}
                onSubmit={evt => evt.preventDefault()}
                onKeyDown={evt => {
                    if ((evt.charCode || evt.keyCode) === ENTER_KEY_CODE) {
                        evt.preventDefault();
                    }
                }}
            >
                {children}
                <Footer
                    userHasExpiredData={userHasExpiredData || suitabilityExpiredData}
                    setLoading={setLoading}
                    checkedNoDataChanged={checkedNoDataChanged}
                    setCheckedNoDataChanged={setCheckedNoDataChanged}
                />
            </StyledForm>

            <RLPInfoModal
                visible={showRLPInfoModal}
                handleClose={() => {
                    setShowRLPInfoModal(false);
                    handleSubmitMainPath();
                    message = (
                        <StyledMsgContainer>
                            <StyledParagraph>
                                Sucesso! As suas informações estão sendo analisadas e em breve o
                                avisaremos.
                            </StyledParagraph>
                            <StyledParagraph>
                                O produto RLP não foi ativado! Caso queira ativar em outro momento
                                basta acessar o menu RLP.
                            </StyledParagraph>
                        </StyledMsgContainer>
                    );
                }}
                afterSubmit={() => {
                    setShowRLPInfoModal(false);
                    handleSubmitMainPath({ rlp: true });
                    message = (
                        <StyledMsgContainer>
                            <StyledParagraph>
                                Sucesso! As suas informações estão sendo analisadas e em breve o
                                avisaremos.
                            </StyledParagraph>
                            <StyledParagraph>
                                O produto RLP foi ativado com sucesso! Para mais informações{' '}
                                <StyledLink
                                    onClick={() => {
                                        history.push('/app/rlp');
                                    }}
                                >
                                    clique aqui
                                </StyledLink>
                            </StyledParagraph>
                        </StyledMsgContainer>
                    );
                }}
            />
        </FormContext.Provider>
    );
};

interface FormWithFormikProps {
    initialValues: FormikValues;
    onlySuitability?: boolean;
    userHasExpiredData?: boolean;
    suitabilityExpiredData?: boolean;
    focusedAnchor?: string;
    onClose?: () => void;
    onSubmit?: () => void;
    redirectOnSuccess?: boolean;
}

export const FormWithFormik = withFormik<FormWithFormikProps, any, any>({
    validate: null,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    mapPropsToValues: ({ initialValues }) => normalize(initialValues),
    handleSubmit: null,
})(Form);

export * from './index.constants';
