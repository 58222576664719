import * as React from 'react';
import { Loading } from '@terrainvest/react-components';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';

import { patchRLPStatus, fetchRLPStatusInit } from '../../../../Redux/RLP';
import { notifySuccessMsg, notifyErrorMsg } from '../../../../Redux/Notification';

import * as bannerImage from './assets/banner-image.png';
import { texts, eBookLink } from './index.constants';
import * as S from './index.styles';

export const RLPOptin = ({ visible, onclose }) => {
    const { client } = useSelector((state: RootState) => state.clientState);

    const [loading, setLoading] = React.useState<boolean>(false);

    const dispatch = useDispatch();

    const handleActiveRlp = () => {
        const payload = {
            clientId: client.ClientId,
            rlpNewStatus: true,
            electronicCheck: null,
        };
        setLoading(true);
        patchRLPStatus(payload)
            .then(() => {
                dispatch(fetchRLPStatusInit(client.ClientId));
                dispatch(notifySuccessMsg(texts.RLP_ACTIVATION_SUCCESS));
            })
            .then(() => {
                setLoading(false);
                onclose();
            })
            .catch(() => {
                dispatch(notifyErrorMsg(texts.RLP_ACTIVATION_FAILURE));
            });
    };

    return (
        <>
            <S.StyledModal visible={visible} onClose={onclose} width={640}>
                <Loading visible={loading} />
                <S.StyledWrapper>
                    <S.StyledTextsWrapper>
                        <S.StyledH3>{texts.BANNER_FIRST_TITLE}</S.StyledH3>
                        <S.StyledH2>
                            {texts.BANNER_SECOND_TITLE.start}
                            <b>{texts.BANNER_SECOND_TITLE.bold}</b>
                            {texts.BANNER_SECOND_TITLE.end}
                        </S.StyledH2>
                        <S.StyledText>
                            {texts.BANNER_LABEL.start}
                            <b>{texts.BANNER_LABEL.bold}</b>
                            {texts.BANNER_LABEL.end}
                            <S.StyledLink href={eBookLink} target="blank">
                                {texts.BANNER_LINK_LABEL}
                            </S.StyledLink>
                        </S.StyledText>
                        <S.StyledButtonWrapper>
                            <S.StyledPrimaryButton onClick={handleActiveRlp}>
                                {texts.BANNER_PRIMARY_BUTTON_LABEL}
                            </S.StyledPrimaryButton>
                            <S.StyledSecondaryButton onClick={onclose}>
                                {texts.BANNER_SECONDARY_BUTTON_LABEL}
                            </S.StyledSecondaryButton>
                        </S.StyledButtonWrapper>
                    </S.StyledTextsWrapper>
                    <S.StyledImageWrapper>
                        <S.StyledImage src={bannerImage} />
                    </S.StyledImageWrapper>
                </S.StyledWrapper>
            </S.StyledModal>
        </>
    );
};

export default RLPOptin;
