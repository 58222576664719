import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../../Utils/Api';
import { normalizeBrokerage } from './index.normalize';

export const fetchBrokerageNoteDatesInit = data => ({
    type: Actions.FETCH_BROKERAGENOTE_DATES_INIT,
    data,
});

export const fetchBrokerageNoteDates = data => {
    return fetch(
        ENDPOINTS.GET_BROKERAGENOTE_DATES.replace(':accountNumber', data.account).replace(
            ':market',
            data.marketValue,
        ),
        {
            method: 'GET',
        },
    )
        .then(response => response.json())
        .then(normalizeBrokerage);
};

export const fetchBrokerageNoteDatesSuccess = dates => ({
    type: Actions.FETCH_BROKERAGENOTE_DATES_SUCCESS,
    dates,
});

export const fetchBrokerageNoteDatesError = error => ({
    type: Actions.FETCH_BROKERAGENOTE_DATES_ERROR,
    error: error.message,
});

export const fetchBrokerageNoteDatesReset = () => ({
    type: Actions.FETCH_BROKERAGENOTE_DATES_RESET,
});
