import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    postWithdraw,
    postWithdrawSuccess,
    postWithdrawError,
    fetchWithdrawHistorySuccess,
    fetchWithdrawHistoryError,
    fetchWithdrawHistory,
    fetchWithdrawValuesSuccess,
    fetchWithdrawValuesError,
    fetchWithdrawValues,
} from './index.actions';
import { Actions as AuthActions } from '../Auth/index.constants';
import { WithdrawState } from './index.types';

export const initialState: WithdrawState = {
    withdraw: null,
    loading: false,
    error: null,
    loadingHistory: false,
    errorHistory: false,
    history: null,
    valuesLoading: false,
    valuesError: null,
    values: {},
};

export const withdrawReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.WITHDRAW_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(postWithdraw, {
                    successActionCreator: postWithdrawSuccess,
                    failActionCreator: postWithdrawError,
                    args: [action.data],
                }),
            );
        case Actions.WITHDRAW_FETCH_SUCCESS:
            return { ...state, withdraw: action.withdraw, loading: false, error: false };
        case Actions.WITHDRAW_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        case Actions.WITHDRAW_HISTORY_FETCH_INIT:
            return loop(
                { ...state, loadingHistory: true },
                Cmd.run(fetchWithdrawHistory, {
                    successActionCreator: fetchWithdrawHistorySuccess,
                    failActionCreator: fetchWithdrawHistoryError,
                    args: [action.data],
                }),
            );
        case Actions.WITHDRAW_HISTORY_FETCH_SUCCESS:
            return {
                ...state,
                history: action.history,
                loadingHistory: false,
                errorHistory: false,
            };
        case Actions.WITHDRAW_HISTORY_FETCH_ERROR:
            return { ...state, errorHistory: action.error, loadingHistory: false };
        case Actions.WITHDRAW_VALUES_FETCH_INIT:
            return loop(
                { ...state, valuesLoading: true, valuesError: null },
                Cmd.run(fetchWithdrawValues, {
                    successActionCreator: fetchWithdrawValuesSuccess,
                    failActionCreator: fetchWithdrawValuesError,
                    args: [action.accountNumber],
                }),
            );
        case Actions.WITHDRAW_VALUES_FETCH_SUCCESS:
            return {
                ...state,
                values: action.withdrawValue,
                valuesLoading: false,
                valuesError: null,
            };
        case Actions.WITHDRAW_VALUES_FETCH_ERROR:
            return { ...state, valuesLoading: false, valuesError: action.error };
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
