import * as React from 'react';
import { H2, Radio, Text, InputCpf, InputText, InputInteger } from '@terrainvest/react-components';
import { StyledBankAccount, StyledFormGroup } from './index.styles';
import { StyledRow, StyledCol, StyledSelect } from '../../Details/index.styles';
import { validateRequiredField, validateCpfValue, validateFields } from '../../index.validators';
import { StyledContainerNoPadd } from '../../../Common/Grid';
import { CommonPrefixes } from '../../../../Redux/Common/index.constants';
import { Field, SelectField } from '../../Form/Field';
import { fieldLengthLimits } from '../../index.settings';

enum AccountType {
    INDIVIDUAL = 0,
    JOINT = 1,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BankAccount = ({ bankAccount, index, onClose }) => (
    <StyledBankAccount>
        <H2>{`Conta ${index + 1}`}</H2>
        {/* {onClose && (
            <StyledDelete onClick={onClose}>
                <Text weight="medium">
                    Remover conta <Icon icon="times" size="sm" padLeft />
                </Text>
            </StyledDelete>
        )} */}

        <StyledContainerNoPadd fluid>
            <StyledRow>
                <StyledCol col={12}>
                    <Field name={`BankAccounts[${index}].AccountType`} isArray>
                        {({ field, form }) => (
                            <StyledFormGroup
                                {...field}
                                vertical={false}
                                fullWidth
                                border
                                disabled
                                onChange={evt => {
                                    form.setFieldValue(field.name, Number(evt.target.value));
                                }}
                            >
                                <Text>Tipo de conta:</Text>
                                <Radio
                                    label="Individual"
                                    defaultChecked={field.value === AccountType.INDIVIDUAL}
                                    defaultValue={AccountType.INDIVIDUAL}
                                    disabled
                                />
                                <Radio
                                    label="Conjunta"
                                    defaultChecked={field.value === AccountType.JOINT}
                                    defaultValue={AccountType.JOINT}
                                    disabled
                                />
                            </StyledFormGroup>
                        )}
                    </Field>
                </StyledCol>
                <StyledCol xl={4} md={8} sm={12}>
                    <SelectField
                        name={`BankAccounts[${index}].BankNumber`}
                        commonPrefix={CommonPrefixes.Banks}
                        validate={validateRequiredField}
                    >
                        {({ field, meta, onChange, options, defaultValue }) => (
                            <StyledSelect
                                name={field.name}
                                label="Banco"
                                fullWidth
                                suggest
                                suggestLimit={8}
                                error={!!meta.error}
                                message={meta.error || ''}
                                defaultValue={defaultValue}
                                value={defaultValue}
                                options={options}
                                onChange={onChange}
                                disabled
                            />
                        )}
                    </SelectField>
                </StyledCol>
                <StyledCol xl={2} md={4} sm={12}>
                    <Field
                        name={`BankAccounts[${index}].AgencyNumber`}
                        validate={value => validateFields('bankBranch', value)}
                        isArray
                    >
                        {({ inputProps }) => (
                            <InputInteger
                                {...inputProps}
                                label="Agência"
                                fullWidth
                                maxLength={fieldLengthLimits.bankBranch}
                                disabled
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol xl={3} md={6} sm={12}>
                    <Field
                        name={`BankAccounts[${index}].AccountNumber`}
                        validate={value => validateFields('bankAccount', value)}
                        isArray
                    >
                        {({ inputProps }) => (
                            <InputInteger
                                {...inputProps}
                                label="Conta (sem dígito)"
                                fullWidth
                                maxLength={fieldLengthLimits.bankAccount}
                                disabled
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol xl={3} md={6} sm={12}>
                    <Field
                        name={`BankAccounts[${index}].AccountDigit`}
                        validate={value => validateFields('bankDigit', value)}
                        isArray
                    >
                        {({ inputProps }) => (
                            <InputInteger
                                {...inputProps}
                                label="Dígito da conta"
                                fullWidth
                                maxLength={fieldLengthLimits.bankDigit}
                                disabled
                            />
                        )}
                    </Field>
                </StyledCol>
                {bankAccount.AccountType === AccountType.JOINT && (
                    <>
                        <StyledCol col={6}>
                            <Field
                                name={`BankAccounts[${index}].CpfCoOwner`}
                                validate={validateCpfValue}
                                isArray
                                disabled
                            >
                                {({ inputProps }) => (
                                    <InputCpf {...inputProps} label="CPF Co-Titular" fullWidth />
                                )}
                            </Field>
                        </StyledCol>
                        <StyledCol col={6}>
                            <Field
                                name={`BankAccounts[${index}].NameCoOwner`}
                                validate={value => validateFields('nameCoHolder', value)}
                                isArray
                                disabled
                            >
                                {({ inputProps }) => (
                                    <InputText
                                        {...inputProps}
                                        label="Nome Co-Titular"
                                        fullWidth
                                        maxLength={fieldLengthLimits.nameCoHolder}
                                    />
                                )}
                            </Field>
                        </StyledCol>
                    </>
                )}
            </StyledRow>
        </StyledContainerNoPadd>
    </StyledBankAccount>
);

export default BankAccount;
