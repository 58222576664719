import * as React from 'react';
import { Icon } from '@terrainvest/react-components';

import useWindowSize from '../../../../Utils/Hooks/useWindowSize';

import { StyledHeaderProfileMenuButton } from './index.styles';

interface HeaderProfileMenuButtonProps {
    id: string;
    open?: boolean;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderProfileMenuButton: React.FC<HeaderProfileMenuButtonProps> = ({
    open,
    setOpen,
    id = '',
}: HeaderProfileMenuButtonProps) => {
    const windowSize = useWindowSize();
    const isMobileViewport = windowSize && windowSize.width < 768;

    return (
        <StyledHeaderProfileMenuButton
            id={id}
            open={open}
            onClick={setOpen}
            isMobile={isMobileViewport}
        >
            {isMobileViewport ? (
                <>
                    <Icon id={id} icon={['fas', 'user-circle']} size="lg" />
                    <Icon
                        id={id}
                        icon={['fal', 'chevron-right']}
                        size="sm"
                        rotation={open ? 180 : 90}
                    />
                </>
            ) : (
                <>
                    <Icon id={id} icon={['fal', 'user-circle']} size="lg" />
                    <Icon
                        id={id}
                        icon={['fal', 'chevron-right']}
                        size="sm"
                        rotation={open ? 180 : 90}
                    />
                </>
            )}
        </StyledHeaderProfileMenuButton>
    );
};

HeaderProfileMenuButton.defaultProps = {
    open: false,
    setOpen: () => {},
};

export default HeaderProfileMenuButton;
