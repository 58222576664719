import * as React from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency, Icon, Table } from '@terrainvest/react-components';

import {
    StyledWrapper,
    StyledHeader,
    StyledSkeleton,
    StyledSpecialSkeleton,
    StyledCard,
    StyledTableWrapper,
    StyledTitle,
    StyledNoDataFeedback,
} from './index.styles';

import { StyledRowNoPadd, StyledColNoPadd } from '../../Common/Grid';
import StyledBalanceText from '../../../Utils/General/index.balancetextcolor';
import { feedbackNoFinancialData } from '../../Common/TextualInfo';

// Should be changed and placed on Header
export const FinancialOverviewMobile = () => {
    const { balance, loading, error, visible: balanceVisible } = useSelector(
        state => state.balanceState,
    );
    const [d1Balance, setD1BalanceState] = React.useState(0);
    const [d2Balance, setD2BalanceState] = React.useState(0);

    const [showFinancialData, setShowFinancialData] = React.useState(balanceVisible);

    const isAssetsBalanceNegative = balance && balance.Total < 0;
    const isBalanceAvailableNegative = balance && balance.Available < 0;
    const isProjectedBalanceNegative = balance && balance.ProjectedBalance < 0;
    const isD1BalanceNegative = balance && d1Balance && d1Balance < 0;
    const isD2BalanceNegative = balance && d2Balance && d2Balance < 0;
    const hasData = !loading && balance;

    React.useEffect(() => {
        if (!balance) {
            return;
        }

        const d1Cred = balance.Credits.filter(e => e.Day === 1).pop();
        const d1Debit = balance.Debits.filter(e => e.Day === 1).pop();

        const d2Cred = balance.Credits.filter(e => e.Day === 2).pop();
        const d2Debit = balance.Debits.filter(e => e.Day === 2).pop();

        const d1BalanceTotal = d1Cred.Value - d1Debit.Value;
        const d2BalanceTotal = d2Cred.Value - d2Debit.Value;

        setD1BalanceState(d1BalanceTotal);
        setD2BalanceState(d2BalanceTotal);
    }, [balance]);

    const handleShowFinancialDataClicked = () => {
        setShowFinancialData(prev => !prev);
    };

    const renderData = () => {
        const hasSomeData = !loading && !error && balance;
        const isLoadingOrHasData = loading || (!loading && !error && hasSomeData);
        const hasError = !loading && error;

        if (!showFinancialData) {
            return null;
        }

        if (isLoadingOrHasData) {
            return displayDynamicContent();
        }

        if (hasError) {
            return <StyledNoDataFeedback>{feedbackNoFinancialData}</StyledNoDataFeedback>;
        }

        return null;
    };

    const displayDynamicContent = () => {
        return (
            <StyledTableWrapper>
                <Table>
                    <Table.Body>
                        <Table.Row>
                            <Table.Column>Patrimônio</Table.Column>
                            <Table.Column textAlign="right">
                                {hasData ? (
                                    <StyledBalanceText
                                        isNegative={isAssetsBalanceNegative}
                                        large
                                        weight="bold"
                                    >
                                        {formatCurrency(balance.Total)}
                                    </StyledBalanceText>
                                ) : (
                                    <StyledSkeleton duration={1} height={32} />
                                )}
                            </Table.Column>
                        </Table.Row>
                        <Table.Row>
                            <Table.Column>Saldo</Table.Column>
                            <Table.Column textAlign="right">
                                {hasData ? (
                                    <StyledBalanceText
                                        isNegative={isBalanceAvailableNegative}
                                        large
                                        weight="bold"
                                    >
                                        {formatCurrency(balance.Available)}
                                    </StyledBalanceText>
                                ) : (
                                    <StyledSkeleton duration={1} height={32} />
                                )}
                            </Table.Column>
                        </Table.Row>
                        <Table.Row>
                            <Table.Column>D+1</Table.Column>
                            <Table.Column textAlign="right">
                                {hasData ? (
                                    <StyledBalanceText
                                        isNegative={isD1BalanceNegative}
                                        large
                                        weight="bold"
                                    >
                                        {formatCurrency(d1Balance)}
                                    </StyledBalanceText>
                                ) : (
                                    <StyledSkeleton duration={1} height={32} />
                                )}
                            </Table.Column>
                        </Table.Row>
                        <Table.Row>
                            <Table.Column>D+2</Table.Column>
                            <Table.Column textAlign="right">
                                {hasData ? (
                                    <StyledBalanceText
                                        isNegative={isD2BalanceNegative}
                                        large
                                        weight="bold"
                                    >
                                        {formatCurrency(d2Balance)}
                                    </StyledBalanceText>
                                ) : (
                                    <StyledSkeleton duration={1} height={32} />
                                )}
                            </Table.Column>
                        </Table.Row>
                        <Table.Row>
                            <Table.Column>
                                <Icon icon="analytics" size="sm" padRight />
                                Saldo Projetado
                            </Table.Column>
                            <Table.Column textAlign="right">
                                {hasData ? (
                                    <StyledBalanceText
                                        isNegative={isProjectedBalanceNegative}
                                        large
                                        weight="bold"
                                    >
                                        {formatCurrency(balance.ProjectedBalance)}
                                    </StyledBalanceText>
                                ) : (
                                    <StyledSpecialSkeleton duration={1} height={32} />
                                )}
                            </Table.Column>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </StyledTableWrapper>
        );
    };

    return (
        <StyledWrapper>
            <StyledCard sharpEdges>
                <StyledRowNoPadd>
                    <StyledColNoPadd>
                        <StyledHeader onClick={handleShowFinancialDataClicked}>
                            <StyledTitle>Seu resumo financeiro</StyledTitle>
                            <Icon
                                icon={showFinancialData ? 'chevron-left' : 'chevron-down'}
                                size="sm"
                            />
                        </StyledHeader>
                    </StyledColNoPadd>
                </StyledRowNoPadd>

                {renderData()}
            </StyledCard>
        </StyledWrapper>
    );
};

export default FinancialOverviewMobile;
