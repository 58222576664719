import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Provider as ThemeProvider, themeGenerator } from '@terrainvest/react-components';

import { fetchPartnerInit } from '../Redux/Partner';

import View from '../View';
import NoPartnerView from '../View/NoPartnerView';
import { setupIcons } from '../Utils/General/index.icons';

setupIcons();

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow-x: hidden;
    background-color: ${props => props.theme.global.colorAliases.background};
    transition: ${props => props.theme.global.transitions.gammaRay};

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const Core = () => {
    const dispatch = useDispatch();
    const { partner, loading, error } = useSelector(state => state.partnerState);
    const [theme, setTheme] = React.useState(null);

    React.useEffect(() => {
        if (!partner) {
            dispatch(fetchPartnerInit());
        }
    }, []);

    React.useEffect(() => {
        const generatedTheme =
            partner && !error && !loading
                ? themeGenerator(partner.style.colors, partner.style.typographyConfig)
                : themeGenerator();

        setTheme(generatedTheme);
    }, [partner]);

    const renderContent = () => {
        const hasNoPartner = (error || (!partner && !loading)) && theme;
        const hasPartnerAndTheme = partner && theme && !error && !loading;
        if (hasPartnerAndTheme) {
            return (
                <ThemeProvider theme={theme}>
                    <View />
                    <GlobalStyle />
                </ThemeProvider>
            );
        }
        if (hasNoPartner) {
            return (
                <ThemeProvider theme={theme}>
                    <NoPartnerView />
                </ThemeProvider>
            );
        }
        return (
            <FontAwesomeIcon
                style={{
                    position: 'absolute',
                    top: 'calc(50% - 1em)',
                    left: 'calc(50% - 1em)',
                }}
                size="6x"
                icon={['fas', 'spinner-third']}
                spin
            />
        );
    };

    return <>{renderContent()}</>;
};

export default Core;
