import * as React from 'react';
import { useFormikContext } from 'formik';
import {
    Text,
    InputText,
    InputCpf,
    InputDate,
    InputTel,
    Select,
    FormGroup,
    Radio,
} from '@terrainvest/react-components';

import { StyledCol, StyledRow } from '../../../Common/GridStyles';

import {
    validateDate,
    validateLength,
    validateRequiredField,
    isRequiredValidDate,
    validateEmail,
    validatePhone,
    validateRequiredCpfValue,
} from '../../../index.validators';

import { CommonPrefixes } from '../../../../../Redux/Common/index.constants';
import { disclaimerTexts, StyledDisclaimerText } from '../../../../Common/TextualInfo';
import { Field, SelectField } from '../../../Form/Field';
import { fieldLengthLimits } from '../../../index.settings';
import AddressInput from '../../../AdressInput';

export const ProcuratorInputForPerson = ({
    id,
    preffix,
    showValidityDate,
    validityEnabled,
    validityDisabled,
}) => {
    const { setFieldValue } = useFormikContext();

    return (
        <StyledRow>
            <StyledCol md={12} sm={12}>
                <Field name={`${preffix}ProcuratorName`} validate={validateRequiredField}>
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="Nome"
                            fullWidth
                            maxLength={fieldLengthLimits.name}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}ProcuratorCpfCnpj`} validate={validateRequiredCpfValue}>
                    {({ inputProps }) => <InputCpf {...inputProps} label="CPF" fullWidth />}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <SelectField
                    name={`${preffix}DocumentTypeId`}
                    commonPrefix={CommonPrefixes.DocumentTypes}
                >
                    {({ field, meta, onChange, options, defaultValue }) => (
                        <Select
                            name={field.name}
                            label="Tipo de Documento"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!meta.error}
                            message={meta.error || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChange}
                        />
                    )}
                </SelectField>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}DocumentNumber`} validate={validateRequiredField}>
                    {({ inputProps }) => (
                        <InputText {...inputProps} label="Número do Documento" fullWidth />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}DocumentIssuerDate`} validate={validateDate}>
                    {({ inputProps }) => (
                        <InputDate
                            {...inputProps}
                            id={`procurators[${preffix}]-validate-date`}
                            label="Data de emissão"
                            fullWidth
                            icon={false}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <SelectField
                    name={`${preffix}DocumentIssuerName`}
                    commonPrefix={CommonPrefixes.DocumentIssuerAgencies}
                    validate={validateRequiredField}
                >
                    {({ field, meta, onChange, options, defaultValue }) => (
                        <Select
                            name={field.name}
                            label="Órgão emissor"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!meta.error}
                            message={meta.error || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChange}
                        />
                    )}
                </SelectField>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <SelectField
                    name={`${preffix}DocumentIssuerState`}
                    commonPrefix={CommonPrefixes.States}
                    validate={validateRequiredField}
                >
                    {({ field, meta, onChange, options, defaultValue }) => (
                        <Select
                            name={field.name}
                            label="UF do emissor"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!meta.error}
                            message={meta.error || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChange}
                        />
                    )}
                </SelectField>
            </StyledCol>

            <AddressInput preffix={id} />

            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}Skype`} validate={value => validateLength('skype', value)}>
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="Skype"
                            fullWidth
                            maxLength={fieldLengthLimits.skype}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}MobilePhone`} validate={validatePhone}>
                    {({ inputProps }) => <InputTel {...inputProps} label="Celular" fullWidth />}
                </Field>
            </StyledCol>

            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}Email`} validate={validateEmail}>
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="E-mail"
                            fullWidth
                            maxLength={fieldLengthLimits.email}
                        />
                    )}
                </Field>
            </StyledCol>

            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}PoliticallyExposedPerson`}>
                    {({ field }) => (
                        <FormGroup
                            name={field.name}
                            border
                            vertical={false}
                            fullWidth
                            onChange={evt => {
                                field.onChange(evt);
                            }}
                        >
                            <Text>É pessoa políticamente exposta?</Text>
                            <Radio
                                label="Sim"
                                defaultChecked={field.value === true}
                                // eslint-disable-next-line react/jsx-boolean-value
                                defaultValue={true}
                                onChange={() => {
                                    setFieldValue(
                                        `${preffix}PoliticallyExposedPerson` as never,
                                        true,
                                    );
                                }}
                            />
                            <Radio
                                label="Não"
                                defaultChecked={field.value === false}
                                defaultValue={false}
                                onChange={() => {
                                    setFieldValue(
                                        `${preffix}PoliticallyExposedPerson` as never,
                                        false,
                                    );
                                }}
                            />
                        </FormGroup>
                    )}
                </Field>
            </StyledCol>

            <StyledCol col={12}>
                <StyledDisclaimerText small>
                    {disclaimerTexts.procurator.authorization}
                </StyledDisclaimerText>
            </StyledCol>

            <StyledCol md={12} sm={12}>
                <Field name={`${preffix}ValidityIndeterminate`}>
                    {({ field }) => (
                        <FormGroup
                            name={field.name}
                            border
                            vertical={false}
                            fullWidth
                            onChange={evt => {
                                field.onChange(evt);
                            }}
                        >
                            <Text>Validade</Text>
                            <Radio
                                label="Indeterminada"
                                // eslint-disable-next-line react/jsx-boolean-value
                                defaultValue={true}
                                defaultChecked={!field.value || field.value === true}
                                onChange={validityEnabled}
                            />
                            <Radio
                                label="Até"
                                defaultChecked={field.value === false}
                                defaultValue={false}
                                onChange={validityDisabled}
                            />
                        </FormGroup>
                    )}
                </Field>
            </StyledCol>

            {showValidityDate && (
                <StyledCol md={6} sm={12}>
                    <Field
                        name={`${preffix}ValidityDate`}
                        validate={showValidityDate && isRequiredValidDate}
                    >
                        {({ inputProps }) => (
                            <InputDate {...inputProps} label="Validade" fullWidth />
                        )}
                    </Field>
                </StyledCol>
            )}
        </StyledRow>
    );
};

export default ProcuratorInputForPerson;
