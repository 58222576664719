import * as React from 'react';
import { SideNavItem } from '@terrainvest/react-components';

import Sections from '../Sections';
import { HOME_ITEM_ID } from '..';

export const MobileSidebar = ({ history }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleToggle = result => {
        setIsOpen(() => result);
    };

    const handleClickedNavItem = (toggleStatus, redirectTo = '') => {
        handleToggle(toggleStatus);
        if (redirectTo) {
            history.push(redirectTo);
        }
    };

    return (
        <>
            <SideNavItem
                icon={isOpen ? 'times' : 'bars'}
                title="Menu"
                id="side-nav-item-menu"
                mobileNavItem
                mobileOnly
                onClick={handleToggle}
            >
                <SideNavItem
                    title="Visão Geral"
                    icon={['far', 'money-check']}
                    subNavItem
                    id={HOME_ITEM_ID}
                    onClick={value => handleClickedNavItem(value, '/app')}
                />

                <Sections isMobile handleClick={handleToggle} />
            </SideNavItem>
        </>
    );
};

export default MobileSidebar;
