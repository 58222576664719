import moment from 'moment';
import { parseDateForAPI } from '../../../Utils/Parsers/timeParsers';

const DENORMALIZE_FIELDS = [
    {
        label: 'CpfCnpj',
        normalizer: value => (value ? value.replace(/[^\d]/g, '') : undefined),
    },
    {
        label: 'PostalCode',
        normalizer: value => (value ? value.replace(/[^\d]/g, '') : undefined),
    },
    {
        label: 'ValidityDate',
        normalizer: date =>
            date ? moment(parseDateForAPI(date), 'YYYY-MM-DD').format() : undefined,
    },
    {
        label: 'AssetManagerCpfCnpj',
        normalizer: value => (value ? value.replace(/[^\d]/g, '') : undefined),
    },
];

const UNNECESSARY_FIELDS = ['key'];

const removeUnnecessaryFields = assetManager => {
    const denormalizedObj = { ...assetManager };

    UNNECESSARY_FIELDS.forEach(field => {
        if (denormalizedObj[field]) {
            delete denormalizedObj[field];
        }
    });

    return denormalizedObj;
};

const denormalizeFields = assetManager => {
    const denormalizedObj = { ...assetManager };

    DENORMALIZE_FIELDS.forEach(field => {
        const rawField = denormalizedObj[field.label];
        denormalizedObj[field.label] = field.normalizer(rawField);
    });

    return denormalizedObj;
};

const denormalizeAddressFields = assetManager => {
    const denormalizedObj = {
        ...assetManager,
        Complement: assetManager.SecondaryAddress,
        CityId: assetManager.CityId,
    };

    return denormalizedObj;
};

const denormalizeValidity = assetManager => {
    const denormalizedObj = { ...assetManager };
    const { ValidityIndeterminate } = { ...denormalizedObj } || { ValidityIndeterminate: false };

    if (ValidityIndeterminate === 'true') {
        denormalizedObj.ValidityDate = undefined;
    }

    return denormalizedObj;
};

const denormalizeOutgoingAssetManager = assetManager => {
    const denormalizedObj = { ...assetManager };

    return [denormalizedObj]
        .map(denormalizeAddressFields)
        .map(denormalizeFields)
        .map(denormalizeValidity)
        .map(removeUnnecessaryFields)
        .pop();
};

export const denormalizeOutgoingAssetManagers = client => {
    const denormalizedObjs = [...client.AssetManagers].map(denormalizeOutgoingAssetManager);

    return denormalizedObjs;
};
