import { parseDateForDisplay } from '../../Utils/Parsers';

const ICON_SPLIT_WORD = ' fa-';
// The icon fullname follows the pattern "fas fa-icon", but FontAwesomeIcon expects ['fas', 'icon']
const getIconArray = fullIconName => fullIconName.split(ICON_SPLIT_WORD);

export const normalizeLifeInsurance = pensions =>
    pensions &&
    pensions.map(pension => ({
        id: pension.Id,
        title: pension.Title,
        description: pension.Description,
        iconUrl: getIconArray(pension.IconUrl),
    }));

export const normalizeLifeInsurancesDetails = pension => {
    return {
        id: pension.Id,
        title: pension.Title,
        detailTitle: pension.DetailTitle,
        detailDescription: pension.DetailDescription,
        quotationLink: pension.QuotationLink,
        iconUrl: getIconArray(pension.IconUrl),
        detailIconUrl: getIconArray(pension.DetailIconUrl),
        detailCards: normalizeLifeInsurancesDetailCards(pension.DetailCards),
    };
};

export const normalizeLifeInsurancesDetailCards = cards =>
    cards &&
    cards.map(card => ({
        title: card.Title,
        iconUrl: getIconArray(card.IconUrl),
        description: card.Description,
        cardPosition: card.CardPosition,
        insuranceTypeId: card.InsuranceTypeId,
        items: normalizeLifeInsurancesDetailCardsItems(card.Items),
    }));

export const normalizeLifeInsuraceRequests = requests =>
    requests &&
    requests.map(request => ({
        id: request.Id,
        insuranceTypeId: request.InsuranceTypeId,
        insuranceTypeDescription: request.InsuranceTypeDescription,
        clientId: request.ClientId,
        clientName: request.ClientName,
        accountNumber: request.AccountNumber,
        status: request.Status,
        statusDescription: request.StatusDescription,
        registerUserId: request.RegisterUserId,
        registerDate: request.RegisterDate,
        modificationUserId: request.ModificationUserId,
        modificationDate: request.ModificationDate,
        startDate: request.StartDate,
        endDate: request.EndDate,
        registerDateNormalized: parseDateForDisplay(request.RegisterDate),
        modificationDateNormalized: parseDateForDisplay(request.ModificationDate),
        startDateNormalized: parseDateForDisplay(request.StartDate),
        endDateNormalized: parseDateForDisplay(request.EndDate),
        hasFile: request.File && true,
        file: request.File,
    }));

export const normalizeLifeInsurancesDetailCardsItems = items =>
    items &&
    items.map(item => ({
        id: item.Id,
        item: item.Item,
    }));
