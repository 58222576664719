import normalizeEarningsDividendsAsset from './EarningsDividends/index.normalizers';
import normalizeCollateralAsset from './Collateral/index.normalizers';
import normalizeIntradayAsset from './Intraday/index.normalizer';
import normalizeTermsAsset from './Terms/index.normalizers';

import { parseDateForDisplay, parseDate } from '../../Utils/Parsers/timeParsers';

export const normalizeStocks = assets =>
    assets && assets.map
        ? assets
              .map(asset => ({
                  name: asset.Assets,
                  quantity: asset.CurrentQuantity,
                  averagePrice: asset.AveragePrice,
                  spend: asset.SpendValue,
                  percentProfit: asset.PercentProfitLoss,
                  amount: asset.CurrentValue,
              }))
              .filter(asset => asset.quantity)
        : [];

export const normalizeFixedIncome = assets =>
    assets && assets.map
        ? assets
              .map(asset => ({
                  id: asset.Id,
                  assetId: asset.AssetId,
                  name: asset.IssuerName,
                  product: asset.SecurityName,
                  rentability: asset.RentabilityDescription,
                  invested: asset.InitialValue,
                  issuerName: asset.IndexName,
                  gross: asset.Total,
                  quantity: asset.Quantity,
                  amount: asset.Amount,
                  dueDate: asset.MaturityDate,
                  dueDateNormalized: parseDate(asset.MaturityDate),
              }))
              .filter(asset => asset.quantity)
        : [];

export const normalizeTreasuryDirect = assets =>
    assets && assets.map
        ? assets
              .map(asset => ({
                  name: asset.AssetName,
                  quantity: asset.Quantity,
                  invested: asset.InvestedValue,
                  gross: asset.GrossValue,
                  net: asset.NetValue,
                  incomeTax: asset.PercentProfitLoss,
                  dueDate: asset.DueDate,
                  dueDateNormalized: parseDateForDisplay(asset.DueDate),
              }))
              .filter(asset => asset.quantity)
        : [];

export const normalizeFunds = assets =>
    assets && assets.map
        ? assets
              .map(asset => ({
                  assetId: asset.AssetId,
                  name: asset.AssetName,
                  quantity: asset.TotalQuotes,
                  gross: asset.GrossAmount,
                  net: asset.NetValue,
                  invested: asset.InitialValue,
                  cnpj: asset.Cnpj,
              }))
              .filter(asset => asset.quantity)
        : [];

export const normalizeFutures = assets =>
    assets && assets.map
        ? assets
              .map(asset => ({
                  name: asset.Assets,
                  quantity: asset.CurrentQuantity,
                  percentProfit: asset.PercentProfitLoss,
                  currentPrice: asset.LastTrade,
                  expired: asset.Expired,
                  adjustedAmount: asset.ValueAdjustedAmount,
              }))
              .filter(asset => asset.quantity)
        : [];

export const normalizeEarningsDividends = assets => {
    return assets && assets.map
        ? [...assets].map(normalizeEarningsDividendsAsset).map(asset => ({
              name: asset.Stock,
              type: asset.EarningType,
              quantity: asset.Quantity,
              tax: asset.Tax,
              grossAmount: asset.GrossValue,
              netAmount: asset.NetValue,
              paymentEstimation: asset.PaymentEstimation,
              normalized: asset.normalized,
          }))
        : [];
};

export const normalizeBtc = assets =>
    assets && assets.map
        ? assets.map(asset => ({
              name: asset.Quote,
              contract: asset.ContractId,
              quantity: asset.Quantity,
              dueDate: asset.DueDate,
              dueDateNormalized: parseDateForDisplay(asset.DueDate),
              description: asset.Description,
          }))
        : [];

export const normalizeCollateral = assets => {
    return assets && assets.map
        ? [...assets].map(normalizeCollateralAsset).map(asset => ({
              name: asset.CodigoIdentificacaoGarantia,
              emitter: '--',
              quantity: asset.QuantidadeDepositada,
              normalized: asset.normalized,
          }))
        : [];
};

export const normalizeIntraday = assets => {
    return assets && assets.map
        ? [...assets].map(normalizeIntradayAsset).map(asset => ({
              dateEmitted: asset.normalized.DataLancamento.value,
              dateLiquidity: asset.normalized.DataLiquidacao.value,
              quantity: asset.quantity || '--',
              value: asset.normalized.Valor.value,
              normalized: asset.normalized,
          }))
        : [];
};

export const normalizeTerms = assets => {
    return assets && assets.map
        ? [...assets].map(normalizeTermsAsset).map(asset => ({
              name: asset.Quote,
              contractId: asset.ContractId,
              contractNumber: asset.ContractNumber,
              quantity: asset.AvailableQuantity,
              normalized: asset.normalized,
          }))
        : [];
};

export const normalizePrivatePension = assets =>
    assets && assets.map
        ? assets.map(asset => ({
              id: asset.FundId,
              name: asset.FundName,
              graceRescueDate: parseDate(asset.GraceRescueDate),
              balanceDate: parseDate(asset.BalanceDate),
              certificateNumber: asset.CertificateNumber,
              totalQuotesQuantity: asset.TotalQuotesQuantity,
              liquidRescueBalance: asset.LiquidRescueBalance,
              balanceValue: asset.BalanceValue,
              ir: asset.IR,
              iof: asset.IOF,
          }))
        : [];
