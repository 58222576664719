import styled, { css } from 'styled-components';
import { Icon } from '@terrainvest/react-components';

export const StyledHeaderLinkItem = styled.div`
    ${() =>
        css`
            padding-left: 32px;
            padding-right: 32px;
            color: #42555d;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 13px;

            cursor: pointer;
            & > span {
                font-size: 12px;
                font-weight: 400;
                margin-right: auto;
                font-family: Montserrat;
                &:hover {
                    font-weight: 500;
                }
            }

            ${Icon.Styles.StyledIcon} {
                font-size: 16px;
            }
        `}
`;
