import { AuthorizationConfig } from './index.types';
import ShareholderClient from './ShareholderClient';

const Authorizations: AuthorizationConfig[] = [
    {
        keys: ['/investimentos'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['/extratos'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['/relatorios'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['/resgate'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['/contratos'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['/plataformas'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['/simuladores'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['/limites'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['/carteira'],
        authorizer: ShareholderClient,
    },
    {
        keys: ['homebroker'],
        authorizer: ShareholderClient,
    },
];

export default Authorizations;
