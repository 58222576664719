import styled from 'styled-components';
import { Button, Notification } from '@terrainvest/react-components';

export const StyledRepresentatives = styled.div`
    ${Button.Styles.StyledButton} {
        height: 48px;
        margin-bottom: 32px;
    }
`;

export const StyledNotification = styled(Notification)`
    margin-bottom: 40px;
`;
