import styled from 'styled-components';
import { Icon } from '@terrainvest/react-components';

export const StyledHeaderProfileMenuButton = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${Icon.Styles.StyledIcon} {
        color: ${props =>
            props.isMobile
                ? props.theme.components.header.colors.mobile.foreground
                : props.theme.components.header.colors.desktop.foreground};
    }

    ${Icon.Styles.StyledIcon}:first-child {
        margin-right: 8px;
        padding-right: 4px;
    }

    ${Icon.Styles.StyledIcon}:last-child {
        font-size: 12px;
        padding-right: 8px;
        margin-left: 4px;
        transition: all 400ms ease-in-out;
    }
`;
