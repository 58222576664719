import moment from 'moment';
import { ENDPOINTS } from '../../Utils/Api';

const formatter = dateAsString => moment(dateAsString).format('DD/MM/YYYY hh:mm');

export const normalizeAgreement = agreement => {
    const agreementDate = formatter(agreement.AgreementDate);
    const expirationDate = agreement.ExpirationDate
        ? formatter(agreement.ExpirationDate)
        : 'Sem vencimento';

    const filePath = `${process.env.API_URL}${ENDPOINTS.GET_AGREEMENT_FILE.replace(
        ':agreementId',
        agreement.AgreementId,
    )}`;

    return {
        name: agreement.AgreementTypeDescription,
        typeName: agreement.Group,
        agreementDate,
        expirationDate,
        filePath,
        ...agreement,
    };
};

export const normalizeAgreements = agreements =>
    agreements && agreements.map(agreement => normalizeAgreement(agreement));
