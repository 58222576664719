import { Icons } from '@terrainvest/react-components';
import {
    faExclamationCircle as fasExclamationCircle,
    faPhoneAlt as fasPhoneAlt,
    faArrowAltDown as fasArrowAltDown,
    faRedo as fasRedo,
    faReceipt as fasReceipt,
    faCogs as fasCogs,
    faArrowUp as fasArrowUp,
    faArrowDown as fasArrowDown,
    faCircle as fasCircle,
    faLock as fasLock,
    faQuestion as fasQuestion,
    faArrowAltToBottom as fasArrowAltToBottom,
    faHistory as fasHistory,
    faSpinner as fasSpinner,
    faUpload as fasUpload,
    faArrowAltUp as fasArrowAltUp,
    faUserCircle as fasUserCircle,
    faPlus as fasPlus,
    faSpinnerThird as fasSpinnerThird,
    faPlay as fasPlay,
    faUserLock as fasUserLock,
    faChartLine as fasChartLine,
    faTasks as fasTasks,
    faStop as fasStop,
    faList as fasList,
    faThLarge as fasThLarge,
    faBullhorn as fasBullhorn,
    faDownload as fasDownload,
    faInfoCircle as fasInfoCircle,
    faShieldCheck as fasShieldCheck,
    faSync as fasSync,
    faArrowAltLeft as fasArrowAltLeft,
    faArrowCircleDown as fasArrowCircleDown,
    faListUl as fasListUl,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faMoneyCheck as farMoneyCheck,
    faFileChartLine as farFileChartLine,
    faInfoCircle as farInfoCircle,
    faArrowAltToBottom as farArrowAltToBottom,
    faChartNetwork as farChartNetwork,
    faCommentDollar as farCommentDollar,
    faInboxOut as farInboxOut,
    faWallet as farWallet,
    faTachometerAverage as farTachometerAverage,
    faClipboard as farClipboard,
    faFileInvoiceDollar as farFileInvoiceDollar,
    faTreasureChest as farTreasureChest,
    faHandHoldingUsd as farHandHoldingUsd,
    faWater as farWater,
    faHandshake as farHandshake,
    faPiggyBank as farPiggyBank,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faUserCircle as falUserCircle,
    faSearch as falSearch,
} from '@fortawesome/pro-light-svg-icons';

export const setupIcons = () => {
    Icons.setup(
        fasExclamationCircle,
        fasPhoneAlt,
        fasArrowAltDown,
        fasRedo,
        fasReceipt,
        fasCogs,
        fasArrowUp,
        fasArrowDown,
        fasCircle,
        fasLock,
        fasQuestion,
        fasArrowAltToBottom,
        fasHistory,
        fasSpinner,
        fasUpload,
        fasChartLine,
        fasArrowAltUp,
        fasTasks,
        farMoneyCheck,
        farFileChartLine,
        farInfoCircle,
        farChartNetwork,
        farCommentDollar,
        farInboxOut,
        farWallet,
        farTachometerAverage,
        farClipboard,
        farFileInvoiceDollar,
        farTreasureChest,
        farWater,
        farHandshake,
        farPiggyBank,
        falUserCircle,
        falSearch,
        fasUserCircle,
        fasPlus,
        farArrowAltToBottom,
        fasSpinnerThird,
        fasPlay,
        fasUserLock,
        fasStop,
        farHandHoldingUsd,
        fasList,
        fasThLarge,
        fasBullhorn,
        fasDownload,
        fasInfoCircle,
        fasShieldCheck,
        fasSync,
        fasArrowAltLeft,
        fasArrowCircleDown,
        fasListUl,
    );
};
