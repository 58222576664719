import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchBrokerageNoteDates,
    fetchBrokerageNoteDatesSuccess,
    fetchBrokerageNoteDatesError,
} from './index.actions';

export const initialState = {
    dates: null,
    loading: false,
    error: null,
    fileUrl: null,
};

export const brokerageNoteDatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_BROKERAGENOTE_DATES_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchBrokerageNoteDates, {
                    successActionCreator: fetchBrokerageNoteDatesSuccess,
                    failActionCreator: fetchBrokerageNoteDatesError,
                    args: [action.data],
                }),
            );

        case Actions.FETCH_BROKERAGENOTE_DATES_SUCCESS:
            return { ...state, dates: [...action.dates], loading: false, error: false };

        case Actions.FETCH_BROKERAGENOTE_DATES_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_BROKERAGENOTE_DATES_RESET:
            return { ...state, dates: null, error: null, loading: false };

        default:
            return state;
    }
};
