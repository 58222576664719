import moment from 'moment';
import { parseDateForAPI } from '../../../Utils/Parsers/timeParsers';

const DENORMALIZE_FIELDS = [
    {
        label: 'ProcuratorCpfCnpj',
        normalizer: value => (value ? value.replace(/[^\d]/g, '') : undefined),
    },
    {
        label: 'PostalCode',
        normalizer: value => (value ? value.replace(/[^\d]/g, '') : undefined),
    },
    {
        label: 'DocumentIssuerDate',
        normalizer: date =>
            date ? moment(parseDateForAPI(date), 'YYYY-MM-DD').format() : undefined,
    },
];

const UNNECESSARY_FIELDS = ['key'];

const removeUnnecessaryFields = procurator => {
    const denormalizedProcurator = { ...procurator };

    UNNECESSARY_FIELDS.forEach(field => {
        if (denormalizedProcurator[field]) {
            delete denormalizedProcurator[field];
        }
    });

    return denormalizedProcurator;
};

const denormalizeProcuratorFields = procurator => {
    const denormalizedProcurator = { ...procurator };

    DENORMALIZE_FIELDS.forEach(field => {
        const rawField = denormalizedProcurator[field.label];
        denormalizedProcurator[field.label] = field.normalizer(rawField);
    });

    return denormalizedProcurator;
};

const denormalizeAddressFields = procurator => {
    const denormalizedProcurator = {
        ...procurator,
        Complement: procurator.SecondaryAddress,
        CityId: procurator.CityId,
    };

    return denormalizedProcurator;
};

const denormalizeValidity = assetManager => {
    const denormalizedObj = { ...assetManager };
    const { ValidityIndeterminate } = { ...denormalizedObj } || { ValidityIndeterminate: false };

    if (ValidityIndeterminate) {
        denormalizedObj.ValidityDate = undefined;
    }

    return denormalizedObj;
};

const denormalizeOutgoingProcurator = procurator => {
    const denormalizedProcurator = { ...procurator };

    return [denormalizedProcurator]
        .map(denormalizeAddressFields)
        .map(denormalizeProcuratorFields)
        .map(denormalizeValidity)
        .map(removeUnnecessaryFields)
        .pop();
};

export const denormalizeOutgoingProcurators = client => {
    const denormalizedProcurators = [...client.Procurators].map(denormalizeOutgoingProcurator);

    return denormalizedProcurators;
};
