import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Button, Icon } from '@terrainvest/react-components';

import FormCard from '../Common/FormCard';
import { FORM_SECTIONS } from '../Form';

import Separator from '../../../Utils/General/index.separator';
import FeedbackNote from '../../Common/FeedbackNote';

import { addNewEmptyAssetManager, removeAssetManagerByIndex } from './index.model';
import AssetManagerInput from './AssetManagerInput';

export const AssetManagers = ({ isCompany }) => {
    const formikContext = useFormikContext();
    const { values } = formikContext;
    const { ClientId } = useSelector(state => state.clientState.client);

    const handleAdd = () => {
        addNewEmptyAssetManager(formikContext, ClientId);
    };

    const handleRemove = index => {
        removeAssetManagerByIndex(formikContext, index);
    };

    return (
        <>
            {values && values.AuthorizesOrdersByAdministrator && (
                <FormCard
                    id={FORM_SECTIONS.ASSET_MANAGERS.ID}
                    title={FORM_SECTIONS.ASSET_MANAGERS.NAME}
                    subTitle={FORM_SECTIONS.ASSET_MANAGERS.DETAILS}
                >
                    {values &&
                        values.AssetManagers &&
                        values.AssetManagers.map((assetManager, index) => (
                            <AssetManagerInput
                                key={assetManager.AssetManagerId || assetManager.key}
                                index={index}
                                preffix={`AssetManagers[${index}]`}
                                onRemove={handleRemove}
                                isCompany={isCompany}
                            />
                        ))}

                    {values && (!values.AssetManagers || !values.AssetManagers.length) && (
                        <>
                            <Separator />
                            <FeedbackNote
                                color="warn"
                                message="Você precisa adicionar administradores de carteira para continuar."
                            />
                            <Separator />
                        </>
                    )}

                    <Separator />
                    <Button type="button" fullWidth secondary onClick={handleAdd}>
                        <Icon icon={['fas', 'plus']} padRight size="xs" />
                        ADICIONAR ADMINISTRADOR DE CARTEIRA
                    </Button>
                </FormCard>
            )}
        </>
    );
};

export default AssetManagers;
export * from './index.model';
