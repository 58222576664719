export const appMsgTypes = {
    LOADED: 'loaded',
    LOGOUT: 'logout',
    FILE: 'file',
    DIRECT_LINK: 'direct-link',
};

export const postAppMsg = (type, payload = null) => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        const message = {
            type,
            payload,
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
};
