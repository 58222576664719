const ICON_SPLIT_WORD = ' fa-';

// The icon fullname follows the pattern "fas fa-icon", but FontAwesomeIcon expects ['fas', 'icon']
const getIconArray = fullIconName => fullIconName.split(ICON_SPLIT_WORD);

export const normalizeFunctionalities = functionalities =>
    functionalities && functionalities.map
        ? functionalities.map(functionality => ({
              id: functionality.id,
              name: functionality.name,
              tooltip: functionality.tooltip,
              url: functionality.url,
              icon: getIconArray(functionality.icon),
              suitability: functionality.suitability,
              blockInstitutional: functionality.notAllowedInstitutional,
              bottomMenu: functionality.bottomMenu,
          }))
        : [];

export const normalizeSections = sections =>
    sections && sections.map
        ? sections.map(section => ({
              id: section.id,
              name: section.name,
              functionalities: normalizeFunctionalities(section.functionalities),
          }))
        : [];
