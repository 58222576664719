export const fieldLengthLimits = {
    name: 60,
    email: 60,
    skype: 50,
    motherName: 60,
    fatherName: 60,
    spouseName: 60,
    company: 60,
    address: 100,
    addressNumber: 5,
    addressComplement: 10,
    addressNeighborhood: 18,
    cnhNumber: 20,
    safetyCode: 20,
    rgNumber: 60,
    rneNumber: 20,
    bankBranch: 5,
    bankAccount: 40,
    bankDigit: 1,
    nameCoHolder: 60,
    percentage: 3,
    constitutionFormat: 60,
};

export default fieldLengthLimits;
