import * as React from 'react';
import { H2, Text, InputFileUpload } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import { StyledAttachments, StyledFileSection } from './index.styles';
import { FORM_SECTIONS } from '../Form';
import { PAGE_OFFSET } from '../index.styles';
import { Field } from '../Form/Field';
import { validateFile } from '../index.validators';

import FormCard from '../Common/FormCard';
import { REGISTRATION_STATUS } from '../../../Utils/General/index.registration-status';

const Attachments = ({ isCompany }) => {
    const { values, touched } = useFormikContext();

    const bankAccountsWithCoTitular =
        values && values.BankAccounts.filter(bankAccount => bankAccount && bankAccount.CpfCoOwner);

    const representatives = values && values.Representatives ? values.Representatives : [];
    const orderIssuers = values && values.OrderIssuers ? values.OrderIssuers : [];

    React.useEffect(() => {
        const element = document.getElementById(FORM_SECTIONS.ATTACHMENTS.ID);

        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top: element.offsetTop - PAGE_OFFSET,
            });
        }
    }, []);

    const onChange = (name, type, form) => file => {
        form.setFieldValue('RegistrationStatusId', REGISTRATION_STATUS.IN_ANALYSIS);

        form.setFieldValue(name, {
            file,
            clientId: values.ClientId,
            type,
        });
    };

    const askCnpjCard = () => touched && touched.Address;

    const askSocialContract = () =>
        touched && (touched.Address || touched.Name || touched.Representatives || touched.Activity);

    const askLastBalanceSheet = () => (touched && touched.Patrimony) || touched.BankAccounts;

    const askEmitterDocuments = () => touched && touched.OrderIssuers;

    const askRepresentativesCpf = () => touched && touched.Representatives;

    const getTouchedRepresentatives = () => {
        const touchedIndices = getAlteredIndices(touched.Representatives) as Array<number>;

        return representatives.filter((e, index) => touchedIndices.includes(index));
    };

    const getTouchedOrderIssuers = () => {
        const touchedIndices = getAlteredIndices(touched.OrderIssuers) as Array<number>;

        return orderIssuers.filter((e, index) => touchedIndices.includes(index));
    };

    const getAlteredIndices = collection => {
        const dirtyIndices = new Array<number>();

        collection.forEach((e, index) => {
            if (e) {
                dirtyIndices.push(index);
            }
        });

        return dirtyIndices;
    };

    const getPersonForm = () => (
        <>
            {touched && touched.Documents && (
                <StyledFileSection>
                    <H2 weight="medium">Documento de identidade</H2>
                    <Field name="files.documentNumber" validate={validateFile}>
                        {({ field, meta, form }) => (
                            <InputFileUpload
                                name={field.name}
                                width="100%"
                                message="Arquivos aceitos: PNG, JPEG e PDF"
                                error={!!meta.error}
                                errorMessage={meta.error}
                                onChange={onChange(field.name, 3, form)}
                            />
                        )}
                    </Field>
                </StyledFileSection>
            )}

            {touched && touched.Address && (
                <StyledFileSection>
                    <H2 weight="medium">Comprovante de residência</H2>
                    <Text weight="medium">
                        Importante:{' '}
                        <Text>
                            Prazo máximo de emissão é de 6 meses. Você pode utilizar a conta de
                            água, luz, telefone ou gás. Deve ser em seu nome, do seu pai, mãe ou
                            cônjuge.
                        </Text>
                    </Text>
                    <Field name="files.address" validate={validateFile}>
                        {({ field, meta, form }) => (
                            <InputFileUpload
                                name={field.name}
                                width="100%"
                                message="Arquivos aceitos: PNG, JPEG e PDF"
                                error={!!meta.error}
                                errorMessage={meta.error}
                                onChange={onChange(field.name, 4, form)}
                            />
                        )}
                    </Field>
                </StyledFileSection>
            )}

            {touched && touched.Procurators && (
                <>
                    {/*
                        PF não precisará mais enviar cópia do contrato de procuração,
                        porém a feature ainda está em discussão
                    */}

                    {/* <StyledFileSection>
                        <H2 weight="medium">Procuração</H2>
                        <Field name="files.authenticatedPowerofAttorney" validate={validateFile}>
                            {({ field, meta, form }) => (
                                <InputFileUpload
                                    name={field.name}
                                    width="100%"
                                    message="Arquivos aceitos: PNG, JPEG e PDF"
                                    error={!!meta.error}
                                    errorMessage={meta.error}
                                    onChange={onChange(field.name, 17, form)}
                                />
                            )}
                        </Field>
                    </StyledFileSection> */}
                    <StyledFileSection>
                        <H2 weight="medium">Comprovante de CPF do Procurador</H2>
                        <Field name="files.procuratorDocument" validate={validateFile}>
                            {({ field, meta, form }) => (
                                <InputFileUpload
                                    name={field.name}
                                    width="100%"
                                    message="Arquivos aceitos: PNG, JPEG e PDF"
                                    error={!!meta.error}
                                    errorMessage={meta.error}
                                    onChange={onChange(field.name, 21, form)}
                                />
                            )}
                        </Field>
                    </StyledFileSection>
                </>
            )}

            {touched && touched.AssetManagers && (
                <StyledFileSection>
                    <H2 weight="medium">Cópia do Contrato (Administrador de Carteira)</H2>
                    <Field name="files.assetManagerContract" validate={validateFile}>
                        {({ field, meta, form }) => (
                            <InputFileUpload
                                name={field.name}
                                width="100%"
                                message="Arquivos aceitos: PNG, JPEG e PDF"
                                error={!!meta.error}
                                errorMessage={meta.error}
                                onChange={onChange(field.name, 21, form)}
                            />
                        )}
                    </Field>
                </StyledFileSection>
            )}

            {touched &&
                touched.BankAccounts &&
                !!bankAccountsWithCoTitular.length &&
                bankAccountsWithCoTitular.map((bankAccount, index) => (
                    <StyledFileSection key={bankAccount.BankNumber}>
                        <H2 weight="medium">{`Documento do Co-Titular da Conta ${index + 1}`}</H2>
                        <Field name={`files.bankAccount${index}`} validate={validateFile}>
                            {({ field, meta, form }) => (
                                <InputFileUpload
                                    name={field.name}
                                    width="100%"
                                    message="Arquivos aceitos: PNG, JPEG e PDF"
                                    error={!!meta.error}
                                    errorMessage={meta.error}
                                    onChange={onChange(field.name, 23, form)}
                                />
                            )}
                        </Field>
                    </StyledFileSection>
                ))}
        </>
    );

    const getCompanyForm = () => (
        <>
            {askCnpjCard() && (
                <StyledFileSection>
                    <H2 weight="medium">Cópia do cartão CNPJ</H2>
                    <Field name="files.cnpjCard" validate={validateFile}>
                        {({ field, meta, form }) => (
                            <InputFileUpload
                                name={field.name}
                                width="100%"
                                message="Arquivos aceitos: PNG, JPEG e PDF"
                                error={!!meta.error}
                                errorMessage={meta.error}
                                onChange={onChange(field.name, 5, form)}
                            />
                        )}
                    </Field>
                </StyledFileSection>
            )}

            {askSocialContract() && (
                <StyledFileSection>
                    <H2 weight="medium">Cópia do Contrato Social</H2>
                    <Field name="files.socialContract" validate={validateFile}>
                        {({ field, meta, form }) => (
                            <InputFileUpload
                                name={field.name}
                                width="100%"
                                message="Arquivos aceitos: PNG, JPEG e PDF"
                                error={!!meta.error}
                                errorMessage={meta.error}
                                onChange={onChange(field.name, 6, form)}
                            />
                        )}
                    </Field>
                </StyledFileSection>
            )}

            {askLastBalanceSheet() && (
                <StyledFileSection>
                    <H2 weight="medium">Cópia do último balanço patrimonial</H2>
                    <Field name="files.balanceSheet" validate={validateFile}>
                        {({ field, meta, form }) => (
                            <InputFileUpload
                                name={field.name}
                                width="100%"
                                message="Arquivos aceitos: PNG, JPEG e PDF"
                                error={!!meta.error}
                                errorMessage={meta.error}
                                onChange={onChange(field.name, 7, form)}
                            />
                        )}
                    </Field>
                </StyledFileSection>
            )}

            {askRepresentativesCpf() &&
                getTouchedRepresentatives().map((representative, index) => (
                    <StyledFileSection key={representative.Name}>
                        <H2 weight="medium">Cópia do CPF do representante {representative.Name}</H2>
                        <Field name={`files.Representative${index}Cpf`} validate={validateFile}>
                            {({ field, meta, form }) => (
                                <InputFileUpload
                                    name={field.name}
                                    width="100%"
                                    message="Arquivos aceitos: PNG, JPEG e PDF"
                                    error={!!meta.error}
                                    errorMessage={meta.error}
                                    onChange={onChange(field.name, 23, form)}
                                />
                            )}
                        </Field>
                    </StyledFileSection>
                ))}

            {askEmitterDocuments() &&
                getTouchedOrderIssuers().map((orderIssuer, index) => (
                    <>
                        <StyledFileSection key={`${orderIssuer.Name}_CPF`}>
                            <H2 weight="medium">Cópia do CPF do emissor {orderIssuer.Name}</H2>
                            <Field name={`files.OrderIssuer${index}Cpf`} validate={validateFile}>
                                {({ field, meta, form }) => (
                                    <InputFileUpload
                                        name={field.name}
                                        width="100%"
                                        message="Arquivos aceitos: PNG, JPEG e PDF"
                                        error={!!meta.error}
                                        errorMessage={meta.error}
                                        onChange={onChange(field.name, 23, form)}
                                    />
                                )}
                            </Field>
                        </StyledFileSection>
                        <StyledFileSection key={`${orderIssuer.Name}_RG`}>
                            <H2 weight="medium">Cópia do RG do emissor {orderIssuer.Name}</H2>
                            <Field name={`files.OrderIssuer${index}Rg`} validate={validateFile}>
                                {({ field, meta, form }) => (
                                    <InputFileUpload
                                        name={field.name}
                                        width="100%"
                                        message="Arquivos aceitos: PNG, JPEG e PDF"
                                        error={!!meta.error}
                                        errorMessage={meta.error}
                                        onChange={onChange(field.name, 23, form)}
                                    />
                                )}
                            </Field>
                        </StyledFileSection>
                    </>
                ))}

            {touched && touched.Procurators && (
                <>
                    <StyledFileSection>
                        <H2 weight="medium">Procuração</H2>
                        <Field name="files.authenticatedPowerofAttorney" validate={validateFile}>
                            {({ field, meta, form }) => (
                                <InputFileUpload
                                    name={field.name}
                                    width="100%"
                                    message="Arquivos aceitos: PNG, JPEG e PDF"
                                    error={!!meta.error}
                                    errorMessage={meta.error}
                                    onChange={onChange(field.name, 17, form)}
                                />
                            )}
                        </Field>
                    </StyledFileSection>
                    <StyledFileSection>
                        <H2 weight="medium">Comprovante de CPF do Procurador</H2>
                        <Field name="files.procuratorDocument" validate={validateFile}>
                            {({ field, meta, form }) => (
                                <InputFileUpload
                                    name={field.name}
                                    width="100%"
                                    message="Arquivos aceitos: PNG, JPEG e PDF"
                                    error={!!meta.error}
                                    errorMessage={meta.error}
                                    onChange={onChange(field.name, 21, form)}
                                />
                            )}
                        </Field>
                    </StyledFileSection>
                </>
            )}

            {touched && touched.AssetManagers && (
                <StyledFileSection>
                    <H2 weight="medium">Cópia do Contrato (Administrador de Carteira)</H2>
                    <Field name="files.assetManagerContract" validate={validateFile}>
                        {({ field, meta, form }) => (
                            <InputFileUpload
                                name={field.name}
                                width="100%"
                                message="Arquivos aceitos: PNG, JPEG e PDF"
                                error={!!meta.error}
                                errorMessage={meta.error}
                                onChange={onChange(field.name, isCompany ? 26 : 27, form)}
                            />
                        )}
                    </Field>
                </StyledFileSection>
            )}
        </>
    );

    return (
        <StyledAttachments id={FORM_SECTIONS.ATTACHMENTS.ID}>
            <FormCard
                title={FORM_SECTIONS.ATTACHMENTS.NAME}
                subTitle={FORM_SECTIONS.ATTACHMENTS.DETAILS}
            >
                {isCompany ? getCompanyForm() : getPersonForm()}
            </FormCard>
        </StyledAttachments>
    );
};

Attachments.defaultProps = {
    isCompany: false,
};

export default Attachments;
