import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HighlightedText, H3, Skeleton, Icon } from '@terrainvest/react-components';

import {
    fetchFileAsObjUrl,
    postClientAgreement,
    fetchClientAgreementByTypeInit,
} from '../../../Redux/Agreements';
import {
    postNotificationFromError,
    notifySuccessMsg,
    postNotification,
} from '../../../Redux/Notification';

import { Separator } from '../../../Utils/General/index.separator';
import { StyledContainerNoPadd, StyledRowNoPadd, StyledColNoPadd } from '../../Common/Grid';

import { disclaimerTexts } from '../../Common/TextualInfo';
import { IframeShell, GetSrcInstructions } from '../../Common/IframeShell';
import { ElectronicPasswordInput } from '../../Common/ElectronicPasswordInput';
import { ENDPOINTS } from '../../../Utils/Api';
import { useNotifyAppLoad } from '../../../Utils/Hooks';
import { needsAgreementUpdate } from '../../../Utils/General';

import {
    StyledOpenFlatCard,
    StyledContentWrapper,
    StyledDisclaimer,
    StyledGlobalNotification,
} from '../index.styles';
import { StyledSubmitButton } from './index.styles';

export const ExpiredIntermediationContract = () => {
    const { client, loading: loadingClient } = useSelector(state => state.clientState);
    const { agreementByType: agreement, loading: loadingAgreement } = useSelector(
        state => state.agreementsState,
    );

    const [electronicCheck, setElectronicCheck] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    useNotifyAppLoad();

    const renderIframe = () => {
        const hasNoData = !client || !client.ClientId;

        if (loadingClient || hasNoData) {
            return <Skeleton width="100%" height={400} />;
        }

        const url = `${process.env.API_URL}${ENDPOINTS.GET_AGREEMENTS_PDF_BY_CLIENT_ID.replace(
            ':clientId',
            client.ClientId,
        ).replace(':signatureOption', 'true')}`;

        const mobileSrcInstructions = {
            fetchSrcData: () => url,
        } as GetSrcInstructions;

        const desktopSrcInstructions = {
            fetchSrcData: fetchAgreementFileUrl,
            props: [url],
            config: { method: 'GET' },
        } as GetSrcInstructions;

        return (
            <IframeShell
                title="Termo de adesão"
                width="100%"
                height={400}
                frameBorder={0}
                mobileSrcInstructions={mobileSrcInstructions}
                desktopSrcInstructions={desktopSrcInstructions}
            />
        );
    };

    const fetchAgreementFileUrl = async url => {
        const fileObjUrl = await fetchFileAsObjUrl(url).then(data => data.url);
        return fileObjUrl;
    };

    const handleDataNotExpired = () => {
        redirectToHome();
    };

    const handleDataExpired = () => {
        notifyExpiration();
    };

    const handleChangedElectronicPassword = event => {
        setElectronicCheck(event.target.value);
    };

    const handleSubmit = async () => {
        setSubmitting(true);

        try {
            await postClientAgreement(agreement, client, electronicCheck);

            dispatch(fetchClientAgreementByTypeInit(client))
                .then(redirectToHome)
                .then(() =>
                    dispatch(notifySuccessMsg('Sucesso! Registro atualizado.', { timeout: 8000 })),
                );
        } catch (e) {
            dispatch(postNotificationFromError(e));
            setSubmitting(false);
        }
    };

    const redirectToHome = () => {
        return history.push('/app');
    };

    const notifyExpiration = () => {
        dispatch(
            postNotification('A validade de seu contrato de intermediação expirou.', {
                timeout: 8000,
                type: 'error',
            }),
        );
    };

    React.useEffect(() => {
        const hasClient = client && !loadingClient;
        const hasAgreement = agreement && !loadingAgreement;

        if (!hasClient) {
            return;
        }

        if (!hasAgreement) {
            dispatch(fetchClientAgreementByTypeInit(client));
            return;
        }

        if (hasClient && hasAgreement) {
            setSubmitting(false);
            const userDataExpired = needsAgreementUpdate(agreement);

            if (!userDataExpired) {
                handleDataNotExpired();
            }

            if (userDataExpired) {
                handleDataExpired();
            }
        }
    }, []);

    return (
        <StyledContainerNoPadd>
            <StyledRowNoPadd>
                <StyledColNoPadd>
                    <Separator />
                    <StyledOpenFlatCard>
                        <StyledContentWrapper>
                            <HighlightedText
                                asideColor="error"
                                title="TERMOS ATUALIZADOS"
                                subTitle="Contrato de Intermediação"
                                large
                            />

                            <StyledDisclaimer>
                                {disclaimerTexts.dataExpiration.reviewAndAcceptToContinue}
                            </StyledDisclaimer>
                        </StyledContentWrapper>
                    </StyledOpenFlatCard>
                    <Separator />
                    <StyledOpenFlatCard>
                        <StyledContentWrapper>
                            <H3>Contrato de Intermediação</H3>
                            {renderIframe()}
                            <Separator />
                            <ElectronicPasswordInput
                                fullWidth
                                name="password"
                                id="password"
                                label="Assinatura eletrônica"
                                autoComplete="new-password"
                                onChange={handleChangedElectronicPassword}
                                setLoading={loadingClient}
                            />
                            <Separator />
                            <StyledSubmitButton
                                fullWidth
                                type="submit"
                                onClick={handleSubmit}
                                disabled={submitting || !electronicCheck.length}
                            >
                                {submitting && <Icon icon="spinner" spin padRight />}
                                ACEITAR E CONTINUAR
                            </StyledSubmitButton>
                        </StyledContentWrapper>
                    </StyledOpenFlatCard>
                </StyledColNoPadd>
            </StyledRowNoPadd>
            <StyledGlobalNotification />
        </StyledContainerNoPadd>
    );
};

export default ExpiredIntermediationContract;
