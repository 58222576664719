import styled, { css } from 'styled-components';
import { profileIdToColor } from '../../../UserProfileForm/Suitability/index.styles';

export const StyledHeaderProfileNameItem = styled.div`
    ${props =>
        css`
            color: #42555d;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 13px;
            margin-top: -8px;

            & > span {
                padding-left: 32px;
                padding-right: 32px;
                font-size: 12px;
                font-weight: 500;
                margin-right: auto;
                font-family: Montserrat;
                ${profileIdToColor(props.profileId)}
            }
        `}
`;
