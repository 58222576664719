import * as React from 'react';
import { Icon } from '@terrainvest/react-components';
import { StyledHeaderLinkItem } from './index.styles';

export interface HeaderLinkItemProps {
    name?: string;
    dispatch?: () => void;
    clickedCallback?: () => void;
}

export const HeaderLinkItem: React.FC<HeaderLinkItemProps> = ({
    name = 'Senha e Assinatura Eletrônica',
    dispatch = () => undefined,
    clickedCallback = () => undefined,
}: HeaderLinkItemProps) => {
    const handleClicked = () => {
        dispatch();
        clickedCallback();
    };

    return (
        <StyledHeaderLinkItem onClick={handleClicked}>
            <span>{name}</span>
            <Icon icon={['fal', 'chevron-right']} size="xs" padLeft />
        </StyledHeaderLinkItem>
    );
};

export default HeaderLinkItem;
