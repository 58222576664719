import styled, { css } from 'styled-components';
import { Icon } from '@terrainvest/react-components';

export const StyledHeaderProfileMenuItem = styled.div`
    ${props =>
        css`
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 20px;
            cursor: pointer;
            &:hover {
                & > span,
                #number,
                #name {
                    color: #42555d;
                }
            }

            ${Icon.Styles.StyledIcon} {
                font-size: 18px;
                vertical-align: middle;
                color: #9ba6a7;
            }

            #number {
                vertical-align: middle;
                color: #9ba6a7;
                font-family: Montserrat;
                font-size: 12px;
                margin-left: 14px;
                font-weight: 500;
            }

            #name {
                vertical-align: middle;
                color: #9ba6a7;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 400;
            }
            & > span {
                color: #9ba6a7;
            }

            ${props.selected
                ? `
            & > span,#number, #name {
                color: #42555d;
            }




            ${Icon.Styles.StyledIcon} {
                color: #004d4e;
            }
        `
                : `
            & > span,#number, #name {
               color: #9ba6a7;
            }

        `}
        `}
`;
