import { normalizeIncomingAssetManagers } from './AssetManager/index.normalize';
import { normalizeIncomingProcurators } from './Procurator/index.normalize';
import { normalizePatrimony } from './Patrimony/index.normalize';

const normalizePersonType = client => {
    return {
        ...client,
        isCompany: client.PersonType === 'J',
    };
};

export const normalizeClient = client => {
    const normalizedClient = { ...client };

    return [normalizedClient]
        .map(normalizePersonType)
        .map(normalizePatrimony)
        .map(normalizeIncomingProcurators)
        .map(normalizeIncomingAssetManagers)
        .pop();
};
