import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeOffers, normalizeOffer, normalizeInnerFields } from './index.normalize';

export const fetchInvestmentDirectTreasuryAssetsInit = () => ({
    type: Actions.FETCH_DIRECT_TREASURY_INIT,
});

export const fetchDirectTreasuryAssets = () => {
    return fetch(ENDPOINTS.GET_TD_INVESTMENT_OFFERS, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeOffers);
};

export const fetchDirectTreasurySuccess = offers => ({
    type: Actions.FETCH_DIRECT_TREASURY_SUCCESS,
    offers,
});

export const fetchDirectTreasuryError = error => ({
    type: Actions.FETCH_DIRECT_TREASURY_ERROR,
    error: error.message,
});

export const fetchDirectTreasuryAsset = asset => {
    const { assetName = '', dueDate = '' } = { ...asset };

    const url = ENDPOINTS.GET_DIRECT_TREASURY_ASSET.replace(':assetName', assetName).replace(
        ':dueDate',
        dueDate,
    );

    return fetch(url, { method: 'GET' })
        .then(response => {
            let parsedResponse;

            try {
                parsedResponse = response.json();
            } catch (error) {
                return error;
            }

            return parsedResponse;
        })
        .then(normalizeOffer);
};

export const fetchDirectTreasuryById = offerId => {
    const url = ENDPOINTS.GET_TD_BY_ID.replace(':id', offerId);

    return fetch(url, { method: 'GET' })
        .then(response => {
            let parsedResponse;

            try {
                parsedResponse = response.json();
            } catch (error) {
                return error;
            }

            return parsedResponse;
        })
        .then(normalizeInnerFields);
};

export const postDirectTreasuryBuyOrder = payload => {
    return fetch(ENDPOINTS.POST_TD_BUY_ORDER, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const postRedeemRequest = payload => {
    return fetch(ENDPOINTS.POST_REDEEM_DIRECT_TREASURY_REQUEST, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};
