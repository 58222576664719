import * as React from 'react';

import { InputText, InputDate } from '@terrainvest/react-components';

import { Field, SelectField } from '../../Form/Field';

import { CommonPrefixes } from '../../../../Redux/Common/index.constants';

import { StyledRow, StyledCol, StyledSelect } from '../../Details/index.styles';
import { StyledHelperImage } from '../index.styles';

import { fieldLengthLimits } from '../../index.settings';

import { validateRequiredField, validateDate, validateFields } from '../../index.validators';

import RNENumber from '../assets/nRNE.png';
import RNEValidateDate from '../assets/dtExpRNE.png';

const RNEForm = () => (
    <StyledRow>
        <StyledCol col={12}>
            <Field name="DocumentNumber" validate={value => validateFields('rneNumber', value)}>
                {({ inputProps }) => (
                    <InputText
                        id="rne-number-input"
                        {...inputProps}
                        label="Número do RNE"
                        fullWidth
                        helperContent={<StyledHelperImage src={RNENumber} />}
                        maxLength={fieldLengthLimits.rneNumber}
                    />
                )}
            </Field>
        </StyledCol>
        <StyledCol md={6} sm={12}>
            <Field name="DocumentIssuerDate" validate={validateDate}>
                {({ inputProps }) => (
                    <InputDate
                        id="rne-validate-date-input"
                        {...inputProps}
                        label="Data de expedição"
                        fullWidth
                        icon={false}
                        helperContent={<StyledHelperImage src={RNEValidateDate} />}
                    />
                )}
            </Field>
        </StyledCol>
        <StyledCol md={6} sm={12}>
            <SelectField
                name="DocumentIssuerState"
                commonPrefix={CommonPrefixes.States}
                validate={validateRequiredField}
            >
                {({ field, form: { errors }, onChange, options, defaultValue }) => (
                    <StyledSelect
                        name={field.name}
                        label="UF"
                        fullWidth
                        suggest
                        suggestLimit={8}
                        error={!!errors[field.name]}
                        message={errors[field.name] || ''}
                        defaultValue={defaultValue}
                        options={options}
                        onChange={onChange}
                    />
                )}
            </SelectField>
        </StyledCol>
    </StyledRow>
);

export default RNEForm;
