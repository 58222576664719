import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeSections } from './index.normalize';

export const fetchPartnerFunctionalityInit = partnerId => ({
    type: Actions.FETCH_PARTNER_FUNCTIONALITY_INIT,
    partnerId,
});

export const fetchPartnerFunctionality = async partnerId => {
    const url = ENDPOINTS.GET_PARTNER_FUNCTIONALITY.replace(':partnerId', partnerId);

    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeSections);
};

export const fetchPartnerFunctionalitySuccess = sections => ({
    type: Actions.FETCH_PARTNER_FUNCTIONALITY_SUCCESS,
    sections,
});

export const fetchPartnerFunctionalityError = () => ({
    type: Actions.FETCH_PARTNER_FUNCTIONALITY_ERROR,
});
