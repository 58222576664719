import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeHistory, normalizeWithdrawValue } from './index.normalize';
import { WithdrawValue } from './index.types';

export const postWithdrawInit = data => ({
    type: Actions.WITHDRAW_FETCH_INIT,
    data,
});

export const postWithdraw = data => {
    return fetch(ENDPOINTS.POST_WITHDRAW, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const postWithdrawSuccess = balance => ({
    type: Actions.WITHDRAW_FETCH_SUCCESS,
    balance,
});

export const postWithdrawError = error => ({
    type: Actions.WITHDRAW_FETCH_ERROR,
    error: error.message,
});

export const fetchWithdrawHistoryInit = data => ({
    type: Actions.WITHDRAW_HISTORY_FETCH_INIT,
    data,
});

export const fetchWithdrawHistory = data => {
    let url = ENDPOINTS.GET_WITHDRAW_HISTORY;

    Object.keys(data).forEach(key => {
        url = url.replace(`:${key}`, data[key]);
    });

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    })
        .then(response => response.json())
        .then(history => normalizeHistory(history));
};

export const fetchWithdrawHistorySuccess = history => ({
    type: Actions.WITHDRAW_HISTORY_FETCH_SUCCESS,
    history,
});

export const fetchWithdrawHistoryError = error => ({
    type: Actions.WITHDRAW_HISTORY_FETCH_ERROR,
    error: error.message,
});

export const postCancelWithdraw = payload => {
    return fetch(ENDPOINTS.POST_CANCEL_WITHDRAW, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const fetchWithdrawValuesInit = (accountNumber: string | number) => ({
    type: Actions.WITHDRAW_VALUES_FETCH_INIT,
    accountNumber: accountNumber.toString(),
});

export const fetchWithdrawValuesError = (error: Error) => ({
    type: Actions.WITHDRAW_VALUES_FETCH_ERROR,
    error: error.message,
});

export const fetchWithdrawValuesSuccess = (withdrawValue: Record<string, WithdrawValue>) => ({
    type: Actions.WITHDRAW_VALUES_FETCH_SUCCESS,
    withdrawValue,
});

export const fetchWithdrawValues = (accountNumber: string) => {
    return fetch(ENDPOINTS.GET_WITHDRAW_VALUES.replace(':accountNumber', accountNumber), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(json => normalizeWithdrawValue(json));
};
