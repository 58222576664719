export const FORM_SECTIONS = {
    DETAILS: {
        ID: 'user-details',
        NAME: 'Dados Pessoais',
        DETAILS:
            'Lembre-se de atualizar os dados sempre que houver alterações na sua situação profissional.',
        COMPANY_NAME: 'Dados Empresa',
    },
    PROCURATORS_INFO: {
        ID: 'procurators-info',
        NAME: 'Procuradores',
        DETAILS: 'Você pode adicionar um ou mais procuradores em seu cadastro',
    },
    CONTROLLERS: {
        ID: 'controllers-info',
        NAME: 'Controladoras, Controladas ou Coligadas',
        DETAILS:
            'Você pode adicionar 1 ou mais controladoras, controladas ou coligadas em seu cadastro',
        EMPTY_FEEDBACK:
            'Você não possui quaisquer controladoras, controladas ou coligadas em seu cadastro.',
    },
    ADMINISTRATORS: {
        ID: 'administrators-info',
        NAME: 'Administradores',
        DETAILS: 'Você pode adicionar 1 ou mais administradores em seu cadastro',
        EMPTY_FEEDBACK: 'Você não possui administradores em seu cadastro.',
    },
    ASSET_MANAGERS: {
        ID: 'asset-managers',
        NAME: 'Administradores de Carteira',
        DETAILS: 'Você pode adicionar um ou mais administradores de carteira em seu cadastro',
        ANCHOR_NAME: 'Admins. de Carteira',
    },
    ADDRESSES: {
        ID: 'user-addresses',
        NAME: 'Dados Residenciais',
        DETAILS:
            'Para atualizar os dados de endereço,' +
            ' é necessário o envio de um comprovante de residência e a análise de nossa equipe.',
        COMPANY_NAME: 'Endereço Empresa',
        COMPANY_DETAILS:
            'Para atualizar os dados de endereço,' +
            ' é necessário o envio de um comprovante de endereço e a análise de nossa equipe.',
    },
    DOCUMENTS: {
        ID: 'user-documents',
        NAME: 'Documentos',
        COMPANY_NAME: 'Documentos',
    },
    FINANCIAL_DATA: {
        ID: 'user-financial-data',
        NAME: 'Dados Financeiros',
        COMPANY_NAME: 'Dados Financeiros',
    },
    REPRESENTATIVES: {
        ID: 'user-representatives',
        NAME: 'Representantes',
        COMPANY_NAME: 'Representantes',
    },
    ORDER_ISSUERS: {
        ID: 'user-order-issuers',
        NAME: 'Emissores',
        COMPANY_NAME: 'Emissores',
    },
    SUITABILITY: {
        ID: 'user-suitability',
        NAME: 'Perfil de Investidor',
        COMPANY_NAME: 'Perfil de Investidor',
    },
    ATTACHMENTS: {
        ID: 'user-attachments',
        NAME: 'Anexo',
        DETAILS: 'Você alterou dados que precisam de alguns documentos para validação.',
        COMPANY_NAME: 'Anexo',
    },
};
