import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeClient } from './index.normalizes';

export const selectAccountNumber = accountNumber => ({
    type: Actions.CLIENT_SELECT_ACCOUNT_NUMBER,
    accountNumber,
});

export const fetchClientInit = (refresh?) => {
    return {
        type: Actions.CLIENT_FETCH_INIT,
        refresh,
    };
};

export const fetchClient = async (refresh, getState) => {
    const {
        authState: { auth },
        clientState: { client },
    } = getState();

    if (!auth) {
        return null;
    }

    if (client && !refresh) {
        return client;
    }

    return fetchClientData(auth.ClientId);
};

export const fetchClientSuccess = client => ({
    type: Actions.CLIENT_FETCH_SUCCESS,
    client,
});

export const fetchClientError = error => ({
    type: Actions.CLIENT_FETCH_ERROR,
    error: error.message,
});

export const updateClient = client =>
    fetch(ENDPOINTS.POST_CLIENT, {
        method: 'POST',
        body: JSON.stringify(client),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const updateClientNoDataChanged = client =>
    fetch(ENDPOINTS.PUT_CLIENT_NO_DATA_CHANGED, {
        method: 'PUT',
        body: JSON.stringify(client),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchClientData = clientId =>
    fetch(ENDPOINTS.GET_CLIENT.replace(':clientId', clientId), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(clnt => {
            if (clnt) {
                return normalizeClient(clnt);
            }
            throw new Error('Empty client!');
        });
