import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { blobToFile } from '../../../Utils/Parsers';

import { normalizeFinancialIncomeAvailableDates } from './index.normalize';

export const fetchFinancialIncomeReport = async (account, fiscalYear) => {
    const url = ENDPOINTS.GET_FINANCIAL_INCOME_REPORT.replace(':fiscalYear', fiscalYear).replace(
        ':account',
        account,
    );

    const response = await fetch(url, { method: 'GET' });
    const blob = new Blob(['\ufeff', await response.blob()], { type: 'text/html' });

    if (response.status && response.status === 200) {
        const defaultFilename = `informe_rendimento.html`;

        return blobToFile(blob, defaultFilename);
    }

    throw new Error(response.status.toString());
};

export const fetchFinancialIncomeAvailableDates = () => {
    const url = ENDPOINTS.GET_FINANCIAL_INCOME_DATES;
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeFinancialIncomeAvailableDates);
};
