import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { SideNavItem, CollapsableNavItem, SideNavSection } from '@terrainvest/react-components';

import { withAuth } from '../../../Utils/HOCs';
import Separator from '../../../Utils/General/index.separator';
import { TEDAccountInfo } from '../../TEDAccountInfo';

import { TED_ACCOUNT_INFO_SECTION_NAME, MENU_ID_PREFIX } from './index.constants';

const SideNavItemWithAuth = withAuth(SideNavItem);

const Sections = ({ isMobile, handleClick }) => {
    const { sections } = useSelector(state => state.partnerFunctionalityState);

    const history = useHistory();

    const getFunctionalities = functionalities =>
        functionalities &&
        functionalities.map &&
        functionalities.map(functionality => (
            <SideNavItemWithAuth
                id={`${MENU_ID_PREFIX}${functionality.url}${isMobile ? '-mobile' : ''}`}
                key={functionality.id}
                authIdentifier={`/${functionality.url}`}
                subNavItem={isMobile}
                mobileNavItem={!isMobile && functionality.bottomMenu}
                icon={functionality.icon}
                title={functionality.tooltip}
                suitabilityRestriction={functionality.suitability}
                blockInstitutional={functionality.blockInstitutional}
                onClick={evt => {
                    if (handleClick) {
                        handleClick(evt);
                    }
                    history.push(`/app/${functionality.url}`);
                }}
            />
        ));

    const getTEDAccountInfo = sectionName =>
        sectionName === TED_ACCOUNT_INFO_SECTION_NAME && (
            <CollapsableNavItem
                id="side-nav-item-account"
                subNavItem={isMobile}
                mobileNavItem={isMobile}
                mobileOnly={isMobile}
                mobile={isMobile}
                collapsibleSubNavItem={isMobile}
                icon="chevron-right"
                title="Minha Conta para TED"
            >
                <Separator base={4} />
                <TEDAccountInfo />
            </CollapsableNavItem>
        );

    const getSections = () =>
        sections &&
        sections.map &&
        sections.map(section => (
            <React.Fragment key={section.id}>
                <SideNavSection title={section.name} desktopOnly={!isMobile} />
                {getTEDAccountInfo(section.name)}
                {getFunctionalities(section.functionalities)}
            </React.Fragment>
        ));

    return <>{getSections()}</>;
};

Sections.propTypes = {
    isMobile: PropTypes.bool,
    handleClick: PropTypes.func,
};

Sections.defaultProps = {
    isMobile: false,
    handleClick: undefined,
};

export default Sections;
