// Funds terms and documents types
export const DOC_TYPES = {
    INFO: {
        type: 'LAMINA_DE_INFORMACOES',
        name: 'Lâmina de Informações',
    },
    REGULATION: {
        type: 'REGULAMENTO',
        name: 'Regulamento',
    },
    TERM: {
        type: 'TERMO_DE_ADESAO',
        name: 'Termo de Adesão',
    },
};

export const TRANSLATE_DOC_TYPE = {
    LAMINA_DE_INFORMACOES: 'Lâmina de Informações',
    REGULAMENTO: 'Regulamento',
    TERMO_DE_ADESAO: 'Termo de Adesão',
};
