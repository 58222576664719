import { formatCurrency } from '@terrainvest/react-components';
import moment from 'moment';

import { DOC_TYPES } from '../../Utils/General/DocTypes';
import { riskTypes } from '../../Utils/General/Risk';

const FILE_DOC_TYPE = new Map<string, string>([
    [DOC_TYPES.INFO.type, DOC_TYPES.INFO.name],
    [DOC_TYPES.REGULATION.type, DOC_TYPES.REGULATION.name],
    [DOC_TYPES.TERM.type, DOC_TYPES.TERM.name],
]);

const RISK = new Map<string, number>([
    [riskTypes.VERY_LOW.type, riskTypes.VERY_LOW.value],
    [riskTypes.LOW.type, riskTypes.LOW.value],
    [riskTypes.AVERAGE.type, riskTypes.AVERAGE.value],
    [riskTypes.HIGH.type, riskTypes.HIGH.value],
    [riskTypes.VERY_HIGH.type, riskTypes.VERY_HIGH.value],
]);

const REDEMPTION_TYPE = new Map<string, string>([
    ['corrido', 'corridos'], //
    ['util', 'úteis'],
]);

const OFFER_FILE_FIELDS = [
    {
        label: 'DocumentType',
        normalizer: docType => FILE_DOC_TYPE.get(docType) || docType,
    },
];

const OFFER_FIELDS = [
    {
        label: 'MinimalApplication',
        normalizer: value => formatCurrency(value, { currency: '' }),
    },
    {
        label: 'Risk',
        normalizer: risk => RISK.get(risk) || risk,
    },
];

const getRedemptionType = key => {
    const parsedKey = (key as string).toLowerCase();
    return REDEMPTION_TYPE.get(parsedKey) || undefined;
};

const normalizeOfferFiles = offer => {
    if (!offer.Files || offer.Files.length === 0) {
        return offer;
    }

    const normalizedOffer = { ...offer };

    normalizedOffer.Files.forEach(file => {
        OFFER_FILE_FIELDS.forEach(field => {
            const rawField = file[field.label];
            // eslint-disable-next-line no-param-reassign
            file[`${field.label}Normalized`] = field.normalizer(rawField);
        });
    });

    return normalizedOffer;
};

const normalizeOfferQuotization = offer => {
    const normalizedOffer = { ...offer };

    const quotizationType = getRedemptionType(offer.RedemptionType) || '--';
    const quotization = `${normalizedOffer.RedemptionDays}d (${quotizationType})`;

    const liquidationType = getRedemptionType(offer.RedemptionTypeLiquidation) || '--';
    const liquidation = `${normalizedOffer.DaysLiquidation}d (${liquidationType})`;

    normalizedOffer.QuotizationNormalized = quotization;
    normalizedOffer.LiquidationNormalized = liquidation;

    return normalizedOffer;
};

const normalizeOfferFields = offer => {
    const normalizedOffer = { ...offer };

    OFFER_FIELDS.forEach(field => {
        const rawField = normalizedOffer[field.label];
        normalizedOffer[`${field.label}Normalized`] = field.normalizer(rawField);
    });

    return normalizedOffer;
};

export const normalizeOffer = incomingOffer => {
    const normalizedOffer = { ...incomingOffer };
    return [normalizedOffer]
        .map(offer => normalizeOfferFields(offer))
        .map(offer => normalizeOfferFiles(offer))
        .map(offer => normalizeOfferQuotization(offer))
        .pop();
};

export const normalizeOffers = offers => {
    return [...offers]
        .map(offer => normalizeOfferFields(offer))
        .map(offer => normalizeOfferFiles(offer))
        .map(offer => normalizeOfferQuotization(offer));
};

export const normalizeAssetDetails = incomingDetails =>
    incomingDetails && incomingDetails.map
        ? incomingDetails.map(details => ({
              opDate: details.OperationDate,
              opDateNormalized: moment(details.OperationDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              quote: details.QuoteValue,
              quoteAmount: details.TotalQuotes,
              invested: details.InitialValue,
              gross: details.GrossAmount,
              ir: details.IrTax,
              iof: details.IofTax,
              net: details.NetValue,
          }))
        : [];
