import * as React from 'react';
import { Text, Icon, InputCpf, InputText } from '@terrainvest/react-components';
import { StyledOrderIssuer, StyledDelete } from './index.styles';
import { StyledRow, StyledCol } from '../../Details/index.styles';
import { validateRequiredField, validateCpfValue, isValidEmail } from '../../index.validators';
import { Field } from '../../Form/Field';
import { StyledContainerNoPadd } from '../../../Common/Grid';
import { fieldLengthLimits } from '../../index.settings';

export const OrderIssuer = ({ index, onClose }) => {
    const preffix = `OrderIssuers[${index}]`;
    return (
        <StyledOrderIssuer>
            {onClose && (
                <StyledDelete onClick={onClose}>
                    <Text weight="medium">
                        Remover emissor <Icon icon="times" size="sm" padLeft />
                    </Text>
                </StyledDelete>
            )}
            <StyledContainerNoPadd fluid>
                <StyledRow>
                    <StyledCol md={6} sm={12}>
                        <Field name={`${preffix}.Name`} validate={validateRequiredField}>
                            {({ inputProps }) => (
                                <InputText
                                    {...inputProps}
                                    label="Nome"
                                    fullWidth
                                    maxLength={fieldLengthLimits.name}
                                />
                            )}
                        </Field>
                    </StyledCol>
                    <StyledCol md={6} sm={12}>
                        <Field name={`${preffix}.Cpf`} validate={validateCpfValue}>
                            {({ inputProps }) => <InputCpf {...inputProps} label="CPF" fullWidth />}
                        </Field>
                    </StyledCol>
                    <StyledCol md={6} sm={12}>
                        <Field name={`${preffix}.Rg`} validate={validateRequiredField}>
                            {({ inputProps }) => <InputText {...inputProps} label="RG" fullWidth />}
                        </Field>
                    </StyledCol>
                    <StyledCol md={6} sm={12}>
                        <Field name={`${preffix}Email`} validate={isValidEmail}>
                            {({ inputProps }) => (
                                <InputText
                                    {...inputProps}
                                    label="E-mail"
                                    fullWidth
                                    maxLength={fieldLengthLimits.email}
                                />
                            )}
                        </Field>
                    </StyledCol>
                </StyledRow>
            </StyledContainerNoPadd>
        </StyledOrderIssuer>
    );
};

export default OrderIssuer;
