import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';

import {
    fetchPartnerFunctionality,
    fetchPartnerFunctionalitySuccess,
    fetchPartnerFunctionalityError,
} from './index.actions';

export const initialState = {
    sections: [],
    loading: false,
    error: false,
};

export const partnerFunctionalityReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_PARTNER_FUNCTIONALITY_INIT:
            return loop(
                { ...state, loading: true, error: false },
                Cmd.run(fetchPartnerFunctionality, {
                    successActionCreator: fetchPartnerFunctionalitySuccess,
                    failActionCreator: fetchPartnerFunctionalityError,
                    args: [action.partnerId],
                }),
            );
        case Actions.FETCH_PARTNER_FUNCTIONALITY_SUCCESS:
            return {
                ...state,
                sections: action.sections,
                loading: false,
                error: false,
            };
        case Actions.FETCH_PARTNER_FUNCTIONALITY_ERROR:
            return { ...state, error: true, loading: false };
        default:
            return state;
    }
};
