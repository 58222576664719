import * as React from 'react';

import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Button, Icon } from '@terrainvest/react-components';

import FormCard from '../Common/FormCard';
import { FORM_SECTIONS } from '../Form';

import getGUID from '../../../Utils/General/GUID';
import Separator from '../../../Utils/General/index.separator';
import FeedbackNote from '../../Common/FeedbackNote';
import AdministratoInput from './AdministratorInput';

export const AdministratorsInfo = () => {
    const { values, setFieldValue } = useFormikContext<any>();
    const { ClientId } = useSelector(state => state.clientState.client);

    const handleRemoveItem = index => {
        if (index >= -1) {
            const newControllers = [...values.Controllers];
            newControllers.splice(index, 1);

            setFieldValue('Controllers' as never, newControllers);
        }
    };

    const handleAddItem = () => {
        const controllers = [
            ...(values.Controllers || []),
            {
                key: getGUID(),
                ClientId,
                Name: '',
                RG: '',
                CpfCnpj: '',
            },
        ];

        setFieldValue('Controllers' as never, controllers);
    };

    return (
        <>
            <FormCard
                title={FORM_SECTIONS.ADMINISTRATORS.NAME}
                subTitle={FORM_SECTIONS.ADMINISTRATORS.DETAILS}
            >
                {values &&
                    values.Controllers &&
                    values.Controllers.map((controller, index) => (
                        <AdministratoInput
                            key={controller.CnpjId || controller.key}
                            index={index}
                            preffix={`Controllers[${index}]`}
                            onRemove={handleRemoveItem}
                        />
                    ))}

                {values && (!values.Controllers || !values.Controllers.length) && (
                    <>
                        <Separator />
                        <FeedbackNote
                            color="warn"
                            message={FORM_SECTIONS.ADMINISTRATORS.EMPTY_FEEDBACK}
                        />
                        <Separator />
                    </>
                )}

                <Separator />
                <Button type="button" fullWidth secondary onClick={handleAddItem}>
                    ADICIONAR {FORM_SECTIONS.ADMINISTRATORS.NAME}
                    <Icon icon={['fas', 'plus']} padLeft size="xs" />
                </Button>
            </FormCard>
        </>
    );
};

export default AdministratorsInfo;
