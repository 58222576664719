import * as React from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, SideBySideText, Skeleton } from '@terrainvest/react-components';

import { StyledWrapper } from './index.styles';

export const TEDAccountInfo = () => {
    const { selectedAccount } = useSelector(state => state.clientState);
    const { accounts } = useSelector(state => state.accountState);

    const accountInfo =
        selectedAccount &&
        accounts &&
        accounts.find &&
        accounts.find(account => String(account.Number) === String(selectedAccount));

    return (
        <StyledWrapper>
            <Card light>
                <CardBody>
                    {accountInfo ? (
                        <>
                            <SideBySideText
                                left="Banco"
                                right="307 - Terra Investimentos DTVM"
                                width="auto"
                                copy
                            />
                            <SideBySideText left="Agência" right="0001" width="auto" copy />
                            <SideBySideText
                                left="Conta"
                                right={`${accountInfo.Number}-${accountInfo.Digit}`}
                                width="auto"
                                copy
                            />
                        </>
                    ) : (
                        <>
                            <Skeleton height={16} width={180} />
                            <br />
                            <Skeleton height={16} width={180} />
                            <br />
                            <Skeleton height={16} width={180} />
                        </>
                    )}
                </CardBody>
            </Card>
        </StyledWrapper>
    );
};

export default TEDAccountInfo;
