import * as React from 'react';
import {
    StyledHeaderQualifiedInvestorItem,
    StyledWrapper,
    StyledSpan,
    StyledIcon,
} from './index.styles';

interface HeaderQualifiedInvestorItemProps {
    professional?: boolean;
}

export const HeaderQualifiedInvestorItem: React.FC<HeaderQualifiedInvestorItemProps> = ({
    professional,
}: HeaderQualifiedInvestorItemProps) => {
    return (
        <StyledHeaderQualifiedInvestorItem professional={professional}>
            <StyledWrapper>
                <StyledIcon icon={['fa', 'user-tie']} />
                <StyledSpan>Investidor {professional ? 'Profissional' : 'Qualificado'}</StyledSpan>
            </StyledWrapper>
        </StyledHeaderQualifiedInvestorItem>
    );
};

HeaderQualifiedInvestorItem.defaultProps = {
    professional: false,
};

export default HeaderQualifiedInvestorItem;
