import * as React from 'react';
import { useSelector } from 'react-redux';

import useWindowSize from '../../Utils/Hooks/useWindowSize';

import { AvatarFromText } from '../Avatars';

import {
    StyledWrapper,
    StyledAvatarWrapper,
    StyledInfoWrapper,
    StyledHeader,
    StyledInfoText,
} from './index.styles';
import { truncateText } from '../../Utils/Parsers';

export const Advisors = () => {
    const { advisor } = useSelector(state => state.advisorsState);

    const windowSize = useWindowSize();
    const isMobileViewport = windowSize.width <= 768 || windowSize.height <= 600;

    const truncateLengthName = isMobileViewport ? 30 : 23;
    const truncateLengthEmail = isMobileViewport ? 35 : 25;

    return (
        <>
            {advisor && (
                <StyledWrapper>
                    {!isMobileViewport && (
                        <StyledAvatarWrapper>
                            <AvatarFromText text={advisor.Name} />
                        </StyledAvatarWrapper>
                    )}
                    <StyledInfoWrapper>
                        <StyledHeader>Assessor</StyledHeader>
                        <StyledInfoText small title={advisor.Name}>
                            {truncateText(advisor.Name, truncateLengthName, '...')}
                        </StyledInfoText>

                        <StyledInfoText small>{advisor.normalized.phone}</StyledInfoText>
                        <StyledInfoText small title={advisor.Email.toLowerCase()}>
                            {truncateText(advisor.Email, truncateLengthEmail, '...').toLowerCase()}
                        </StyledInfoText>
                    </StyledInfoWrapper>
                </StyledWrapper>
            )}
        </>
    );
};

export default Advisors;
